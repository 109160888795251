<mat-toolbar color="primary">
  <div class="ft-module-icon">
    <mat-icon fontSet="mdi" fontIcon="mdi-playlist-plus"></mat-icon>
  </div>
  <h2 mat-dialog-title>{{ label| translate }}</h2>
  <span fxFlex></span>

  <button type="button" mat-icon-button mat-dialog-close tabindex="-1">
    <mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
  </button>
</mat-toolbar>
<div mat-dialog-content class="ft-content"  fxFlex fxFill>
  <form #listForm="ngForm" fxLayout="column">

    <div fxLayout="column"  fxFlex>
      <mat-form-field fxFlex>
        <input    matInput [(ngModel)]="currentModel.value" name="value"   [placeholder]="'shared.value'|translate" required>
      </mat-form-field>
      <mat-form-field fxFlex>
        <textarea    matInput [(ngModel)]="currentModel.description" name="description"   [placeholder]="'shared.description'|translate"></textarea>
      </mat-form-field>
    </div>
  </form>
</div>
<div mat-dialog-actions>
  <span fxFlex></span>
  <button type="button" color="primary" mat-raised-button tabindex="2" (click)="save()" [disabled]="listForm.invalid">
    {{'shared.save'|translate}}
  </button>
  <button type="button" [mat-dialog-close]="null"  color="warn" mat-raised-button tabindex="-1">{{'shared.reset'|translate}}</button>
</div>
