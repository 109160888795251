<div fxFlex class="ft-padding" fxLayout="column">
    <div fxLayout="row" fxLayoutGap="6px">
      <mat-form-field fxFlex="nogrow">
        <input matInput ftDate [matDatepicker]="from_date" [placeholder]="(viewType=='DAILY' ? 'shared.date' : 'shared.from_date')|translate" required
               [(ngModel)]="filter.from_date" name="from_date" (ngModelChange)="loadData()">
        <mat-datepicker-toggle matSuffix [for]="from_date">
          <mat-icon matDatepickerToggleIcon fontSet="mdi" fontIcon="mdi-calendar-today"></mat-icon>
        </mat-datepicker-toggle>
        <mat-datepicker #from_date></mat-datepicker>
      </mat-form-field>

      <mat-form-field fxFlex="nogrow"  *ngIf="viewType=='PERIODIC'">
        <input matInput ftDate [matDatepicker]="to_date" [placeholder]="'shared.to_date'|translate" required
               [(ngModel)]="filter.to_date" name="to_date" (ngModelChange)="loadData()">
        <mat-datepicker-toggle matSuffix [for]="to_date">
          <mat-icon matDatepickerToggleIcon fontSet="mdi" fontIcon="mdi-calendar-today"></mat-icon>
        </mat-datepicker-toggle>
        <mat-datepicker #to_date></mat-datepicker>
      </mat-form-field>
    </div>
   <div [ngBusy]="loading"></div>

  <div fxFlex fxLayout="column"   class="layout-fill ft-wdr-container wdr-container-div no-padding" >
     <wbr-pivot  #pivot [toolbar]="true"
            [height]="'calc(100vh - 150px)'"
            [report]="config"
            [customizeCell]="onCustomizeCell"
            (ready)="onPivotReady($event)"
            (reportcomplete)="onReportComplete()"
            (beforetoolbarcreated)="onToolbarCreated($event)"
     >
    </wbr-pivot>
  </div>
</div>
