import {Component, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import * as moment from 'moment';
import {ActivatedRoute} from '@angular/router';
import {BillingService} from '../../../shared/services/billing.service';
import * as _ from 'lodash';
import {PATIENTS_VIEW} from '../../../patient/utils/views-settings';
import {ORGANIZATION_VIEW} from '../../../settings/utils/views-settings';
import {PatientDialogComponent} from "../../../patient/dialogs/patient-dialog/patient-dialog.component";

@Component({
  selector: 'ftb-care-sheet',
  templateUrl: './care-sheet.component.html',
  styleUrls: ['./care-sheet.component.scss']
})
export class CareSheetComponent implements OnInit {
  cls = 'care-sheet';
  loading: Subscription;
  careSheet: any = {
    number: null,
    tariff: null,
    doc_date: moment(),
    patient: null,
    insurance: null,
    lines: [],
    insured_details: {
      insured_type: 'PATIENT',
      affiliate_number: null,
      title: null,
      first_name: null,
      last_name: null,
      birth_date: null,
      national_id: null,
      address: null
    }
  };

  routes = {list: '/invoicing/care-sheet/', form: '/invoicing/care-sheet/care-sheet-form/'};
  patientInputSettings = _.assignIn(_.cloneDeep(PATIENTS_VIEW), {selectable: false});
  organizationInputSettings = _.assignIn(_.cloneDeep(ORGANIZATION_VIEW), {selectable: false});
  patientDialog = PatientDialogComponent;

  onPatientChange = (data) => {
    this.careSheet.patient = data;
    if (this.careSheet.insured_details.insured_type == 'PATIENT') {
      this.careSheet.insured_details = _.assignIn(this.careSheet.insured_details, _.pick(this.careSheet.patient, 'title', 'first_name', 'last_name', 'birth_date', 'national_id', 'title'));
      if (!this.careSheet.insurance) {
        this.careSheet.insurance = _.get(this.careSheet.patient, 'medical_insurances.0.insurance')
      }
      this.careSheet.insured_details.address = _.get(this.careSheet.patient, 'address.content');

    }
  };
  onOrganizationChange = (data) => {

    this.careSheet.insurance = data;

  };

  constructor(private route: ActivatedRoute, private  billingService: BillingService) {
  }

  ngOnInit() {
    this.route.params.subscribe(params => this.initData(params));
  }

  initData(params) {
    if (_.get(params, 'id')) {
      this.loading = this.billingService.getBillingDoc(this.cls, _.get(params, 'id'))
        .subscribe(data => {
          this.careSheet = data;
        });
    }
  }

  onModelChange(data) {
    this.careSheet = data;
  }

  listChange(data, type) {
    // this.careSheet.=data;
  }
}
