import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FtCoreModule, MaterialModule} from '@ft/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {TranslateModule} from '@ngx-translate/core';
import {FtbSharedModule} from '../shared/shared.module';
import {FtTableModule} from '@ft/table';
import {NgBusyModule} from 'ng-busy';
import {MainComponent} from './components/main/main.component';
import {FtbDailyWorkflowRoutingModule} from './daily-workflow-routing.module';
import {BillingRequestListComponent} from './components/billing-request-list/billing-request-list.component';
import {BillingRequestDialogComponent} from "./dialogs/billing-request-financial-dialog/billing-request-dialog.component";
import { AddRequestDialogComponent } from './dialogs/add-request-dialog/add-request-dialog.component';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    TranslateModule.forChild(),
    FtbSharedModule,
    FtCoreModule,
    FtTableModule,
    NgBusyModule,
    FtbDailyWorkflowRoutingModule
  ],
  declarations: [
    MainComponent,
    BillingRequestListComponent,
    BillingRequestDialogComponent,
    AddRequestDialogComponent
  ]
})
export class FtbDailyWorkflowModule {
}
