import {Injectable} from '@angular/core';
import {HttpClient, HttpRequest} from '@angular/common/http';
import * as _ from 'lodash';
import {FtWsService} from '@ft/core';
import {Observable, ReplaySubject, Subscription} from "rxjs";


@Injectable({
  providedIn: 'root'
})
export class SharedService {

  constructor(private http: HttpClient, private ws: FtWsService) {
  }

  list(cls) {
    return this.ws.call(`list.get_list`, {cls: cls});
  }

  handleList(item, _cls) {
    return this.ws.call('list.save_list', {obj: item, cls: _cls});
  }

  handleImport(file, topic, file_name): Observable<any> {
    return this.ws.call(topic, {file, file_name});
  }

  readFile(fileToRead: File): Observable<any> {
    let base64Observable = new ReplaySubject<any>(1);

    let fileReader = new FileReader();
    fileReader.onload = event => {
      base64Observable.next(fileReader.result);
    };
    fileReader.readAsDataURL(fileToRead);

    return base64Observable;
  }

  handleXlsExport(topic, options) {
    return this.ws.call(topic, options);
  }

  handleRemainingAmount(topic, pk) {
    return this.ws.call(`${topic}.handle_remaining_amount`, {pk});
  }

  getCitiesList() {
    return this.http.get('api/location/city/')
  }

}
