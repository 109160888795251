import {Component, OnInit} from '@angular/core';
import {BehaviorSubject, Observable, of, Subscription} from "rxjs";
import {TableColumnDefinition} from "@ft/table";
import * as moment from "moment";
import * as _ from "lodash";
import {StateService} from "../../services/state-service.service";
import {TranslateService} from "@ngx-translate/core";
import {TableColumnType} from "@ft/table/table/models/table-definitions";
import {ORGANIZATION_VIEW} from "../../../settings/utils/views-settings";

@Component({
  selector: 'ftb-care-request-state',
  templateUrl: './care-request-state.component.html',
  styleUrls: ['./care-request-state.component.scss']
})
export class CareRequestStateComponent implements OnInit {
  items: any = [];
  busy: Subscription;

  linesSource$: Observable<any[]> = of([]);
  viewColumns: TableColumnDefinition[] = [{
    label: "states.patient_fullname",
    type: "text",
    key: "patient_fullname"
  }, {
    label: "states.exams",
    type: "text",
    key: "exams",
  }, {
    label: "states.exams_date",
    type: "date",
    key: "exams_date"
  }, {
    label: "states.send_date",
    type: 'date',
    key: "send_date",
  }, {
    label: "pec.pec_number",
    type: "text",
    key: "number",
  }, {
    label: "states.payment_date",
    type: "date",
    key: "payment_date",
    numberConfig: 'float',
  }];
  linesColumns: BehaviorSubject<TableColumnDefinition[]> = new BehaviorSubject<TableColumnDefinition[]>(this.viewColumns);
  filter = {'organization': {}, 'from_date': moment(), 'to_date': moment()};
  organizationInputSettings = _.assignIn(_.cloneDeep(ORGANIZATION_VIEW), {selectable: false});
  has_res = false;

  constructor(private stateService: StateService, private translateService: TranslateService) {
  }

  ngOnInit() {
    this.handleFilterChange(null);
  }

  trackByFn(index, item) {
    return _.get(item, 'label', index);
  }

  reportClick(link) {

  }

  onOrganizationChange = (data) => {

    this.filter.organization = data;
    this.handleFilterChange(null);

  };

  handleFilterChange(ev) {
    this.busy = this.stateService.getCareRequestState(this.formatFilter()).subscribe(data => {
      let totals = _.get(data, 'totals', {});
      if (!_.isNil(data)) {
        this.has_res = true;

        this.linesSource$ = of(_.get(data, 'data', []));
      }
      else {
        this.has_res = false;
      }
    });

  }

  formatFilter() {
    return {
      _from: _.get(this.filter, 'from_date').format('DD/MM/YYYY'),
      _to: _.get(this.filter, 'to_date').format('DD/MM/YYYY'),
      _organization: _.get(this.filter, 'organization.id')
    }
  }

}
