import {Component, OnInit, ViewChild} from '@angular/core';
import {of, Subscription} from 'rxjs';
import {ActivatedRoute, ParamMap} from '@angular/router';
import {BillingService} from '../../../shared/services/billing.service';
import * as _ from 'lodash';
import * as moment from 'moment';
import {RouterDataService} from '../../../shared/services/router-data.service';
import {NgForm} from '@angular/forms';
import {PATIENTS_VIEW} from '../../../patient/utils/views-settings';
import {ORGANIZATION_VIEW} from '../../../settings/utils/views-settings';
import {ResponseDialogComponent} from "../../dialogs/response-dialog/response-dialog.component";
import {MEDIUM_DIALOG, SMALL_DIALOG} from "@ft/core";
import {MatDialog} from "@angular/material/dialog";
import {PatientDialogComponent} from "../../../patient/dialogs/patient-dialog/patient-dialog.component";

@Component({
  selector: 'ftb-pec-form',
  templateUrl: './pec-form.component.html',
  styleUrls: ['./pec-form.component.scss']
})
export class PecFormComponent implements OnInit {
  routes = {list: '/pec/pecs/', form: '/pec/pecs/pec-form/'};
  loadedObject=null;
  cls = 'pec';
  loading: Subscription;
  pec: any = {
    number: null,
    tariff: null,
    doc_date: moment(),
    send_date: moment(),
    beneficiary_type: 'patient',
    beneficiary: null,
    patient: null,
    organization: null,
    details: [],
    answers: [],
    insured_details: {
      insured_type: 'PATIENT',
      affiliate_number: null,
      title: null,
      first_name: null,
      last_name: null,
      birth_date: null,
      national_id: null,
      address: null
    }
  };

  seqQuery = {
    cls: 'pec.CareRequest',
    label: 'pec.pec_number',
    fieldName: 'number',
    reloadDeleted: true

  };

  patientInputSettings = _.assignIn(_.cloneDeep(PATIENTS_VIEW), {selectable: false});
  organizationInputSettings = _.assignIn(_.cloneDeep(ORGANIZATION_VIEW), {selectable: false});
  patientDialog = PatientDialogComponent;

  conventions = [];
  @ViewChild('pecForm', {static: true}) public pecForm: NgForm;

  constructor(private route: ActivatedRoute, private  billingService: BillingService, private  routerDataService: RouterDataService,private dialog: MatDialog) {
  }

  ngOnInit() {
    this.route.params.subscribe(params => this.initData(params));
  }

  initData(params) {
    if (_.get(params, 'id')) {
      this.loading = this.billingService.getBillingDoc(this.cls, _.get(params, 'id'))
        .subscribe(data => {
          this.pec = !_.get(data, 'insured_details') ? _.assignIn(data, {insured_details: {}}) : data;
        });
    }
  }


  onPatientChange = (data) => {
    this.pec.patient = data;
    if( this.pec.insured_details.insured_type=='PATIENT'){
      this.pec.insured_details = _.assignIn(this.pec.insured_details, _.pick(this.pec.patient,  'title', 'first_name', 'last_name', 'birth_date', 'national_id','title' ));
      this.pec.insurance = _.get(this.pec.patient, 'medical_insurances.0.insurance');
      this.pec.insured_details.address = _.get(this.pec.patient, 'address.content');
    }
  };
  onOrganizationChange = (data) => {
    this.pec.organization = data;
  };

  onModelChange(data) {
    this.pec = data;
  }

  onInsuredChange(ev) {
    if (ev.value == 'PATIENT') {
      this.pec.insured_details = _.assignIn(this.pec.insured_details, _.pick(this.pec.patient,  'title', 'first_name', 'last_name' ));
    }
  }

  listChange(data, type) {
  }


  addResponseDialog() {
    this.dialog.open(ResponseDialogComponent, _.assign(MEDIUM_DIALOG, {
      data: {lines:_.cloneDeep(this.pec.details)}
    })).afterClosed().subscribe(res => {
      if (_.get(res, 'response')){
        this.pec.answers.push(_.get(res, 'response'));
        this.pec.details=_.get(res, 'lines');
        this.loading = this.billingService.saveBillingDoc(this.pec,this.cls, null)
          .subscribe(data => {
            this.pec = data;
          });
      }
    });
  }

}
