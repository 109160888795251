import {TableColumnDefinition} from '@ft/table/table/models/table-definitions';

export const SYSTEM_COLUMNS: TableColumnDefinition[] = [
  {
    'key': 'created_at', 'label': 'created_at', 'type': 'date'
  },
  {
    'key': 'updated_at', 'label': 'updated_at', 'type': 'date'
  },
  {
    'key': 'is_deleted', 'label': 'is_deleted', 'type': 'boolean'
  },
  {
    'key': 'deleted_at', 'label': 'deleted_at', 'type': 'date'
  },

];
