import {Component, Inject, OnInit} from '@angular/core';
import {BillingService} from '../../../shared/services/billing.service';
import * as _ from 'lodash';
import {SelectDocumentsDialogComponent} from '../../../shared/dialogs/select-documents-dialog/select-documents-dialog.component';
import {LARGE_DIALOG} from '@ft/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {BehaviorSubject, Observable, of, Subscription} from 'rxjs';
import {PROCEDURE_VIEW} from '../../../shared/utils/views-settings';
import {TableColumnDefinition} from '@ft/table';
import {SharedService} from "../../../shared/services/shared-service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {TranslateService} from "@ngx-translate/core";


@Component({
  selector: 'ftb-convention-dialog',
  templateUrl: './convention-dialog.component.html',
  styleUrls: ['./convention-dialog.component.scss']
})

export class ConventionDialogComponent implements OnInit {
  loader: Subscription;

  public files: File[] = [];
  subscription: Subscription;

  convention: any = {tariff: {tm: 50, tp: 50}, exceptions: [], label: null};
  tableLines = new BehaviorSubject([]);
  tableDataChange = this.tableLines.asObservable();

  conventions: any = [];
  cls = 'convention';

  viewColumns: TableColumnDefinition[] = [
    {
      'label': 'settings.code',
      'key': 'procedure.code',
      'type': 'text'
    },
    {
      'label': 'settings.tm',
      'key': 'tm',
      'type': 'text'
    },
    {
      'label': 'settings.tp',
      'key': 'tp',
      'type': 'text'
    },
    {
      'label': 'shared.price',
      'key': 'procedure.price',
      'type': 'text'
    }

  ];
  columns: TableColumnDefinition[] = [

    {
      'label': 'settings.code',
      'key': 'procedure.code',
      'type': 'text',

    },
    {
      'label': 'shared.price',
      'key': 'procedure.price',
      'type': 'number',
      'numberConfig': 'float'
    },
    {
      'label': 'settings.tp',
      'key': 'tp',
      'type': 'number',
      'numberConfig': 'float',
      'editable': true,
      'min': (row) => {
        return 0;
      },
      'max': (row) => {
        return 100 - _.get(row, 'tm', 0);
      }
    },
    {
      'label': 'settings.tm',
      'key': 'tm',
      'type': 'number',
      'editable': true,
      'numberConfig': 'float',
      'min': (row) => {
        return 0;
      },
      'max': (row) => {
        return 100 - _.get(row, 'tp', 0);
      }
    },
    {
      label: '', key: 'actions', type: 'actions', actions: [
        {
          class: 'mat-warn',
          icon: 'mdi-close-circle',
          method: (item, index, ev) => {
            return this.removeException(item);
          },
        }
      ]
    }

  ];
  public linesColumns = new BehaviorSubject<TableColumnDefinition[]>(this.columns);
  public linesSource$: Observable<any[]> = of([]);

  constructor(private billingService: BillingService, public dialogRef: MatDialogRef<ConventionDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any, protected dialog: MatDialog,
              private sharedService: SharedService, protected snackBar: MatSnackBar,
              private translateService: TranslateService) {
  }

  ngOnInit() {
    this.initData(this.data);
  }

  initData(params) {
    this.billingService.getBillingDocs(this.cls)
      .subscribe(data => {
        if (data) {
          this.conventions = data;
        }
      });

    if (_.get(params, 'id')) {
      this.initModel(_.get(params, 'id'));
    }
  }

  initModel(pk) {
    this.billingService.getBillingDoc(this.cls, pk)
      .subscribe(data => {
        this.convention = data;
        this.tableLines.next(this.convention['exceptions']);
        this.linesSource$ = of(this.convention['exceptions']);

      });
  }

  newException() {
    this.dialog.open(SelectDocumentsDialogComponent, _.assign(LARGE_DIALOG, {
      data: PROCEDURE_VIEW
    })).afterClosed().subscribe(res => {
      if (res) {
        let existingProcedures = _.map(this.convention['exceptions'], 'procedure.code');
        let procedures = _.filter(res, (p) => {
          return !_.find(existingProcedures, (e) => {
            return _.get(p, 'code') == e
          });
        });
        this.convention['exceptions'] = _.concat(_.map(procedures, (e) => {
          return {procedure: e, tp: 0, tm: 0};
        }), this.convention['exceptions']);
        this.tableLines.next(this.convention['exceptions']);
        this.linesSource$ = of(this.convention['exceptions']);
      }
    });
  }

  cancel() {
    this.dialogRef.close(null);
  }


  save() {

    this.billingService.saveBillingDoc(this.convention, this.cls).subscribe(data => {
      this.convention = data;
      this.dialogRef.close(this.convention);
    });
  }


  onLinesChange(data) {
    this.convention['exceptions'] = data;
  }

  showConvention(c) {
    this.convention = c;
  }

  removeException(row) {
    _.remove(this.convention.exceptions, {procedure: {code: row.procedure.code}});
    this.tableLines.next(this.convention['exceptions']);
    this.linesSource$ = of(this.convention['exceptions']);
  }

  handleTariffPercents(ev, sourcePercent, targetPercent) {
    this.convention.tariff[targetPercent] = 100 - _.get(this.convention.tariff, sourcePercent, 0);
  }

  importExceptions(files: File[]) {
    let items = _.filter(files, i => i instanceof File);

    if (items.length > 0) {
      let extension = items[0].name.split('.').pop();
      if (extension == 'csv') {
        this.sharedService.readFile(items[0]).subscribe(fileString => {
          this.subscription = this.sharedService.handleImport(fileString, 'convention.import_exceptions', this.convention.id).subscribe(data => {
              this.files = [];
              if (data) {
                this.snackBar.open(this.translateService.instant('shared.upload_success'), null, {
                  duration: 2000,
                });
                this.initModel(this.convention.id);
              } else {
                this.snackBar.open(this.translateService.instant('shared.upload_file_error'), null, {
                  duration: 2000,
                });
              }

            },
            () => {
              this.files = [];
              this.snackBar.open(this.translateService.instant('shared.upload_file_error'), null, {
                duration: 2000,
              });
            }
          );
        })
      }
    }
  }
}
