import {Injectable} from '@angular/core';
import {getUrl} from '../../shared/utils/functions';
import {HttpClient} from '@angular/common/http';
import {FtWsService} from '@ft/core';

@Injectable({
  providedIn: 'root'
})
export class HlSevenService {

  constructor(private http: HttpClient, private ws: FtWsService) {
  }

  getConfig() {
    return this.http.get(`/api/hl-seven-config/`);
  }
  handleConfig(config) {
    return this.http.post(`/api/hl-seven-config/${config.id ? config.id + '/' : ''}`,  config);
  }
  startService() {
    return this.ws.call('hl-seven.start_service',  {});
  }
  stopServer() {
    return this.ws.call('hl-seven.stop_service',  {});
  }
  restartServer() {
    return this.ws.call('hl-seven.restart_service',  {});
  }

  getClients(){
    return this.http.get('api/hl-seven-client-config/');
  }
  saveClient(model){
    return this.http.post(`api/hl-seven-client-config/${model.id ? model.id + '/' : ''}`, model);
  }
  removeClient(pk){
    return this.http.delete(`api/hl-seven-client-config/${pk}/`);
  }

}
