<div class="ft-parent-layout mat-elevation-z1 ft-content-margin" fxFlex fxLayout="column">
  <mat-toolbar color="accent">
    <div class="ft-module-icon">
      <button type="button" mat-icon-button>
        <mat-icon fontSet="mdi" fontIcon="mdi-lan-connect"></mat-icon>
      </button>
    </div>
    <h2>{{ 'settings.hl_seven' | translate }}</h2>
  </mat-toolbar>
  <div class="ft-content" fxFlex>
    <form #form="ngForm" fxLayout="column" fxFlex>
      <!--<div fxLayout="row" fxLayoutGap="6px">
        <mat-form-field fxFlex>
          <input matInput    name="title" [(ngModel)]="hlSeven.title" readonly
                 [placeholder]="'shared.title'|translate">
        </mat-form-field>
        <mat-form-field fxFlex class="with-error-label">
          <input matInput [(ngModel)]="hlSeven.port"  name="port" type="number"
                 ftCustomValidation="hl-seven.check_port"
                 [query]="{field:'port', pk:hlSeven?.id}"
                 [disabled]="hlSeven['is_running'] || !hlSeven['port']"
                 #port="ngModel"
                 [placeholder]="'settings.port'|translate">
        </mat-form-field>
      </div>
      <div fxLayout="row" fxLayoutGap="6px">
        <span fxFlex></span>
        <button type="button" [disabled]="hlSeven['is_running']" (click)="startServer()" mat-raised-button class="has-icon" color="primary">
          <mat-icon fontSet="mdi" fontIcon="mdi-play"></mat-icon>
          <span>{{'settings.start'|translate}}</span>
        </button>
        <button type="button" [disabled]="!hlSeven['is_running']" (click)="stopServer()" mat-raised-button class="has-icon" color="warn">
          <mat-icon fontSet="mdi" fontIcon="mdi-stop"></mat-icon>
          <span>{{'settings.stop'|translate}}</span>
        </button>
        <button type="button" [disabled]="!hlSeven['is_running']" (click)="restartServer()" mat-raised-button class="has-icon"
                color="primary">
          <mat-icon fontSet="mdi" fontIcon="mdi-restart"></mat-icon>
          <span>{{'settings.restart'|translate}}</span>
        </button>
      </div>
      <hr class="ft-sep"> -->
      <div fxFlex fxLayout="column">

        <ft-generic-body-table fxFlex
                               [remote]="false"
                               [source]="linesSource$"
                               [selectable]="false"
                               [hasPagination]="true"
                               [columns]="linesColumns | async"
                               [multiActions]="linesActions"
        >
        </ft-generic-body-table>

      </div>
    </form>
  </div>


</div>
