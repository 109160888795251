import {TableColumnDefinition} from '@ft/table/table/models/table-definitions';

export const CARE_SHEET_COLUMNS: TableColumnDefinition[] = [
  {
    "label": "invoicing.care_sheet_number",
    "key": "number",
    "sortBy": "number",
    "type": "text",
    "is_global_searchable": true
  },
  {
    "label": "shared.date",
    "key": "doc_date",
    "sortBy": "doc_date",
    "type": "date",
    "is_global_searchable": true,
  },
  {
    "label": "shared.patient",
    "key": "patient.full_name",
    "sortBy": "patient.full_name",
    "type": "text",
    "is_global_searchable": false
  },
  {
    "label": "shared.insurance",
    "key": "insurance.name",
    "sortBy": "insurance.name",
    "type": "text",
    "is_global_searchable": true,
    'model': 'shared.insurance.Organization'
  },
  {
    "label": "shared.total",
    "key": "taxed_amount",
    "sortBy": "taxed_amount",
    "type": "number",
    'numberConfig': 'currency',
    "is_global_searchable": true
  }
]
