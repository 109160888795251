import {Component, Inject, OnInit} from '@angular/core';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {FormControl, Validators} from '@angular/forms';
import {BillingService} from '../../../shared/services/billing.service';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {BehaviorSubject, Observable, of, Subscription} from 'rxjs';
import * as _ from 'lodash';
import {SelectDocumentsDialogComponent} from '../../../shared/dialogs/select-documents-dialog/select-documents-dialog.component';
import {LARGE_DIALOG} from '@ft/core';
import {CONVENTION_VIEW} from '../../utils/views-settings';
import {TableColumnDefinition} from '@ft/table';
import {TranslateService} from '@ngx-translate/core';
import {PAYMENT_CONDITIONS} from '../../../shared/utils/payment-conditions';
import {MatChipInputEvent} from "@angular/material/chips";
import {SharedService} from "../../../shared/services/shared-service";

const LINES_COLUMNS: TableColumnDefinition[] = [
  {
    'label': 'settings.name',
    'key': 'convention.label',
    'type': 'text'
  },
  {
    'label': 'settings.tp',
    'key': 'convention.tariff.tp',
    'type': 'text'
  },
  {
    'label': 'settings.tm',
    'key': 'convention.tariff.tm',
    'type': 'text',
    // 'editable': true
  }
];

@Component({
  selector: 'ftb-organization-form',
  templateUrl: './organization-form.component.html',
  styleUrls: ['./organization-form.component.scss']
})

export class OrganizationFormComponent implements OnInit {

  loading: Subscription;
  paymentConditions: any = PAYMENT_CONDITIONS;

  organization: any = {payment_condition: PAYMENT_CONDITIONS[0], conventions:[]};

  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  emailFormControl = new FormControl('', [
    Validators.required,
    Validators.email,
  ]);

  cls = 'organization';
  tableLines = new BehaviorSubject([]);
  tableDataChange = this.tableLines.asObservable();


  linesSource$: Observable<any[]> = of([]);


  actionColumn: TableColumnDefinition = {

    label: '', key: 'actions', type: 'actions', actions: [
      {
        icon: 'mdi-star-circle',
        tooltip: this.translateService.instant('settings.set_default'),
        method: (item, index, ev) => {
          this.setDefaultConvention(item);
        },
        class: (row) => {

          return !_.isNil(row) && row.is_default ? 'mat-primary' : '';
        }
      }, {
        class: 'mat-warn',
        icon: 'mdi-close-circle',
        method: (item, index, ev) => {
          return this.removeConvention(item, index);
        },
        // acl: {resource: 'profile', action: 'get', behavior: 'remove'}
      }

    ]
  };
  linesColumns = new BehaviorSubject<TableColumnDefinition[]>(_.concat(LINES_COLUMNS, this.actionColumn));
  linesActions = [
    {
      class: 'mat-primary',
      icon: 'mdi-plus',
      isMultipleSelection: false,
      method: (item, ev) => {
        return this.addConvention();
      },
      tooltip: this.translateService.instant('shared.select_conventions')
    }
  ];
  pricingList: any = [];
  citiesList: any = [];


  constructor(private billingService: BillingService, private sharedService: SharedService, private route: ActivatedRoute, private router: Router, protected dialog: MatDialog, private translateService: TranslateService) {
    this.billingService.getBillingDocs('pricing').subscribe(data => {
      this.pricingList = data;
    });
    this.sharedService.getCitiesList().subscribe(data => {
      this.citiesList = data;
    });
  }

  ngOnInit() {
    this.organization['phone_numbers'] = [];
    this.route.params.subscribe(params => this.initData(params));
  }

  initData(params) {
    if (_.get(params, 'id')) {
      this.loading = this.billingService.getBillingDoc(this.cls, _.get(params, 'id'))
        .subscribe(data => {
          this.organization = data;
          this.tableLines.next(this.organization['conventions']);
          this.linesSource$ = of(this.organization['conventions']);

        });
    }
  }

  save() {
    this.loading = this.billingService.saveBillingDoc(this.organization, 'organization').subscribe(data => {
      if (!_.has(this.organization, 'id')) {
        this.router.navigate(['/settings/organization-form', data['id']], {replaceUrl: true});
      }
      this.organization = data;
      this.tableLines.next(this.organization['conventions']);
      this.linesSource$ = of(this.organization['conventions']);
    });
  }

  exit() {
    this.router.navigate(['/settings/organization-list']);
  }

  onLinesChange(data) {
    this.organization['conventions'] = data;
  }

  removePhoneItem(item) {
    const index = this.organization['phone_numbers'].indexOf(item);

    if (index >= 0) {
      this.organization['phone_numbers'].splice(index, 1);
    }

  }

  addPhoneItem(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    // Add our number
    if ((value || '').trim()) {
      this.organization['phone_numbers'].push(value.trim());
    }
// Reset the input value
    if (input) {
      input.value = '';
    }
  }

  addConvention() {

    this.dialog.open(SelectDocumentsDialogComponent, _.assign(LARGE_DIALOG, {
      data: CONVENTION_VIEW
    })).afterClosed().subscribe(res => {
      if (res) {
        this.organization['conventions'] = _.chain(res).map((e) => {
          return {is_default: false, convention: e};
        }).concat(this.organization['conventions']).uniqBy('convention.label').value();
        this.tableLines.next(this.organization['conventions']);
        this.linesSource$ = of(this.organization['conventions']);

      }
    });
  }

  goToList() {
    this.router.navigate(['/settings/organization-list'], {replaceUrl: false});
  }

  removeConvention(obj, idx) {
    // _.remove(this.organization['conventions'], {convention: {label: obj.convention.label}});
    this.organization['conventions'].splice(idx, 1);
    this.tableLines.next(this.organization['conventions']);
    this.linesSource$ = of(this.organization['conventions']);
  }

  setDefaultConvention(obj) {
    _.forEach(this.organization['conventions'], (e) => {
      _.update(e, 'is_default', function (n) {
        return false;
      });
    });
    obj.is_default = !obj.is_default;
    this.tableLines.next(this.organization['conventions']);
    this.linesSource$ = of(this.organization['conventions']);
  }

  compareCondition(o1, o2) {
    return o1 && o2 ? o1.key == o2.key : false;
  }

  comparePricingCondition(o1, o2) {
    return o1 && o2 ? o1.name == o2.name : false;
  }

  compareCityCondition(o1, o2) {
    return o1 && o2 ? o1.full_name == o2.full_name : false;
  }
}
