import {TableColumnDefinition} from '@ft/table/table/models/table-definitions';

export const ORGANIZATION_COLUMNS: TableColumnDefinition[] =
  [
    {
      "label": "shared.code",
      "key": "code",
      "sortBy": "code",
      "type": "text",
      "is_global_searchable": true
    },
    {
      "label": "shared.name",
      "key": "name",
      "sortBy": "name",
      "type": "text",
      "is_global_searchable": true
    },
    {
      "label": "shared.full_name",
      "key": "full_name",
      "sortBy": "full_name",
      "type": "text",
      "is_global_searchable": true
    },
    {
      "label": "shared.address",
      "key": "address",
      "sortBy": "address",
      "type": "text",
      "is_global_searchable": true
    },
    {
      "label": "shared.email",
      "key": "email",
      "sortBy": "email",
      "type": "text",
      "is_global_searchable": true
    },
    {
      "label": "shared.phone_number",
      "key": "phone_numbers",
      "type": "text",
      "is_global_searchable": true
    },
    {
      "label": "settings.postal_code",
      "key": "postal_code",
      "type": "text",
      "is_global_searchable": true
    },
    {
      "label": "settings.city",
      "key": "city.full_name",
      "type": "text",
      "is_global_searchable": false,
      'is_ref': true,
      'model': 'ft_core.generic_api.locations.City'
    }
  ]
