<div class="ft-parent-layout mat-elevation-z1" [class.ft-content-margin]="true" fxFlex fxLayout="column">
  <nav mat-tab-nav-bar fxLayout="column" class="toolbar-links" #navBar   >
    <ng-container *ngFor="let link of navLinks">
      <ng-template [ftAcl]="link.acl" >
        <a mat-tab-link name="{{link.name}}"
           [routerLink]="link.path"
           [queryParams]="link.params()"
           [disabled]="link.isDisabled()"
           routerLinkActive
           #rla="routerLinkActive"
           [active]="rla.isActive"
           [ngClass]="{'with-icon': (link.icon && link.label)}">
          <ng-container *ngIf="link.icon">
            <mat-icon [fontIcon]="link.icon" fontSet="mdi"></mat-icon>
          </ng-container>
          <ng-container *ngIf="link.label">
            <span>{{link.label | translate}}</span>
          </ng-container>
        </a>
      </ng-template>
    </ng-container>
    <span fxFlex></span>
    <button type="button" *ngIf="!activateList" mat-button class="with-icon" (click)="handleNew($event)">
      <mat-icon fontSet="mdi" fontIcon="mdi-playlist-plus"></mat-icon>
      <span>{{'shared.submit_add'|translate}}</span>
    </button>

    <button type="button" *ngIf="activateList" mat-button class="with-icon" style="margin-right: 0;" (click)="handleList()">
      <mat-icon fontSet="mdi" fontIcon="mdi-format-list-bulleted"></mat-icon>
      <span></span>
    </button>
  </nav>
  <div fxLayout="column" fxFlex  >
    <router-outlet></router-outlet>
  </div>
</div>


