import {Injectable} from '@angular/core';
import {FtWsService} from '@ft/core';
import {HttpClient} from '@angular/common/http';
import * as _ from "lodash";

@Injectable({
  providedIn: 'root'
})
export class PatientService {

  constructor(private http: HttpClient, private ws: FtWsService) {
  }

  handlePatientNotification(pk){
    return this.ws.call('patient.handle_notification', {_pk: pk});
  }
}
