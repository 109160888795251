import {INVOICE_COLUMNS} from './invoice';
import {TableColumnDefinition} from '@ft/table/table/models/table-definitions';

export const QUOTATION_COLUMNS: TableColumnDefinition[] = [
  {
    "label": "invoicing.quotation_number",
    "type": "text",
    "key": "number",
    "sortBy": "number",
    "is_global_searchable": true
  },
  {
    "label": "shared.date",
    "type": "date",
    "key": "doc_date",
    "sortBy": "doc_date",
    "is_global_searchable": true
  },
  {
    'label': 'shared.patient_first_name',
    'type': 'text',
    'key': 'patient.first_name',
    'sortBy': 'patient.first_name',
    'is_global_searchable': true,
    'is_ref': true,
    "model": "shared.FtPatient"
  },
  {
    'label': 'shared.patient_last_name',
    'type': 'text',
    'key': 'patient.last_name',
    'sortBy': 'patient.last_name',
    'is_global_searchable': true,
    'is_ref': true,
    "model": "shared.FtPatient"
  },
  {
    'label': 'shared.organization',
    'key': 'organization.name',
    'sortBy': 'organization.name',
    'type': 'text',
    'is_ref': true,
    'model': 'shared.insurance.Organization',
    'is_global_searchable': true
  },
  {
    "label": "invoicing.other_name",
    "type": "text",
    "key": "other_beneficiary",
    "sortBy": "other_beneficiary",
    "is_global_searchable": true
  },
  {
    "label": "shared.valid_date",
    "type": "date",
    "key": "valid_date",
    "is_global_searchable": false
  },
  {
    "label": "shared.taxed_amount",
    "type": "number",
    "key": "taxed_amount",
    "sortBy": "taxed_amount",
    'numberConfig': 'currency',
    "is_global_searchable": true
  }
]
