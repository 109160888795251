import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'ftb-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {
  navLinks: any[] = [];

  constructor() {
  }

  ngOnInit() {
  }

}
