import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {Subscription} from "rxjs";
import * as _ from "lodash";
import {DailyWorkflowService} from "../../services/daily-workflow.service";
import {getLineAmount} from "../../../shared/utils/functions";

@Component({
  selector: 'ftb-patient-dialog',
  templateUrl: './billing-request-dialog.component.html',
  styleUrls: ['./billing-request-dialog.component.scss']
})
export class BillingRequestDialogComponent implements OnInit {
  loader: Subscription;
  model: any = {};
  pk = null;
  full_name = null;
  payment_status = null;

  constructor(public dialogRef: MatDialogRef<BillingRequestDialogComponent>, private dailyWorkflowService: DailyWorkflowService,
              @Inject(MAT_DIALOG_DATA) public data: any, protected dialog: MatDialog) {
  }

  ngOnInit() {
    this.model = _.get(this.data,'model');
    this.pk=_.get(this.data, 'pk')
    this.full_name=_.get(this.data, 'full_name');
    this.payment_status=_.get(this.data, 'payment_status');
  }

  save() {
    this.dailyWorkflowService.handleUpdate(this.model, this.pk).subscribe(data=>{
      this.dailyWorkflowService.handleChangeNotification(this.pk).subscribe(data=>{_.noop()});
      this.dialogRef.close(true);
    });
  }

  getFullAmount() {
    return _.chain(this.model.procedures).sumBy((row) => {
      return getLineAmount(row);
    }).value();
  }

  handleDiscount(is_amount = false) {
    let taxed_amount = this.getFullAmount();
    if (is_amount) {
      this.model.global_discount = _.get(this.model, 'global_discount_amount', 0) * 100 / taxed_amount;
    } else {
      this.model.global_discount_amount = _.get(this.model, 'global_discount', 0) * taxed_amount / 100;
    }
    if (this.model.global_discount) {
      this.handleLinesGlobalDiscount(taxed_amount);
    }
  }

  handleLinesGlobalDiscount(taxed_amount) {
    _.forEach(this.model.procedures, (l) => {
      let st1 = (l.qte * l.price) - l.discount_amount;
      l.discount_amount_from_global = _.get(this.model, 'global_discount_amount', 0) * (st1 * 100 / taxed_amount) / 100;
      l.discount_from_global = l.discount_amount_from_global * 100 / st1;
    })
  }

}
