export const lang = {
  yes: 'Oui',
  no: 'Non',
  billing: 'Facturation',
  payment: 'Paiement',
  states: 'Rapports',
  title: 'Titre',
  prefix: 'Préfixe',
  increment_by: 'Incrémenter Par',
  start_by: 'Démarrer Par',
  next_value: 'Prochaine valeur',
  filling: 'Remplissage',
  suffix: 'Suffixe',
  daily_workflow: 'Flux journalier',
  no_element_to_show: 'Aucun élément trouvé',
  search: 'Rechercher',
  settings: "Paramétres",
  patients: "Patients"
};
