import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import * as _ from 'lodash';

@Component({
  selector: 'ftb-select-documents-dialog',
  templateUrl: './select-documents-dialog.component.html',
  styleUrls: ['./select-documents-dialog.component.scss']
})
export class SelectDocumentsDialogComponent implements OnInit {
  settings = null;
  selectedItems = [];
  tableSettings = null;

  constructor(public dialogRef: MatDialogRef<SelectDocumentsDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any ) {
  }

  ngOnInit() {
    this.settings = this.data ? this.data : {};
    this.tableSettings = this.data ? this.data : {};

    _.assign(this.settings, !this.settings.selectable ? {
      rowClick: (row) => {
        return this.select(row);
      }
    } : {});
    _.assign(this.tableSettings, !this.settings.selectable ? {
      lineClick: (row) => {
        return this.select(row);
      }
    } : {});

  }
  handleSelectionChange(data){
    this.selectedItems = data;
  }

  add() {
    this.dialogRef.close(this.selectedItems);
  }

  select(row) {
    this.dialogRef.close(row);
  }
}
