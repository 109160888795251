export const lang = {
  generals: 'General settings',
  app_config: 'Application configuration',
  organization_list: 'Organization List',
  organization: 'Organization',
  search: 'Search',
  procedures: 'Procedures',
  procedure: 'Procedures',
  procedure_name: 'Name',
  code: 'Code',
  procedure_price: 'Honorary',
  is_refundable: 'Refundable',
  tnr: 'TNR',
  ngap_code: 'NGAP code',
  ccam_code: 'CCAM code',
  procedure_save_success: 'Procedure Registered!',
  organization_save_success: 'Organization Registered!',
  convention_save_success: 'Convention Registered!',
  delete_object_warning: 'You are about to delete this object. Are you sure you want to continue?',
  delete_object_failed: 'Can not delete this document Please check out its dependence!',
  delete_object_success: 'Object successfully deleted',
  procedures_catalogues: 'Procedures Catalogs',
  sequence_numbers: 'Sequence numbers',
  add_procedure: 'Add procedure',
  conventions: 'Conventions',
  name: 'Name',
  tp: 'Third-party payment',
  tm: 'Patient fees',
  add_exception: 'Exception',
  convention_list: 'Conventions list',
  users_management: 'Users Management',
  profiles: 'Profiles management',
  list_setup: 'Lists management',
  assistants: 'Assistants',
  physicians: 'Doctors',
  catalogues: 'Catalogs',
  is_default: 'Default',
  base_settings: 'Basic parameters',
  general: 'General',
  system: 'System',
  exercise: 'Exercise',
  exercise_start: 'The fiscal year starts in',
  devices_shared_files: 'Devices and connections',
  hl_seven: 'HL7',
  port: 'Harbor',
  start: 'Start',
  stop: 'Stop',
  restart: 'Restart',
  hl_seven_server: 'HL7 Server Setup',
  hl_seven_services: 'HL7 Services',
  hl_seven_client: 'HL7 Client',
  host: 'IP Address',
  active: 'Activate',
  quotation_valid_duration: 'Validity of the quotes',
  printing_setup: 'Print templates',
  set_default: 'Default',
  import: 'Import',
  import_exceptions: 'Import .csv exceptions \n code,patient(%),Third-part(%) ',
  export_exceptions: 'Download Exceptions .xls',
  pricing_list: 'Pricing list',
  tariffs: 'Prices',
  default_pricing: 'Default price',
  invoice_payment_from_docs: 'Payment of invoices related to the documents',
  accounting_code: 'Accounting code',
  shared_code: 'Shared code',
  import_procedure_tooltip: 'File .csv : Code, Price, Name, NGAP_CODE, IS_REFUNDABLE (0 | 1)',
  postal_code: "Postal code",
  city: "City"
};
