import {Component, Inject, OnInit} from '@angular/core';
import * as _ from 'lodash';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {BillingService} from '../../../shared/services/billing.service';

@Component({
  selector: 'ftb-delete-dialog',
  templateUrl: './delete-dialog.component.html',
  styleUrls: ['./delete-dialog.component.scss']
})
export class DeleteDialogComponent implements OnInit {
  message_text = 'message text is missing';
  obj = null;
  deleteTopic = null;
  deleteCls = null;

  constructor(private billingService: BillingService, public dialogRef: MatDialogRef<DeleteDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    this.message_text = _.get(data, 'message_text', this.message_text);
    this.obj = _.get(data, 'obj');
    this.deleteTopic = _.get(data, 'deleteTopic');
    this.deleteCls = _.get(data, 'deleteCls');
  }

  ngOnInit() {
  }

  confirm() {
    if (_.get(this.obj, 'state.is_valid')) {
      this.dialogRef.close(false);
    }
    else {
      if (this.deleteTopic) {
        this.billingService.removeBillingDoc({
          http: null,
          topic: this.deleteTopic,
          _pk: this.obj.id
        }).subscribe(data => this.dialogRef.close(data));
      }
      else {
        this.billingService.deleteBillingDoc(this.deleteCls, this.obj.id).subscribe(data => this.dialogRef.close(data));
      }
    }
  }

}
