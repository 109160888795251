import {DocumentListSettings} from '../../shared/models/document-list-definition';
import {PROCEDURE_COLUMNS} from './table-configs/procedure';
import {ProcedureDialogComponent} from '../../settings/dialogs/procedure-dialog/procedure-dialog.component';
import {PHYSICIAN_COLUMNS} from "./table-configs/physician";


export const PHYSICIAN_VIEW: DocumentListSettings = {
  trackByKey: 'id',
  hasSearch: true,
  selectable: true,
  hasPagination: true,
  viewNamespace: 'PhysicianList',
  query: [],
  viewSettings: {
    'model': 'shared.FtPhysician',
    'viewColumns': PHYSICIAN_COLUMNS,
    'systemColumns': [],
    'defaultName': 'Liste des médecins'
  },
  actions: [],
  headerLabel: 'shared.physician_list',
  headerIcon: 'mdi-account-search',
};



export const PROCEDURE_VIEW: DocumentListSettings = {
  trackByKey: 'id',
  hasSearch: true,
  selectable: true,
  hasPagination: true,
  viewNamespace: 'Procedure_List',
  query: [],
  viewSettings: {
    'model': 'shared.procedure.Procedure',
    'viewColumns': PROCEDURE_COLUMNS,
    'systemColumns': [
      {
        'key': 'created_at', 'label': 'created_at', 'type': 'date'
      },
      {
        'key': 'updated_at', 'label': 'updated_at', 'type': 'date'
      },
      {
        'key': 'is_deleted', 'label': 'is_deleted', 'type': 'boolean'
      },
      {
        'key': 'deleted_at', 'label': 'deleted_at', 'type': 'date'
      },
    ],
    'defaultName': 'Toutes les procedures'
  },
  actions: [],
  resource:'procedure',
  saveLabel: 'settings.procedure_save_success',
  headerLabel: 'settings.procedures',
  deleteTopic: 'procedure.check_and_delete',
  headerIcon: 'mdi-medical-bag',
  formRoute: null,
  formLabel: 'shared.procedure',
  formDialog: ProcedureDialogComponent

};

