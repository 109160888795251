import { Component, OnInit } from '@angular/core';
import {DrawerLink} from '@ft/core';
import {LINKS} from '../../utils/settings-links';

@Component({
  selector: 'ftb-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class FtbMainComponent implements OnInit {
  public links: DrawerLink[] = LINKS;

  constructor() { }

  ngOnInit() {
  }

}
