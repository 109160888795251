import {TableColumnDefinition} from '@ft/table/table/models/table-definitions';

export const PAYMENT_COLUMNS: TableColumnDefinition[] = [
  {
    'label': 'payment.payment_number',
    'type': 'text',
    'key': 'number',
    'sortBy': 'number',
    'is_global_searchable': true
  },
  {
    'label': 'shared.date',
    'type': 'date',
    'key': 'doc_date',
    'sortBy': 'doc_date',
    'is_global_searchable': true
  },
  {
    'label': 'shared.patient_first_name',
    'type': 'text',
    'key': 'patient.first_name',
    'sortBy': 'patient.first_name',
    'is_global_searchable': true,
    is_ref: true,
    "model":"shared.FtPatient"
  },
  {
    'label': 'shared.patient_last_name',
    'type': 'text',
    'key': 'patient.last_name',
    'sortBy': 'patient.last_name',
    'is_global_searchable': true,
    is_ref: true,
    "model":"shared.FtPatient"
  },
  {
    'label': 'shared.organization',
    'type': 'text',
    'key': 'organization.name',
    'sortBy': 'organization.name',
    'is_ref': true,
    'model': 'shared.insurance.Organization',
    'is_global_searchable': true
  }
];
