<div fxFlex class="ft-padding" fxLayout="column">
<form fxFlex fxLayout="column" fxLayoutGap="6px"    [ngBusy]="busy">
  <div fxLayout="row" fxLayoutGap="6px">
    <mat-form-field fxFlex="nogrow">
      <input matInput ftDate [matDatepicker]="doc_date" [placeholder]="'shared.date'|translate"
             [(ngModel)]="filter.doc_date" name="doc_date" (ngModelChange)="handleFilterChange($event)">
      <mat-datepicker-toggle matSuffix [for]="doc_date">
        <mat-icon matDatepickerToggleIcon fontSet="mdi" fontIcon="mdi-calendar-today"></mat-icon>
      </mat-datepicker-toggle>
      <mat-datepicker #doc_date></mat-datepicker>
    </mat-form-field>
  </div>
  <div fxLayout="column" fxLayoutGap="20px">
    <div fxLayout="row">
      <div fxFlex="" class="table-container state-table">
        <mat-table [dataSource]="dataSource">
          <ng-container matColumnDef="daily_paid_amount">
            <mat-header-cell *matHeaderCellDef> {{ 'states.daily_paid_amount'|translate}}</mat-header-cell>
            <mat-cell *matCellDef="let item"> {{item.daily_paid_amount|ftNumber:'currency'}}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="anterior_paid_amount">
            <mat-header-cell *matHeaderCellDef> {{ 'states.anterior_paid_amount'|translate}}</mat-header-cell>
            <mat-cell *matCellDef="let item"> {{item.anterior_paid_amount | ftNumber:'currency'}}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="full_paid_amount">
            <mat-header-cell *matHeaderCellDef> {{ 'states.full_paid_amount'|translate}}</mat-header-cell>
            <mat-cell *matCellDef="let item"> {{item.daily_paid_amount + item.anterior_paid_amount  | ftNumber:'currency'}}
            </mat-cell>
          </ng-container>


          <ng-container matColumnDef="item-description">
            <mat-header-cell colspan="3" *matHeaderCellDef> {{'states.checkout'|translate}}</mat-header-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="['item-description' ]"></mat-header-row>
          <mat-header-row *matHeaderRowDef="['daily_paid_amount', 'anterior_paid_amount','full_paid_amount']">
          </mat-header-row>
          <mat-row
            *matRowDef="let row; columns: ['daily_paid_amount', 'anterior_paid_amount','full_paid_amount']">
          </mat-row>

        </mat-table>
      </div>
    </div>

    <div fxLayout="row">
      <div fxFlex="" class="table-container state-table">
        <mat-table [dataSource]="dailyTreasurySource">
          <ng-container matColumnDef="empty">
            <mat-header-cell *matHeaderCellDef></mat-header-cell>
            <mat-cell *matCellDef="let item" class="mat-header-cell"> {{ item?.empty|translate}}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="CASH">
            <mat-header-cell *matHeaderCellDef>{{ 'shared.CASH'|translate}}</mat-header-cell>
            <mat-cell *matCellDef="let item"> {{ item?.CASH| ftNumber:'currency'}}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="CHCK">
            <mat-header-cell *matHeaderCellDef>{{ 'shared.CHCK'|translate}}</mat-header-cell>
            <mat-cell *matCellDef="let item">{{ item?.CHCK| ftNumber:'currency'}}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="DEBC">
            <mat-header-cell *matHeaderCellDef>{{ 'shared.DEBC'|translate}}</mat-header-cell>
            <mat-cell *matCellDef="let item"> {{ item?.DEBC| ftNumber:'currency'}}</mat-cell>
          </ng-container>


          <ng-container matColumnDef="item-description">
            <mat-header-cell colspan="4" *matHeaderCellDef> {{'states.daily_immediate_payments'|translate}}
            </mat-header-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="['item-description' ]"><</mat-header-row>
          <mat-header-row *matHeaderRowDef="['empty','CASH', 'CHCK','DEBC']">
          </mat-header-row>
          <mat-row
            *matRowDef="let row; columns: ['empty','CASH', 'CHCK','DEBC']">
          </mat-row>

        </mat-table>
      </div>
    </div>
    <div fxLayout="row">
      <div fxFlex="" class="table-container state-table">
        <mat-table [dataSource]="anteriorTreasurySource">
          <ng-container matColumnDef="empty">
            <mat-header-cell *matHeaderCellDef></mat-header-cell>
            <mat-cell *matCellDef="let item" class="mat-header-cell"> {{ item?.empty|translate}}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="CASH">
            <mat-header-cell *matHeaderCellDef>{{ 'shared.CASH'|translate}}</mat-header-cell>
            <mat-cell *matCellDef="let item"> {{ item?.CASH| ftNumber:'currency'}}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="CHCK">
            <mat-header-cell *matHeaderCellDef>{{ 'shared.CHCK'|translate}}</mat-header-cell>
            <mat-cell *matCellDef="let item">{{ item?.CHCK| ftNumber:'currency'}}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="DEBC">
            <mat-header-cell *matHeaderCellDef>{{ 'shared.DEBC'|translate}}</mat-header-cell>
            <mat-cell *matCellDef="let item"> {{ item?.DEBC| ftNumber:'currency'}}</mat-cell>
          </ng-container>


          <ng-container matColumnDef="item-description">
            <mat-header-cell colspan="4" *matHeaderCellDef> {{'states.anterior_payments'|translate}}</mat-header-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="['item-description' ]"><</mat-header-row>
          <mat-header-row *matHeaderRowDef="['empty','CASH', 'CHCK','DEBC']">
          </mat-header-row>
          <mat-row
            *matRowDef="let row; columns: ['empty','CASH', 'CHCK','DEBC']">
          </mat-row>

        </mat-table>
      </div>
    </div>
    <div fxLayout="row">
      <div fxFlex="" class="table-container state-table">
        <mat-table [dataSource]="dataSource">
          <ng-container matColumnDef="value">
            <mat-header-cell *matHeaderCellDef>{{ 'states.value'|translate}}</mat-header-cell>
            <mat-cell *matCellDef="let item">{{ item?.daily_taxed_amount + item?.anterior_taxed_amount|
              ftNumber:'currency'}}
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="paid">
            <mat-header-cell *matHeaderCellDef>{{ 'states.paid'|translate}}</mat-header-cell>
            <mat-cell *matCellDef="let item">{{ item?.daily_paid_amount + item?.anterior_paid_amount |
              ftNumber:'currency'}}
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="invoiced">
            <mat-header-cell *matHeaderCellDef>{{ 'states.invoiced'|translate}}</mat-header-cell>
            <mat-cell *matCellDef="let item">{{ item?.daily_taxed_amount + item?.anterior_taxed_amount |
              ftNumber:'currency' }}
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="remaining">
            <mat-header-cell *matHeaderCellDef>{{ 'states.remaining'|translate}}</mat-header-cell>
            <mat-cell *matCellDef="let item">{{(item?.daily_taxed_amount - item?.daily_paid_amount)  | ftNumber:'currency'}}
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="patients">
            <mat-header-cell *matHeaderCellDef>{{ 'states.patients'|translate}}</mat-header-cell>
            <mat-cell *matCellDef="let item">{{ item?.patients  |  ftNumber:'currency'}}
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="patient_payments">
            <mat-header-cell *matHeaderCellDef>{{ 'states.patient_payments'|translate}}</mat-header-cell>
            <mat-cell *matCellDef="let item">{{ item?.patient_payments}}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="organization_payments">
            <mat-header-cell *matHeaderCellDef>{{ 'states.organization_payments'|translate}}</mat-header-cell>
            <mat-cell *matCellDef="let item">{{ item?.organization_payments}}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="item-description">
            <mat-header-cell colspan="3" *matHeaderCellDef> {{'states.values'|translate}}</mat-header-cell>
          </ng-container>
          <mat-header-row *matHeaderRowDef="['item-description' ]"></mat-header-row>
          <mat-header-row
            *matHeaderRowDef="['value', 'paid','invoiced','remaining', 'patients','patient_payments','organization_payments']">
          </mat-header-row>
          <mat-row
            *matRowDef="let row; columns: ['value', 'paid','invoiced','remaining', 'patients','patient_payments','organization_payments']">
          </mat-row>
        </mat-table>
      </div>
    </div>
  </div>
</form>
  <div>
    <button fxLayoutAlign="left bottom" type="button"  mat-mini-fab color="primary"
             [disabled]="results.length>0"
            [ftPrint]="{group:'payment-state', view:'/api/billing/payment/',customView:'print-unique',  model:null, extraQuery:{data:formatFilter()},  unique:true}">
      <mat-icon fontSet="mdi" fontIcon="mdi-printer"></mat-icon>
    </button>
  </div>

</div>
