<div fxFlex fxLayout="column" class="ft-content ft-padding">
 <div [ngBusy]="loading"></div>
  <div fxFlex *ngIf="quotation.state?.is_valid" fxLayoutAlign="center center" class="validate-icon">
    <img mat-card-image src="/assets/lock-outline.svg" alt="validation img">
  </div>
  <form novalidate #quotationForm="ngForm" [ngBusy]="loading" fxLayout="column" fxFlex fxLayoutGap="6px">
    <div fxLayout="row" fxLayoutGap="6px">
      <div fxFlex fxLayout="column">
        <div fxLayout="row" fxLayoutGap="6px">
          <mat-form-field>
            <input matInput type="text" required [ftSequenceInput]="seqQuery.cls" [ftSequenceToggle]="toggle"
                   name="sequence"
                   placeholder="{{'invoicing.quotation_number'|translate}}" [(ngModel)]="quotation.number"/>
            <ft-sequence-toggle [disabled]="quotation.id"  matSuffix #toggle [(objectId)]="loadedObject"
                                [config]="{cls: seqQuery.cls, field: 'number'}">
            </ft-sequence-toggle>
          </mat-form-field>


          <mat-form-field>
            <input matInput ftDate [matDatepicker]="inv_date" [placeholder]="'shared.date'|translate"
                   [(ngModel)]="quotation.doc_date" name="doc_date">
            <mat-datepicker-toggle matSuffix [for]="inv_date">
              <mat-icon matDatepickerToggleIcon fontSet="mdi" fontIcon="mdi-calendar-today"></mat-icon>
            </mat-datepicker-toggle>
            <mat-datepicker #inv_date></mat-datepicker>
          </mat-form-field>
          <mat-form-field>
            <input matInput [placeholder]="'invoicing.valid_duration'|translate" required ftDefaultValues="quotation_valid_duration"
                   [(ngModel)]="quotation.valid_duration"
                   type="number" name="valid_duration"/>
            <span matSuffix> {{'shared.days_suffix'|translate}}</span>
          </mat-form-field>
        </div>
        <div fxLayout="row" fxLayoutGap="6px">
          <mat-form-field fxFlex="72">
            <input matInput [placeholder]="'invoicing.quotation_pattern'|translate"
                   [(ngModel)]="quotation.quotation_pattern"
                   name="number"/>
          </mat-form-field>
        </div>
      </div>
      <mat-divider [vertical]="true"></mat-divider>
      <div fxFlex fxLayout="column">
        <div fxLayout="row">

          <mat-form-field fxFlex="" floatLabel="always" class="ft-radio-field">
            <input matInput placeholder="{{'shared.beneficiary'|translate}}" style="display: none">
            <mat-radio-group [(ngModel)]="quotation.beneficiary_type" name="b_type" >
              <mat-radio-button *ngFor="let b_type of ['patient','organization', 'other']" [value]="b_type">
                {{'shared.'+b_type|translate}}
              </mat-radio-button>
            </mat-radio-group>
          </mat-form-field>
        </div>
        <div fxLayout="row">
          <ftb-select-input *ngIf="quotation.beneficiary_type=='patient'" fxFlex="50" [label]="'shared.patient'" [(model)]="quotation.patient" value="full_name"
                                    [settings]="patientInputSettings" [dialog]="patientDialog"></ftb-select-input>

          <ftb-select-input *ngIf="quotation.beneficiary_type=='organization'" fxFlex="50" [label]="'shared.organization'" [(model)]="quotation.organization" value="name"
                            [settings]="organizationInputSettings" ></ftb-select-input>

        <mat-form-field *ngIf="quotation.beneficiary_type=='other'"  fxFlex="50">
          <input matInput [placeholder]="'invoicing.other_name'|translate"
                 [(ngModel)]="quotation.other_beneficiary"
                 name="number"/>
        </mat-form-field>
        </div>
      </div>
    </div>
    <div fxFlex>
      <ftb-document-lines fxFlex [(lines)]="quotation.lines" [model]="quotation" [cls]="'Quotation'" [showDetailedAmounts]="false"></ftb-document-lines>
    </div>
  </form>
</div>
<ftb-document-actions fxFlex="nogrow" [model]="quotation" [cls]="cls" (modelChange)="onModelChange($event)" [loadedObject]="loadedObject"
                      [formInvalid]="quotationForm.invalid ||  (!quotation.patient && !quotation.organization && !quotation.other_beneficiary)"
                      [routes]="routes" [(busy)]="loading"></ftb-document-actions>



