import {Component, OnInit} from '@angular/core';
import {AppConfigsService, FtWsService} from '@ft/core';
import {Subscription} from 'rxjs';
import {SwUpdate} from '@angular/service-worker';
import {TranslateService} from "@ngx-translate/core";
import {MatSnackBar, MatSnackBarConfig} from "@angular/material/snack-bar";

import {DateAdapter} from '@angular/material/core';
import {MomentDateAdapter} from '@angular/material-moment-adapter';
import {Moment} from 'moment';


@Component({
  selector: 'ftb-root',
  template: '<router-outlet></router-outlet>',
  styleUrls: ['./app.component.scss']

})
export class FtbAppComponent implements OnInit {
  subscription: Subscription;
  config = new MatSnackBarConfig();

  constructor(private ws: FtWsService, private _swUpdate: SwUpdate, protected snackBar: MatSnackBar,
              private translateService: TranslateService, private appConfigService: AppConfigsService,
              adapter: DateAdapter<Moment>,
              momentAdapter: MomentDateAdapter
  ) {
    this.translateService.use(appConfigService.appLang);
    this.translateService.setDefaultLang(appConfigService.appLang);

    adapter.setLocale(appConfigService.appLang);
    momentAdapter.setLocale(appConfigService.appLang);


    this.subscription = this.ws.observe('hl-seven.handle_connection_failed').subscribe(data => {
        if (data) {
          setTimeout(() => this.snackBar.open(
            this.translateService.instant('shared.hl_seven_connection_failed'), null, {
              duration: 3000,
              horizontalPosition: 'left',
              panelClass: ['warning-snack-bar', 'ft-snackbar']
            }), 5000);
        }
      }
    );
  }

  ngOnInit() {
    if (this._swUpdate.isEnabled) {
      this._swUpdate.available.subscribe(() => {
        window.location.reload();
      });
    }
  }

}
