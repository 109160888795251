import {Component, Inject, OnInit} from '@angular/core';
import * as _ from 'lodash';
import {PROCEDURE_VIEW} from '../../../shared/utils/views-settings';
import {FEES_NOTES_VIEW, QUOTATIONS_VIEW} from '../../../invoicing/utils/views-settings';
import {BILLING_REQUEST_VIEW} from '../../../daily-workflow/utils/views-settings';
import * as moment from 'moment';
import {DefaultValuesService} from "@ft/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {handleTabs} from "../../utils/helpers";

@Component({
  selector: 'ftb-lines-select-dialog',
  templateUrl: './lines-select-dialog.component.html',
  styleUrls: ['./lines-select-dialog.component.scss']
})
export class LinesSelectDialogComponent implements OnInit {
  settings = null;
  cls = null;
  tabsConfig = [];

  tabSelections = {};
  tabIdx = 0;
  patientFilter = null;
  organizationFilter = null;

  constructor(public dialogRef: MatDialogRef<LinesSelectDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private defaultValuesService: DefaultValuesService) {
  }

  ngOnInit() {
    this.cls = _.get(this.data, 'cls');
    this.patientFilter = _.get(this.data, 'patient');
    this.organizationFilter = _.get(this.data, 'organization');
    let idx = _.findIndex(BILLING_REQUEST_VIEW.viewSettings.viewColumns, (e) => {
      return _.get(e, 'key') == 'debtor'
    });
    if (idx) {
      BILLING_REQUEST_VIEW.viewSettings.viewColumns[idx].style = (row) => {
        return {'background-color': this.defaultValuesService.getValue(`${_.get(row, 'debtor')}_color`)}
      }
    }
    this.tabsConfig = handleTabs(this.cls, this.data);
  }

  handleSelectionChange(data) {
    this.tabSelections[`${this.tabIdx}`] = data;
  }


  select() {
    this.dialogRef.close(_.map(this.tabsConfig, (item) => {
      return _.pick(item, 'name', 'selected');
    }));
  }

  onTabChange(ev) {
    this.tabIdx = ev.index;
  }

  isDisableTab(tab?) {
    return !['Certificate', 'Invoice'].includes(this.cls) && tab != 'procedures';
  }

  getDefaultIndex() {
    return this.cls == 'Invoice' ? 0 : 3;
  }

  handleRequestsQuery() {
    if (this.patientFilter && this.organizationFilter) {
      return [
        {key: 'patient', logical_operator: 'and', operator: '$eq', value: _.get(this.patientFilter, 'id')},
        {key: 'organization', logical_operator: 'and', operator: '$eq', value: _.get(this.organizationFilter, 'id')},
      ];
    } else if (this.patientFilter && !this.organizationFilter) {
      return [{key: 'patient', logical_operator: 'and', operator: '$eq', value: _.get(this.patientFilter, 'id')}];
    } else if (!this.patientFilter && this.organizationFilter) {
      return [{
        key: 'organization',
        logical_operator: 'and',
        operator: '$eq',
        value: _.get(this.organizationFilter, 'id')
      }];
    } else {
      return [];
    }
  }
}
