import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FtCoreModule, MaterialModule} from "@ft/core";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {TranslateModule} from "@ngx-translate/core";
import {FtbSharedModule} from "../shared/shared.module";
import {FtTableModule} from "@ft/table";
import {NgBusyModule} from "ng-busy";
import {PatientRoutingModule} from "./patient-routing.module";
import {MainComponent} from './components/main/main.component';
import {PatientDialogComponent} from './dialogs/patient-dialog/patient-dialog.component';
import {PatientService} from "./services/patient.service";


@NgModule({
  declarations: [MainComponent, PatientDialogComponent],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    TranslateModule.forChild(),
    FtbSharedModule,
    FtCoreModule,
    FtTableModule,
    NgBusyModule,
    PatientRoutingModule
  ],
  providers:[PatientService]
})
export class PatientModule {
}
