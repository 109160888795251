export const lang = {
  yes: 'Yes',
  no: 'No',
  billing: 'Billing',
  payment: 'Payment',
  states: 'Reports',
  title: 'Title',
  prefix: 'Prefix',
  increment_by: 'Increment by',
  start_by: 'Start by',
  next_value: 'Next value',
  filling: 'Filling',
  suffix: 'Suffix',
  daily_workflow: 'workflow',
  no_element_to_show: 'No element found',
  search: 'Search',
  settings: 'Settings',
  patients: 'Patients'
};
