<div fxFlex fxLayout="column">
  <div class="ft-parent-layout mat-elevation-z1" [class.ft-content-margin]="!settings?.isSubModule" fxFlex
       fxLayout="column">
    <mat-toolbar color="accent" *ngIf="!settings?.isSubModule">
      <div *ngIf="!settings?.isSubModule" class="ft-module-icon">
        <button type="button" mat-icon-button>
          <mat-icon fontSet="mdi" fontIcon="{{settings.headerIcon}}"></mat-icon>
        </button>
      </div>
      <h2 *ngIf="!settings?.isSubModule">{{ settings.headerLabel | translate }}</h2>
      <span fxFlex></span>
      <ftb-import-csv-button  *ngIf="settings?.resource=='procedure'" [importCallBack]="importCallBack"  [importTopic]="'procedure.import_from_csv'"></ftb-import-csv-button>
      <button type="button" *ngIf="settings?.formLabel" mat-button class="with-icon" (click)="addClick()">
        <mat-icon fontSet="mdi" fontIcon="mdi-plus"></mat-icon>
        <span>{{ settings?.formLabel | translate }}</span>
      </button>
    </mat-toolbar>
    <div fxFlex fxLayout="column" class="ft-content no-padding">
     <div fxLayout="row" fxLayoutAlign="center" *ngIf="viewSettings?.defaultFilters">
           <mat-button-toggle-group #group="matButtonToggleGroup"  aria-label="filter" class="custom-toggle-group" (change)="handleFilter($event)" >
              <mat-button-toggle  *ngFor="let f of defaultFilters"  [value]="f.query" [checked]="f.checked" >
                {{f.label|translate}}
              </mat-button-toggle>
            </mat-button-toggle-group>
      </div>
      <ft-generic-view-table [namespace]="viewSettings?.viewNamespace"
                             [actions]="viewSettings?.actions"
                             [viewSettings]="viewSettings?.viewSettings"
                             [hasPagination]="viewSettings?.hasPagination"
                             [selectable]="viewSettings?.selectable"
                             [query]="viewSettings?.query"
                             [reloadEvent]="reloadEvent"
                             [exportExcel]="true"
                             [InitialQuery]="viewSettings?.defaultFilters ? initialQuery :[]"
                             [hasFooter]="viewSettings?.hasFooter"
                            (viewQueryChange)="footerHandler($event)"

      >
      </ft-generic-view-table>
    </div>
  </div>
</div>

