<div class="ft-parent-layout mat-elevation-z1 ft-content-margin" fxFlex fxLayout="column">
  <mat-toolbar color="accent">
    <div class="ft-module-icon">
      <button type="button" mat-icon-button>
        <mat-icon fontSet="mdi" fontIcon="mdi-wrench-outline"></mat-icon>
      </button>
    </div>
    <h2>{{ 'settings.base_settings' | translate }}</h2>
  </mat-toolbar>
  <div fxFlex fxLayout="column" class="ft-content no-padding">

    <mat-tab-group fxFlex>

      <mat-tab fxFlex label="{{'settings.general'|translate}}">

        <form #form="ngForm" fxFlex fxLayout="column" class="ft-padding" fxLayoutGap="6px">

          <div fxLayout="row" fxLayoutGap="6px">
            <mat-form-field fxFlex="20">
              <mat-select [placeholder]="'settings.exercise_start' | translate" [compareWith]="compareCondition"
                          [(ngModel)]="exercise_start_month" ftDefaultValues="exercise_start_month"
                          [updateOnChange]="true" name="exercise_start">
                <mat-option *ngFor="let m of months; let idx=index" [value]="idx">
                  {{'shared.' + m|translate}}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field fxFlex="20">
              <input matInput [placeholder]="'settings.quotation_valid_duration'|translate" required
                     ftDefaultValues="quotation_valid_duration" [updateOnChange]="true"
                     [(ngModel)]="valid_duration"
                     type="number" name="valid_duration"/>
              <span matSuffix> {{'shared.days_suffix'|translate}}</span>
            </mat-form-field>
          </div>
          <div fxLayout="row" fxLayoutGap="6px">
            <mat-form-field fxFlex="20">
              <mat-select [placeholder]="'shared.payment_mode' | translate" [compareWith]="compareCondition"
                          ftDefaultValues="payment_mode" [updateOnChange]="true"
                          [(ngModel)]="payment_mode" name="mode">
                <mat-option *ngFor="let type of paymentModeTypes" [value]="type">
                  {{'shared.' + type |translate}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field fxFlex="20">
              <mat-select [placeholder]="'shared.payment_condition' | translate" [compareWith]="comparePaymentCondition"
                          ftDefaultValues="default_payment_condition" [updateOnChange]="true"
                          [(ngModel)]="payment_condition" name="payment_condition">
                <mat-option *ngFor="let condition of paymentConditions" [value]="condition">
                  {{'shared.' + condition.key|translate}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <hr class="ft-sep">
          <mat-slide-toggle [(ngModel)]="invoice_payment_from_docs"
                            ftDefaultValues="invoice_payment_from_docs" [updateOnChange]="true"
                            name="invoice_payment_from_docs">
            {{'settings.invoice_payment_from_docs'|translate}}
          </mat-slide-toggle>
          <hr class="ft-sep">
          <div fxLayout="row">
            <div fxFlex="nogrow" class="table-container state-table">
              <mat-table [dataSource]="dataSource">
                <ng-container matColumnDef="state">
                  <mat-header-cell *matHeaderCellDef> {{'shared.state'|translate}}</mat-header-cell>
                  <mat-cell *matCellDef="let item"> {{item.name}}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="color">
                  <mat-header-cell *matHeaderCellDef class="ft-icon-cell"
                                   style="min-width:170px">{{'shared.color'|translate}}</mat-header-cell>
                  <mat-cell *matCellDef="let item" class="ft-icon-cell" style="min-width:170px">
                    <button mat-icon-button type="button"
                            ftColorPicker name="{{item.name}}"
                            [(ngModel)]="item.color"
                            ftDefaultValues="{{item.name}}" [updateOnChange]="true"
                    >
                      <mat-icon [style.color]="item.color" fontSet="mdi" fontIcon="mdi-circle">
                      </mat-icon>
                    </button>
                    <span style="flex: 1 1 100%">{{item.color}}</span>
                  </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="['state', 'color']">
                </mat-header-row>
                <mat-row
                  *matRowDef="let row; columns: ['state', 'color']">
                </mat-row>

              </mat-table>
            </div>
          </div>
        </form>
      </mat-tab>
      <ng-template [ftAcl]="{resource:  'store-article', action: 'get', behavior: 'remove'}">
        <mat-tab fxFlex label="{{'shared.stock'|translate}}">
          <form #form="ngForm" fxFlex fxLayout="column" class="ft-padding">
            <fts-default-settings></fts-default-settings>
          </form>
        </mat-tab>
      </ng-template>
      <!--<mat-tab fxFlex label="{{'settings.system'|translate}}">-->
      <!--</mat-tab>-->
    </mat-tab-group>
  </div>
</div>







