import {Component, Input, OnInit} from '@angular/core';
import {SMALL_DIALOG} from '@ft/core';
import {AlertDialogComponent} from '../../../settings/dialogs/alert-dialog/alert-dialog.component';
import {MatDialog} from "@angular/material/dialog";
import {MatSnackBar} from "@angular/material/snack-bar";
import {TranslateService} from '@ngx-translate/core';
import {assign, isNil} from 'lodash';
import {Router} from '@angular/router';
import {DeleteDialogComponent} from '../../dialogs/delete-dialog/delete-dialog.component';

@Component({
    selector: 'ftb-delete-button',
    template: `
        <button type="button" mat-icon-button color="warn" mat-mini-fab (click)="handleRemove()">
            <mat-icon fontSet="mdi" fontIcon="mdi-delete"></mat-icon>
        </button>`,
    styleUrls: ['./delete-button.component.scss']
})
export class DeleteButtonComponent implements OnInit {
    @Input() model;
    @Input() deleteTopic;
    @Input() afterDelete ? = () => {
    };

    constructor(protected dialog: MatDialog, protected snackBar: MatSnackBar, private translateService: TranslateService, private router: Router) {
    }

    ngOnInit() {
    }

    handleRemove() {
        this.dialog.open(DeleteDialogComponent, assign(SMALL_DIALOG, {
            data: {obj: this.model, message_text: 'settings.delete_object_warning', deleteTopic: this.deleteTopic}
        })).afterClosed().subscribe(res => {
            if (!isNil(res)) {
                if (res) {
                    this.snackBar.open(this.translateService.instant('settings.delete_object_success'), null, {
                        duration: 1500,
                        horizontalPosition: 'left',
                    });
                    this.afterDelete();
                } else if (res == false) {
                    this.dialog.open(AlertDialogComponent, assign(SMALL_DIALOG, {data: {message_text: 'settings.delete_object_failed'}}));
                }
            }
        });
    }
}
