<div fxFlex fxLayout="column">
  <div class="ft-parent-layout mat-elevation-z1 ft-content-margin" fxFlex fxLayout="column">
    <mat-toolbar color="accent">
      <div   class="ft-module-icon">
        <button type="button" type="button" mat-icon-button >
          <mat-icon fontSet="mdi" fontIcon="mdi-folder-multiple-outline"></mat-icon>
        </button>
      </div>
      <h2>{{'pec.pec_header_number'|translate}}:{{pec.number}}</h2>
      <span fxFlex></span>
      <button type="button" mat-button class="with-icon" [routerLink]="[routes['list']]">
        <mat-icon fontSet="mdi" fontIcon="mdi-format-list-bulleted"></mat-icon>
        <span>{{'pec.pec_list'|translate}}</span>
      </button>
    </mat-toolbar>
    <div fxFlex fxFill fxLayout="column" class="ft-content no-padding">
      <div [ngBusy]="loading"></div>

      <form novalidate #pecForm="ngForm" [ngBusy]="loading" fxLayout="column" fxFlex   fxLayoutGap="6px">
        <div class="ft-padding" fxLayout="row"  fxLayoutGap="6px" >
          <div *ngIf="pec.state?.is_valid" fxLayoutAlign="center center" class="header-validate-icon">
            <img mat-card-image src="/assets/lock-outline.svg" alt="validation img">
          </div>
          <div fxFlex fxLayout="column">
            <div fxLayout="row" fxLayoutGap="6px">
              <mat-form-field>
                <input matInput type="text" required [ftSequenceInput]="seqQuery.cls" [ftSequenceToggle]="toggle" [readonly]="pec?.state?.is_valid"
                       name="sequence"
                       placeholder="{{'pec.pec_number'|translate}}" [(ngModel)]="pec.number"/>
                <ft-sequence-toggle [disabled]="pec.id" matSuffix #toggle [(objectId)]="loadedObject"
                                    [config]="{cls: seqQuery.cls, field: 'number'}">
                </ft-sequence-toggle>
              </mat-form-field>
              <mat-form-field fxFlex="nogrow" >
                <input matInput ftDate [matDatepicker]="inv_date" [placeholder]="'shared.date'|translate" [readonly]="pec?.state?.is_valid"
                       [(ngModel)]="pec.doc_date" name="doc_date">
                <mat-datepicker-toggle matSuffix [for]="inv_date">
                  <mat-icon matDatepickerToggleIcon fontSet="mdi" fontIcon="mdi-calendar-today"></mat-icon>
                </mat-datepicker-toggle>
                <mat-datepicker #inv_date></mat-datepicker>
              </mat-form-field>
              <mat-form-field fxFlex="nogrow" >
                <input matInput ftDate [matDatepicker]="send_date" [placeholder]="'pec.send_date'|translate" [readonly]="pec?.state?.is_valid"
                       [(ngModel)]="pec.send_date" name="send_date">
                <mat-datepicker-toggle matSuffix [for]="send_date">
                  <mat-icon matDatepickerToggleIcon fontSet="mdi" fontIcon="mdi-calendar-today"></mat-icon>
                </mat-datepicker-toggle>
                <mat-datepicker #send_date></mat-datepicker>
              </mat-form-field>
            </div>
            <div fxLayout="row" fxLayoutGap="6px">
              <ftb-select-input  fxFlex="50" [label]="'shared.patient'" [(model)]="pec.patient" value="full_name" [disabled]="pec?.state?.is_valid"
                                [settings]="patientInputSettings" [dialog]="patientDialog"
                                (modelChange)="onPatientChange($event)"></ftb-select-input>
            </div>
            <div fxLayout="row" fxLayoutGap="6px">
              <ftb-select-input fxFlex="50" [label]="'shared.insurance'" [(model)]="pec.organization" value="name" [disabled]="pec?.state?.is_valid"
                                [settings]="organizationInputSettings"
                                (modelChange)="onOrganizationChange($event)"></ftb-select-input>
            </div>
          </div>
          <mat-divider [vertical]="true"></mat-divider>
          <div fxFlex fxLayout="column">
            <div fxLayout="row"   fxLayoutGap="6px">
              <mat-form-field floatLabel="always" class="ft-radio-field">
                <input matInput placeholder="{{'shared.insured_type'|translate}}" style="display: none">
                <mat-radio-group [(ngModel)]="pec.insured_details.insured_type" name="p_type" fxLayout="row" [disabled]="pec?.state?.is_valid"
                                 (change)="onInsuredChange($event)">
                  <mat-radio-button *ngFor="let b_type of ['PATIENT','PARTNER','CHILD','PARENT']" [value]="b_type">
                    {{b_type=='PATIENT' ? ('pec.same_patient'|translate) : ('pec.'+b_type|translate)}}
                  </mat-radio-button>
                </mat-radio-group>
              </mat-form-field>
            </div>
            <div fxLayout="row"   fxLayoutGap="6px">
              <ftb-select-list  fxFlex label="shared.title" [disabled]="pec?.state?.is_valid"
                               [model]="pec.insured_details.title"
                               [cls]="'Title'" (modelChange)="listChange($event,'Title')"></ftb-select-list>
              <mat-form-field fxFlex>
                <input matInput type="text"   name="first_name" placeholder="{{'pec.first_name'|translate}}" required [readonly]="pec?.state?.is_valid"
                       [(ngModel)]="pec.insured_details.first_name"/>
              </mat-form-field>
              <mat-form-field fxFlex>
                <input matInput type="text" name="last_name" placeholder="{{'pec.last_name'|translate}}" required [readonly]="pec?.state?.is_valid"
                       [(ngModel)]="pec.insured_details.last_name"/>
              </mat-form-field>
            </div>
            <div fxLayout="row"   fxLayoutGap="6px">
              <!--<mat-form-field fxFlex>-->
                <!--<input matInput type="text" required name="affiliate_number"-->
                       <!--placeholder="{{'pec.affiliate_number'|translate}}"-->
                       <!--[(ngModel)]="pec.insured_details.affiliate_number"/>-->
              <!--</mat-form-field>-->
              <mat-form-field fxFlex >
                <input matInput ftDate [matDatepicker]="b_date" [placeholder]="'shared.birth_date'|translate" [readonly]="pec?.state?.is_valid"
                       [(ngModel)]="pec.insured_details.birth_date" name="b_date">
                <mat-datepicker-toggle matSuffix [for]="b_date">
                  <mat-icon matDatepickerToggleIcon fontSet="mdi" fontIcon="mdi-calendar-today"></mat-icon>
                </mat-datepicker-toggle>
                <mat-datepicker #b_date></mat-datepicker>
              </mat-form-field>
              <mat-form-field fxFlex>
                <input matInput type="text" required name="national_id" placeholder="{{'pec.national_id'|translate}}" [readonly]="pec?.state?.is_valid"
                       [(ngModel)]="pec.insured_details.national_id"/>
              </mat-form-field>
              <mat-form-field fxFlex>
                <input matInput type="text" required name="imatr_number" [readonly]="pec?.state?.is_valid"
                       placeholder="{{'pec.imatr_number'|translate}} / {{'pec.affiliate_number'|translate}}"
                       [(ngModel)]="pec.insured_details.imatr_number"/>
              </mat-form-field>
            </div>
            <div   fxLayout="row">
              <mat-form-field fxFlex>
          <textarea matInput [(ngModel)]="pec.insured_details.address" name="address" [readonly]="pec?.state?.is_valid"
                    [placeholder]="'shared.address'|translate"></textarea>
              </mat-form-field>
            </div>
          </div>
        </div>
          <mat-tab-group fxFlex>
            <mat-tab fxFlex label="{{'pec.demande_detail'|translate}}">
              <ftb-document-lines fxFlex [(lines)]="pec.details"
                                         [cls]="'pec'"
                                         [model]="pec"
                                         [readOnly]="pec?.state?.is_valid"
                                         [hasSearch]="!pec?.state?.is_valid"
                                         [showDetailedAmounts]="false">

              </ftb-document-lines>
            </mat-tab>
            <mat-tab fxFlex label="{{'pec.answers'|translate}}">
              <ftb-response-lines fxFlex [lines]="pec.answers" [model]="pec" [cls]="'pec'"  [hasSearch]="!pec?.state?.is_valid"></ftb-response-lines>
            </mat-tab>
          </mat-tab-group>
      </form>
    </div>
    <div fxLayout="row">
        <ftb-document-actions fxFlex [model]="pec" [cls]="cls" (modelChange)="onModelChange($event)" [loadedObject]="loadedObject"
                                      [formInvalid]="pecForm.invalid || (!pec.patient || !pec.organization)"
                                      [routes]="routes" [(busy)]="loading"></ftb-document-actions>
        <div *ngIf="pec?.state?.is_valid" class="ft-actions">
           <button type="button" mat-raised-button  class="has-icon success-button" (click)="addResponseDialog()">
              <mat-icon fontSet="mdi" fontIcon="mdi-plus"></mat-icon>
              <span>{{'pec.answers'|translate}}</span>
            </button>
        </div>
    </div>
  </div>
</div>
