import {getLineAmount} from './functions';
import * as _ from 'lodash';
import {TableColumnDefinition} from '@ft/table';

export const LINES_COLUMNS: TableColumnDefinition[] = [
  {
    'key': 'code',
    'label': 'shared.code',
    'type': 'text',
    'class': 'simple-text'
  },
  {
    'key': 'description',
    'label': 'shared.description',
    'type': 'text',
    'editable': (row) => {
      return _.get(row, 'details') ? false : true;
    },
    'extended': true,
    style: (row) => {
      return _.get(row, 'details') ? {'font-weight': '700', 'font-size': "14px"} : {};
    }
  },
  {
    'key': 'procedure.NGAP_code',
    'label': 'settings.ngap_code',
    'type': 'text',
    'editable': false
  },
  {
    'key': 'qte',
    'label': 'shared.qte',
    'type': 'number',
    'editable': true,
    class: 'simple-number'
  },
  {
    'key': 'price',
    'label': 'shared.price',
    'type': 'number',
    'editable': true,
    'numberConfig': 'float'
  },
  {
    'key': 'discount',
    'label': 'shared.discount',
    'type': 'number',
    // 'numberConfig': 'float',
    'editable': true,
    class: 'three-numbers',
    max: 100
  },
  {
    'key': 'tariff.tm',
    'label': 'shared.tm_percent',
    'type': 'number',
    'numberConfig': 'float',
    'editable': true,
    'max': (row) => {
      return 100;// _.get(row, 'tariff') ? 100 - _.get(row, 'tariff.tp', 0) due to ftNumber
    },
    'min': 0,
    class: 'three-numbers'
  },
  {
    'key': 'tariff.tp',
    'label': 'shared.tp_percent',
    'type': 'number',
    'numberConfig': 'float',
    'editable': true,
    'max': (row) => {
      return 100;//_.get(row, 'tariff') ? 100 - _.get(row, 'tariff.tm', 0) : due to ftNumber
    },
    'min': 0,
    class: 'three-numbers'
  },
  {
    'key': 'tm_amount',
    'label': 'shared.tm_amount',
    'type': 'number',
    'numberConfig': 'float',
    'callBack': (row) => {
      return _.get(row, 'tariff.tm') ? getLineAmount(row) - ((100 - (_.get(row, 'tariff.tm', 0))) * getLineAmount(row)) / 100 : 0;
    }
  },
  {
    'key': 'tp_amount',
    'label': 'shared.tp_amount',
    'type': 'number',
    'numberConfig': 'float',
    'callBack': (row) => {
      return _.get(row, 'tariff.tp') ? getLineAmount(row) - ((100 - row.tariff.tp) * getLineAmount(row)) / 100 : 0;
    }
  },
  {
    'key': 'sub_amount',
    'label': 'shared.sub_amount',
    'type': 'number',
    'numberConfig': 'float',
    'callBack': (row) => {
      return getLineAmount(row);
    }
  },
];
export const CERTIFICATE_LINES_COLUMNS: TableColumnDefinition[] = [
  {
    'key': 'doc_date',
    'label': 'shared.date',
    'type': 'date'
  },
  {
    'key': 'doc_id',
    'label': 'payment.external_doc_id',
    'type': 'text'
  },
  {
    'key': 'tm_amount',
    'label': 'shared.tm_amount',
    'type': 'number',
    'numberConfig': 'float'
  },
  {
    'key': 'tp_amount',
    'label': 'shared.tp_amount',
    'type': 'number',
    'numberConfig': 'float'
  },
  {
    'key': 'taxed_amount',
    'label': 'shared.total',
    'type': 'number',
    'numberConfig': 'float'
  },
];
