import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {AuthGuard, BaseLoginComponent,  LoggedGuard} from '@ft/core';
import {FtbMainComponent} from './core/components/main/main.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: '/workflow/billing-requests' ,
  },
  {
    path: '',
    canActivate: [AuthGuard],
    component: FtbMainComponent,
    children: [
      {
        path: 'core',
        loadChildren: () => import('./core/core-routing.module').then(m => m.FtbCoreRoutingModule)
      },
      {
        path: 'settings',
        loadChildren: () => import('./settings/settings-routing.module').then(m => m.FtbSettingsRoutingModule)
      },
      {
        path: 'invoicing',
        loadChildren: () => import('./invoicing/invoicing-routing.module').then(m => m.FtbInvoicingRoutingModule)
      },
      {
        path: 'payment',
        loadChildren: () => import('./payment/payment-routing.module').then(m => m.FtbPaymentRoutingModule)
      },
      {
        path: 'pec',
        loadChildren: () => import('./pec/pec-routing.module').then(m => m.FtbPecRoutingModule)
      },
      {
        path: 'workflow',
        loadChildren: () => import('./daily-workflow/daily-workflow-routing.module').then(m => m.FtbDailyWorkflowRoutingModule)
      },
      {
        path: 'states',
        loadChildren: () => import('./states/states-routing.module').then(m => m.FtbStatesRoutingModule)
      },
      {
        path: 'patient',
        loadChildren: () => import('./patient/patient-routing.module').then(m => m.PatientRoutingModule)
      },
      {
        path: 'stock',
        loadChildren: () => import('./lazy-routing.module').then(m => m.FtsLazyRoutingModule)
      }
    ]
  },
  {path: 'login', component: BaseLoginComponent, canActivate: [LoggedGuard], data: {logo: '/assets/logo.png'}}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class FtbAppRoutingModule {
}
