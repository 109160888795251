import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {BillingService} from '../../../shared/services/billing.service';
import {Subscription} from 'rxjs';
import {
    assign,
    assignIn,
    chain,
    cloneDeep,
    compact,
    concat,
    find,
    findIndex,
    forEach,
    get,
    isNil,
    map,
    pick,
    reduce,
    sumBy,
    update
} from 'lodash';
import * as moment from 'moment';
import {PaymentService} from '../../services/payment.service';
import {NgForm} from '@angular/forms';
import {RouterDataService} from '../../../shared/services/router-data.service';
import {PATIENTS_VIEW} from '../../../patient/utils/views-settings';
import {ORGANIZATION_VIEW} from '../../../settings/utils/views-settings';
import {PatientDialogComponent} from "../../../patient/dialogs/patient-dialog/patient-dialog.component";

@Component({
    selector: 'ftb-payment-form',
    templateUrl: './payment-form.component.html',
    styleUrls: ['./payment-form.component.scss']
})
export class PaymentFormComponent implements OnInit {

    payment: any = {
        number: null,
        doc_date: moment(),
        deadline: moment(),
        beneficiary_type: 'patient',
        lines: [],
        payer_type: 'patient',
        other_payer: {},
        payer: null,
        payment_mode: {},
        received_amount: 0,
        consumed_amount: 0,
        remaining_amount: 0
    };
    cls = 'payment';
    loading: Subscription;
    routes = {list: '/payment/payments/', form: '/payment/payments/payment-form/'};


    patientInputSettings = assign({}, PATIENTS_VIEW, {selectable: false});
    organizationInputSettings = assign({}, ORGANIZATION_VIEW, {selectable: false});
    patientDialog = PatientDialogComponent;


    seqQuery = {
        cls: 'payment.Payment',
        label: 'payment.payment_number',
        fieldName: 'number',
        reloadDeleted: true,
    };
    loadedObject = null;
    paymentLines = [];

    // paymentForm : '';
    @ViewChild('paymentForm', {static: true}) public paymentForm: NgForm;

    paymentModeTypes = [];

    constructor(private route: ActivatedRoute, private  billingService: BillingService, private paymentService: PaymentService, private  routerDataService: RouterDataService) {
    }

    ngOnInit() {
        this.paymentService.getDefaultValue('PAYMENT_MODE_TYPE').subscribe(data => this.paymentModeTypes = data);
        this.route.params.subscribe(params => this.initData(get(params, 'id')));
    }

    initData(_id) {
        if (_id) {
            this.loading = this.billingService.getBillingDoc(this.cls, _id)
                .subscribe(data => {
                    this.payment = data;
                    this.loadDocToPay();
                    this.disableHeader();
                });
        }
        let paymentData = get(this.routerDataService.routerData, 'paymentData');
        if (paymentData) {
            this.payment = assign({}, this.payment, pick(paymentData, ['patient', 'organization', 'beneficiary_type', 'payer', 'payer_type', 'received_amount']));
            this.paymentLines = chain(paymentData).get('paymentLines').cloneDeep().value();
        }
    }

    disableHeader() {
        if (get(this.payment, 'id')) {
            this.paymentForm.form.disable();
        }
    }

    enableHeader() {
        this.paymentForm.form.enable();
        this.dissociateLines();
    }

    dissociateLines() {
        forEach(this.payment.lines, function (row) {
            this.dissociateOneLine(row);
        }.bind(this));

        this.payment.lines = [];
        this.payment.consumed_amount = 0;
    }

    dissociateOneLine(row) {
        let paid_idx = findIndex(this.paymentLines, function (item) {
            if (item['paid_doc'].id == row.paid_doc.id && item['paid_doc']._module == row.paid_doc._module && item['paid_doc']._model == row.paid_doc._model) return item;
        });
        if (paid_idx != -1) {
            this.paymentLines[paid_idx].remaining_amount = this.paymentLines[paid_idx].remaining_amount + row.encasement_amount;
            this.paymentLines[paid_idx].encasement_amount = 0;
            this.paymentLines = cloneDeep(this.paymentLines);
        } else {
            this.paymentLines = concat(this.paymentLines, assign({}, row, {
                remaining_amount: row.encasement_amount,
                encasement_amount: 0
            }));
        }
    }

    onModelChange(data) {
        if (typeof data === 'string') {
            this.executeCommand(data);
        } else {
            this.disableHeader();
            this.payment = data;
            this.loadDocToPay();
        }
    }

    executeCommand(cmd) {
        switch (cmd) {
            case 'headerInvalidate':
                return this.enableHeader();
            case 'dissociateLines':
                return this.dissociateLines();
            case 'shareReceivedAmount':
                return this.shareReceivedAmount();
        }
    }

    handleAction(action) {
        switch (action.action) {
            case 'encasementDbClick':
                return this.encasementDbClick(action.data);
        }
    }

    handlePatientChange(data) {
        this.payment.patient = data;
        this.onPayerChange(null);

        this.loadDocToPay();
    }

    handleOrganizationChange(data) {
        this.payment.organization = data;
        this.onPayerChange(null);
        this.loadDocToPay();
    }

    handlePayerChange(data) {
        this.payment.payer = data;
        this.handlePayer();
    }

    onBeneficiaryChange(ev) {
        this.onPayerChange(null);
    }

    handlePayer() {
        switch (this.payment.payer_type) {
            case 'patient':
                assignIn(this.payment.payer, {
                    _module: 'shared.models',
                    _model: 'FtPatient',
                    _pkg: 'shared'
                });
                break;
            case 'organization':
                assignIn(this.payment.payer, {
                    _module: 'shared.insurance.models',
                    _model: 'Organization',
                    _pkg: 'shared'
                });
                break;
            case 'other':
                assignIn(this.payment.payer, {
                    type: 'unregistered'
                });
                break;
        }
    }

    onPayerChange(ev) {
        if (this.payment.payer_type === this.payment.beneficiary_type) {
            this.payment.payer = this.payment.beneficiary;
            this.handlePayer();
        } else {
            this.payment.payer = null;
        }
    }

    onSequenceChange(data) {
        if (typeof data === 'string') {
            this.payment.number = data;
        } else {
            this.payment.number = data[this.seqQuery.fieldName];
            this.payment.id = data['id'];
        }
    }


    loadDocToPay() {
        this.paymentLines = [];
        if (this.payment.patient || this.payment.organization) {
            this.paymentService.getUnpaidDocuments({
                beneficiary_type: this.payment.beneficiary_type,
                beneficiary_id: this.payment.beneficiary_type == 'patient' ? get(this.payment, 'patient.id') : get(this.payment, 'organization.id')
            }).subscribe(data => {

                let res = compact(concat(map(data['invoices'], (invoice) => {
                        return this.refreshPaymentLines(invoice, 'invoicing.models', 'Invoice');
                    }),
                    map(data['billingRequests'], (item) => {
                        return this.refreshPaymentLines(item, 'request_flow.models', 'BillingRequest');
                    }),
                    map(data['careRequests'], (item) => {
                        return this.refreshPaymentLines(item, 'pec.models', 'CareRequest');
                    })));
                this.paymentLines = res;
            });
        }

    }

    refreshPaymentLines(doc, _pkg, _model) {
        if (!doc['is_paid']) {
            if (isNil(find(this.paymentLines, function (item) {
                    if (item.paid_doc.number == doc.number && item.paid_doc._module == _pkg && item.paid_doc._model == _model) return item;
                }
            ))) {
                return {
                    total_amount: doc.taxed_amount,
                    paid_doc: assignIn(doc, {
                        _module: _pkg,
                        _model: _model,
                        _pkg: _pkg
                    }, _model === 'BillingRequest' ? {number: `${doc.doc_id} - ${doc.doc_source}`} : {}),
                    encasement_amount: 0.0,
                    remaining_amount: doc.remaining_amount
                };
            }
        }
    }

    listChange(data, p) {
        this.payment.payment_mode[p] = data;
    }

    handleReceivedAmountChange() {
        if (this.payment.received_amount == 0 || sumBy(this.paymentLines, 'encasement_amount') > this.payment.received_amount) {
            this.resetEncasementAmount(this.paymentLines);
        }
    }

    resetEncasementAmount(lines) {
        forEach(lines, function (line) {
            line.remaining_amount += parseFloat(line.encasement_amount);
            line.encasement_amount = 0;
        });
    }

    shareReceivedAmount() {
        this.resetEncasementAmount(this.paymentLines);
        this.paymentLines = map(this.paymentLines, (line) => {
            this.encasementDbClick(line);
            return line;
        });
    }


    getRealTotal() {
        let total = reduce(this.payment.lines, getLineTotal, 0);
        return isNil(total) ? 0 : total;

        function getLineTotal(result, value) {
            return result + get(value, 'encasement_amount', 0);
        }
    }


    getTotal() {
        let total = reduce(this.paymentLines, getTotal, 0);
        return isNaN(total) ? 0 : total;

        function getTotal(result, value) {
            return result + value['encasement_amount'];
        }
    }

    paymentRemainingAmount() {
        let model_amount = this.payment.received_amount - this.getRealTotal();
        return model_amount - this.getTotal() > 0 ? model_amount - this.getTotal() : 0;
    }

    getCurrentRemainingAmount() {
        let model_amount = this.payment.received_amount - this.getRealTotal();
        return model_amount - this.getTotal();
    }


    handlePaymentLine(row) {
        let paid_idx = findIndex(this.payment.lines, function (item) {
            if (item['paid_doc'].id == row.paid_doc.id && item['paid_doc']._module == row.paid_doc._module && item['paid_doc']._model == row.paid_doc._model) return item;
        });

        if (paid_idx == -1) {
            this.payment.lines = concat(this.payment.lines, row);
        } else {
            update(this.payment.lines, `[${paid_idx}]`, function (line) {
                return assignIn(line, {
                    encasement_amount: line.encasement_amount + row.encasement_amount,
                    remaining_amount: line.remaining_amount - row.encasement_amount
                });
            });
        }


    }

    encasementDbClick(row) {
        if (row.encasement_amount > 0) return;
        let amount = this.paymentRemainingAmount();
        if (amount > 0) {
            if (amount - row.remaining_amount > 0) {
                row.encasement_amount = row.remaining_amount;
            } else {
                row.encasement_amount = amount;
            }
            row.remaining_amount = row.remaining_amount - row.encasement_amount;
        }
    }

    onPreLinesChange(data) {
        this.paymentLines = data;
    }

    onPaymentLinesChange(data) {
        this.payment.lines = data;
    }

    compareMode(o1, o2) {
        return o1 == o2;
    }

    handleModeChange(ev) {

    }

}
