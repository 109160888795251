<div fxFlex fxLayout="column" class="ft-content ft-padding">
  <div [ngBusy]="loading"></div>
  <div *ngIf="certificate.state?.is_valid" fxLayoutAlign="center center" class="validate-icon">
    <img mat-card-image src="/assets/lock-outline.svg" alt="validation img">
  </div>
  <form novalidate #certificateForm="ngForm" [ngBusy]="loading" fxLayout="column" fxFlex fxLayoutGap="6px">
    <div fxLayout="row" fxLayoutGap="6px">
      <div fxFlex fxLayout="column">
        <div fxLayout="row" fxLayoutGap="6px">
          <mat-form-field>
            <input matInput type="text" required [ftSequenceInput]="'invoicing.Certificate'" [ftSequenceToggle]="toggle"
                   name="sequence"
                   placeholder="{{'invoicing.certificate_number'|translate}}" [(ngModel)]="certificate.number" />
            <ft-sequence-toggle [disabled]="certificate.id"  matSuffix #toggle [(objectId)]="loadedObject"
                                [config]="{cls: 'invoicing.Certificate', field: 'number'}">
            </ft-sequence-toggle>
          </mat-form-field>
          <mat-form-field>
            <input matInput ftDate [matDatepicker]="inv_date" [placeholder]="'shared.date'|translate"
                   [(ngModel)]="certificate.doc_date" name="doc_date">
            <mat-datepicker-toggle matSuffix [for]="inv_date">
              <mat-icon matDatepickerToggleIcon fontSet="mdi" fontIcon="mdi-calendar-today"></mat-icon>
            </mat-datepicker-toggle>
            <mat-datepicker #inv_date></mat-datepicker>
          </mat-form-field>
        </div>
      </div>
      <mat-divider [vertical]="true"></mat-divider>
      <div fxFlex fxLayout="row" fxLayoutGap="6px">
        <div fxLayout="row" fxFlex fxLayoutGap="6px">
          <ftb-select-input fxFlex [label]="'shared.patient'" [(model)]="certificate.patient" value="full_name"
                            [settings]="patientInputSettings" [dialog]="patientDialog"
                            (modelChange)="onPatientChange($event)"></ftb-select-input>
        </div>
        <div fxLayout="row" fxFlex fxLayoutGap="6px">
          <ftb-select-input fxFlex [label]="'shared.insurance'" [(model)]="certificate.insurance" value="name"
                            [settings]="organizationInputSettings"
                            (modelChange)="onOrganizationChange($event)"></ftb-select-input>
        </div>
      </div>
    </div>
    <div fxFlex fxLayout="column">
      <ftb-document-lines fxFlex [(lines)]="certificate.lines"
                          [cls]="cls"
                          [model]="certificate"
                          [linesSelectQuery]="handleLinesQuery()"
                          [showAmounts]="false"></ftb-document-lines>
        <div fxLayout="row">
          <span fxFlex></span>
        <div class="mn-amount ft-padding">
          <div class="amounts" fxLayoutGap="8px">
            <div>
              <span>{{'shared.amount'|translate}}</span>
              <span>{{'shared.organization_part'|translate}}</span>
              <span>{{'shared.patient_part'|translate}}</span>
            </div>
            <div>
              <span>:</span>
              <span>:</span>
              <span>:</span>
            </div>
            <div>
              <span>{{getTotal() | ftNumber: "currency"}}</span>
              <span>{{getTpAmount() | ftNumber: "currency"}}</span>
              <span>{{getTmAmount() | ftNumber: "currency"}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
<ftb-document-actions fxFlex="nogrow" [model]="certificate" [cls]="cls" (modelChange)="onModelChange($event)"
                      [formInvalid]="certificateForm.invalid || (!certificate.patient && !certificate.insurance)"
                      [routes]="routes" [(busy)]="loading" [loadedObject]="loadedObject" ></ftb-document-actions>
