import {Component, OnInit} from '@angular/core';
import {LARGE_DIALOG, SCALE_ANIMATION} from "@ft/core";
import {TableColumnDefinition} from "@ft/table";
import {BehaviorSubject, Observable, of, Subscription} from "rxjs";
import * as _ from "lodash";
import {TranslateService} from "@ngx-translate/core";
import {SelectDocumentsDialogComponent} from "../../../shared/dialogs/select-documents-dialog/select-documents-dialog.component";
import {PROCEDURE_VIEW} from "../../../shared/utils/views-settings";
import {BillingService} from "../../../shared/services/billing.service";
import {MatDialog} from "@angular/material/dialog";
import {ActivatedRoute, Router} from "@angular/router";


@Component({
  selector: 'ftb-pricing',
  templateUrl: './pricing.component.html',
  animations: [
    SCALE_ANIMATION
  ],
  styleUrls: ['./pricing.component.scss']
})
export class PricingComponent implements OnInit {
  busy: Subscription;

  tariff: any = {lines: []};
  tariffs: any = [];
  currentTariff = null;
  linesSource$: Observable<any[]> = of([]);
  linesActions = [
    {
      class: 'mat-primary',
      icon: 'mdi-plus',
      isMultipleSelection: false,
      method: (item, ev) => {
        return this.addLine();
      },
      tooltip: this.translateService.instant('shared.add_line')
    }
  ];

  linesColumns = new BehaviorSubject<TableColumnDefinition[]>([

    {
      'label': 'settings.code',
      'key': 'procedure.code',
      'type': 'text'
    },
    {
      'label': 'shared.name',
      'key': 'procedure.name',
      'type': 'text'
    },
    {
      'label': 'settings.procedure_price',
      'key': 'value',
      'type': 'number',
      editable: true,
      numberConfig: 'float'
    },
    {
      label: '', key: 'actions', type: 'actions', actions: [
        {
          class: 'mat-warn',
          icon: 'mdi-close-circle',
          method: (item, index, ev) => {
            return this.removeLine(item, index);
          }
        }
      ]
    }
  ]);
  importCallBack = () => {
    return this.initData();
  };

  constructor(private translateService: TranslateService, protected dialog: MatDialog, private billingService: BillingService, private router: Router) {
  }

  ngOnInit() {
    this.initData();
  }

  initData() {
    this.busy = this.billingService.getBillingDocs('pricing')
      .subscribe(data => {
        if (data) {
          this.tariffs = data;
          this.tariff = !_.has(this.tariff, 'id') ? _.get(this.tariffs, '0', this.tariff) : this.tariff;
          this.linesSource$ = of(this.tariff.lines);
        }
      });

  }

  handleTariff() {
    // this.router.navigate(['/settings/pricing-list' ], {replaceUrl: false});
    this.tariff = {lines: []};
    this.linesSource$ = of(this.tariff.lines);

  }

  selectTariff(t) {
    this.tariff = t;
    this.linesSource$ = of(this.tariff.lines);
  }

  initTariffs = () => {
    this.tariff = {lines: []};
    this.linesSource$ = of(this.tariff.lines);
    return this.initData();
    // this.router.navigate(['/settings/pricing-list' ], {replaceUrl: false});
  }

  save() {
    this.busy = this.billingService.saveBillingDoc(this.tariff, 'pricing').subscribe(data => {
      this.tariff = data;
      this.initData();
      // this.router.navigate(['/settings/pricing-list', this.tariff['id']], {replaceUrl: false});
    });

  }

  removeLine(item, idx) {
    this.tariff.lines.splice(idx, 1);
    this.linesSource$ = of(this.tariff.lines);
  }

  addLine() {
    this.dialog.open(SelectDocumentsDialogComponent, _.assign(LARGE_DIALOG, {
      data: PROCEDURE_VIEW
    })).afterClosed().subscribe(res => {
      if (res) {
        res = _.map(res, (e) => {
          return {procedure: e, value: e.price}
        });
        this.tariff.lines = _.uniqBy(_.concat(this.tariff.lines, res), 'procedure.code');
        this.linesSource$ = of(this.tariff.lines);

      }
    });
  }
}
