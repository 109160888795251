<mat-toolbar color="primary">
  <div class="ft-module-icon">
    <mat-icon fontSet="mdi" fontIcon="mdi-playlist-plus"></mat-icon>
  </div>

  <h2 mat-dialog-title>{{ 'pec.answers'| translate }}</h2>
  <span fxFlex></span>

  <button type="button" mat-icon-button mat-dialog-close tabindex="-1">
    <mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
  </button>
</mat-toolbar>

<mat-dialog-content>
  <form #responseForm="ngForm" fxLayout="column" fxFlex fxFill>
    <div fxLayout="row" fxLayoutGap="6px">
        <mat-form-field fxFlex="nogrow">
          <input matInput ftDate [matDatepicker]="b_date" [placeholder]="'shared.date'|translate"
                 [(ngModel)]="response.date" name="b_date">
          <mat-datepicker-toggle matSuffix [for]="b_date">
            <mat-icon matDatepickerToggleIcon fontSet="mdi" fontIcon="mdi-calendar-today"></mat-icon>
          </mat-datepicker-toggle>
          <mat-datepicker #b_date></mat-datepicker>
        </mat-form-field>
       <mat-form-field fxFlex="">
         <mat-select [placeholder]="'pec.answer_type'|translate" [(ngModel)]="response.type" required
                     [ngModelOptions]="{standalone:true}" [compareWith]="compareObj">
           <mat-option *ngFor="let e of answers" [value]="e">{{'pec.'+e |translate}}</mat-option>
         </mat-select>
       </mat-form-field>
    </div>
    <mat-form-field fxFlex *ngIf="response?.type=='rejection'">
      <input matInput [(ngModel)]="response.reject_reason" name="reject_reason"
             [placeholder]="'pec.reject_reason'|translate">
    </mat-form-field>
  <div fxLayout="row" fxLayoutGap="6px" *ngIf="response?.type!='rejection'">
      <mat-form-field fxFlex>
        <input matInput [(ngModel)]="response.ref" name="ref"
               [placeholder]="'pec.ref'|translate">
      </mat-form-field>
      <mat-form-field fxFlex>
        <input matInput [(ngModel)]="response.confirmed_amount" name="confirmed_amount"  [ftNumber]="'float'"
               [placeholder]="'pec.confirmed_amount'|translate">
      </mat-form-field>
    </div>
    <mat-form-field fxFlex>
      <input matInput [(ngModel)]="response.description" name="description"
             [placeholder]="'shared.comment'|translate">
    </mat-form-field>

    <div fxLayout="column" *ngIf="response?.type=='final_agreement_received'">
      <hr class="ft-sep">
      <ftb-document-lines fxFlex [(lines)]="lines"
                         [cls]="'pec'"
                         [hasSearch]="false"
                         [showDetailedAmounts]="false"
                         [defaultColumns]="response_details_columns"
                         [showAmounts]="false"></ftb-document-lines>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button type="button" color="primary" mat-raised-button tabindex="2" (click)="save()" [disabled]="responseForm.invalid || !response?.type || (response?.type=='final_agreement_received' && (!response?.ref || !response?.confirmed_amount) )">
    {{'shared.submit_add'|translate}}
  </button>
  <button type="button" [mat-dialog-close]="null" color="warn" mat-raised-button tabindex="-1">{{'shared.reset'|translate}}</button>
</mat-dialog-actions>
