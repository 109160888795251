export const lang = {
  invalidate: 'Dévalider',
  validate: 'Valider',
  exit: 'Quitter',
  save_exit: 'Enregistrer et quitter',
  save: 'Enregistrer',
  date: 'Date',
  patient: 'Patient',
  PATIENT: 'Payant',
  patient_list: 'Liste des patients',
  physician_list: 'Liste des médecins',
  organization: 'Organisme',
  beneficiary: 'Bénéficiaire',
  beneficiary_type: 'Bénéficiaire',
  physician: 'Médecin',
  comment: 'Commentaire',
  quotation: 'Devis',
  b_procedures: 'Procedures',
  store_articles: 'Pharmacie',
  patient_visits: 'HIS/RIS',
  fees_notes: 'Notes d’honoraires',
  code: 'Code',
  description: 'Désignation',
  qte: 'Qté',
  price: 'Prix',
  tm_percent: 'TM%',
  tp_percent: 'TP%',
  tm_amount: 'Montant TM',
  tp_amount: 'Montant TP',
  sub_amount: 'Sous-Total',
  organization_part: 'Part Organisme',
  patient_part: 'Part adhérent',
  total: 'Total',
  new: 'Nouveau',
  reset: 'Annuler',
  confirm: 'Confirmer',
  ok: 'OK',
  email: 'Email',
  name: 'Nom',
  full_name: 'Nom Complet',
  address: 'Adresse',
  phone_number: 'Téléphone',
  invalid_value: 'Valeur invalide',
  is_required: 'Ce champ est requis',
  submit_add: 'Ajouter',
  new_line: 'Nouvelle ligne',
  invoice_select_details: 'Liste des charges',
  first_name: 'Prénom',
  last_name: 'Nom',
  payer: 'Payeur',
  other: 'Autre',
  cin: 'CIN',
  type: 'Type',
  value: 'Valeur',
  procedure: 'Procedure',
  convention: 'Convention',
  CONVENTION: 'Convention',
  valid_date: 'Valide Jusqu\'au',
  taxed_amount: 'Montant TTC',
  discount: 'Remise(%)',
  days_suffix: ' jour(s)',
  patientVisits: 'Visite',
  feesNotes: 'Note d\'honnoraire',
  quotations: 'Devis',
  nr: 'N°',
  insurance: 'Assurance',
  care_sheets: 'Mutuelles',
  insured_type: 'Assuré(e)',
  title: 'Titre',
  birth_date: 'Date de naissance',
  details: 'Détailles',
  custom: 'Personnalisés',
  add_line: 'Ajouter une ligne',
  add_comment: 'Ajouter un commentaire',
  already_exist: 'déja existe',
  copyright: 'Copyright 2013-2017 - dépôt légal N°2017LB0009',
  modality: 'Modality',
  pec_answer_nature: 'Réponses prise en charge',
  'net_10_days': '10 jours nets',
  'net_30_days': '30 jours nets 30',
  'net_120_days': '120 jours nets',
  'net_60_days': '60 jours nets',
  'net_90_days': '90 jours nets',
  'end_of_month_the_10': 'Fin de mois le 10',
  'end_of_month_the_25': 'Fin de mois le 25',
  '30_end_of_month': '30 jours fin de mois',
  '45_end_of_month': '45 jours fin de mois',
  '60_end_of_month': '60 jours fin de mois',
  '90_end_of_month': '90 jours fin de mois',
  '30_end_of_month_the_10': '30 jours fin de mois le 10',
  '30_end_of_month_the_15': '30 jours fin de mois le 15',
  '30_end_of_month_the_25': '30 jours fin de mois le 25',
  '60_end_of_month_the_10': '60 jours fin de mois le 10',
  '60_end_of_month_the_25': '60 jours fin de mois le 25',
  '90_end_of_month_the_10': '90 jours fin de mois le 10',
  payment_condition: 'Condition de paiement',
  select_conventions: 'Selectionner des conventions',
  add_new_line: 'Nouvelle ligne',

  January: 'janvier ',
  February: 'février',
  March: 'mars',
  April: 'avril ',
  May: 'mai',
  June: 'juin',
  July: 'juillet',
  August: 'août',
  September: 'septembre',
  October: 'octobre',
  November: 'novembre',
  December: 'décembre',
  payment_mode: 'Mode de paiement',
  'None': 'Aucune',
  'CASH': 'Espèce',
  'CCCA': 'Carte de crédit',
  'CCHK': 'Chèque',
  'CDAC': 'Compte de crédit / débit',
  'CHCK': 'Chèque',
  'DDPO': 'Dépôt direct',
  'DEBC': 'Carte de débit',
  invalidate_object_failed: 'Impossible de dévalider ce document veuillez verifier ses dépendences!',
  from_date: 'Du',
  to_date: 'Au',
  patient_first_name: 'Patient. Prénom',
  patient_last_name: 'Patient. Nom',
  "stop_document_at_amount": "Arrêté le présent document à la somme de ",
  "upload_file_error": "Erreur d'upload",
  "upload_success": "Upload terminé",
  add: "Ajouter",
  gender: 'Sexe',
  payment_status: 'Statut de paiement',
  default_toolbar_tab: 'Onglet par défaut',
  global_discount: "Remise Globale",
  amount: "Montant totale",
  bill: "Facturer",
  pay: "Payer",
  delete: "Supprimer",
  print: "Imprimer",
  edit: "Modifier",
  discount_amount: "Remise",
  discount_percent: "Remise (%)",
  financial_details: "Détails de payment",
  today: "Aujourd'hui",
  three_days: "3 jours",
  this_week: "1 Semaine",
  this_month: "1 mois",
  all: "Tous",
  hl_seven_connection_failed: "Connexion HL7 perdue!!!",
  THIRD_PARTY_PAYMENT: "Tiers payant",
  new_exam: "Nouveau examen",
  pec: 'Prise en charge',
  PEC: 'PEC',
  patient_already_exist: "Patient existe déjà",
  close: "Fermer",
  exam_file: "Fiche examen",
  view: "Visualiser",
  INSURED_PATIENT: "Mutualiste",
  unpaid: "impayé",
  paid: "payé",
  partial: "paiement partiel",
  exempt: "dispensé",
  in_progress: 'En cours',
  granted: 'Accordé',
  rejected: 'Rejeté',
  agreement_state: "État du dossier",
  state: 'État',
  color: 'Couleur',
  save_empty_document: 'Enregistrement vide',
  is_valid: "Validé",
  is_draft: "Brouillon",
  base_percent: "Base de Calcule",
  stock_module: "Pharmacie",
  stock: "Stock",
  external_technician_code:"N°.INAMI - Nom de dispensateur",
  external_physician_code:"N°.INAMI - Nom de prescripteur",
  doc_date:'Date. Document',
  apply_convention:"Réappliquer la convention",
  table:"Table"
};
