import {TableColumnDefinition} from '@ft/table/table/models/table-definitions';
import * as _ from 'lodash';

export const PATIENT_COLUMNS: TableColumnDefinition[] = [
  {
    'label': 'shared.first_name',
    'key': 'first_name',
    'sortBy': 'first_name',
    'type': 'text',
    'is_global_searchable': true
  }, {
    'label': 'shared.last_name',
    'key': 'last_name',
    'sortBy': 'last_name',
    'type': 'text',
    'is_global_searchable': true
  },
  {
    'label': 'shared.birth_date',
    'key': 'birth_date',
    'sortBy': 'birth_date',
    'type': 'date',
    'is_global_searchable': true
  },
  {
    'label': 'pec.national_id',
    'key': 'national_id',
    'sortBy': 'national_id',
    'type': 'text',
    'is_global_searchable': true
  },
  {
    'label': 'patient.is_insured',
    'key': 'is_insured',
    'type': 'boolean',
    'icon': 'mdi-checkbox-blank-circle',
    'style': (row) => {
      return _.get(row, 'is_insured') ? {'color':'#76FF03', 'font-size': '20px'} : {
        'color': 'grey',
        'font-size': '20px'
      };
    }
  }
];
