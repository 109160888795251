import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MainComponent} from './components/main/main.component';
import {QuotationComponent} from './components/quotation/quotation.component';
import {FeesNoteComponent} from './components/fees-note/fees-note.component';
import {FtCoreModule, MaterialModule} from '@ft/core';
import {TranslateModule} from '@ngx-translate/core';
import {FtbInvoicingRoutingModule} from './invoicing-routing.module';
import {InvoiceFromComponent} from './components/invoice-from/invoice-from.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FtbSharedModule} from '../shared/shared.module';
import {NgBusyModule} from 'ng-busy';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {FtTableModule} from '@ft/table';
import { CareSheetComponent } from './components/care-sheet/care-sheet.component';
import { MainContainerComponent } from './components/main-container/main-container.component';
import { InvoiceListComponent } from './components/invoice-list/invoice-list.component';
import {CertificateComponent} from "./components/certificate-form/certificate.component";

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    FtbSharedModule,
    FtCoreModule,
    FtTableModule,
    NgBusyModule,
    FtbInvoicingRoutingModule,
    TranslateModule.forChild()


  ],
  declarations: [
    MainComponent,
    QuotationComponent,
    FeesNoteComponent,
    InvoiceFromComponent,
    CareSheetComponent,
    MainContainerComponent,
    InvoiceListComponent,
    CertificateComponent
  ],
  providers: []
})
export class FtbInvoicingModule {
}
