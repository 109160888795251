<div class="ft-parent-layout mat-elevation-z1" [class.ft-content-margin]="true" fxFlex fxLayout="column">
  <mat-toolbar color="accent">
    <div class="ft-module-icon">
      <button type="button" mat-icon-button>
        <mat-icon fontSet="mdi" fontIcon="mdi-format-list-numbers"></mat-icon>
      </button>
    </div>
    <h2>{{ 'settings.sequence_numbers' | translate }}</h2>
    <span fxFlex></span>

  </mat-toolbar>
  <div fxFlex fxLayout="column" class="ft-content ft-content-no-padding">
    <!--<ft-sequence-table></ft-sequence-table>-->
  </div>
</div>
