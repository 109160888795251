import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {FtbDashboardComponent} from './components/dashboard/dashboard.component';
import {FtbMainComponent} from './components/main/main.component';
import {FtbCoreComponent} from './core.component';

import {AuthGuard} from '@ft/core';


const routes: Routes = [

  {
    path: '',
    component: FtbCoreComponent,
    children: [
      {path: '', pathMatch: 'full', redirectTo: 'dashboard'},
      {path: 'dashboard', component: FtbDashboardComponent},
]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class FtbCoreRoutingModule {
}
