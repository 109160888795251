import {TableColumnDefinition} from '@ft/table/table/models/table-definitions';

export const FEES_NOTES_COLUMNS: TableColumnDefinition[] = [
  {
    'label': 'invoicing.fees_note_number',
    'key': 'number',
    'sortBy': 'number',
    'type': 'text',
    'is_global_searchable': true
  },
  {
    'label': 'shared.date',
    'key': 'doc_date',
    'sortBy': 'doc_date',
    'type': 'date',
    'is_global_searchable': true
  },
  {
    'label': 'shared.patient_first_name',
    'type': 'text',
    'key': 'patient.first_name',
    'sortBy': 'patient.first_name',
    'is_global_searchable': true,
    'is_ref': true,
    "model": "shared.FtPatient"
  },
  {
    'label': 'shared.patient_last_name',
    'type': 'text',
    'key': 'patient.last_name',
    'sortBy': 'patient.last_name',
    'is_global_searchable': true,
    'is_ref': true,
    "model": "shared.FtPatient"
  },
  {
    'label': 'table.taxed_amount',
    'key': 'taxed_amount',
    'sortBy': 'taxed_amount',
    'type': 'number',
    'numberConfig': 'currency',
    'is_global_searchable': true
  }
];
