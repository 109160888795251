import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PecFormComponent} from './components/pec-form/pec-form.component';
import {MainComponent} from './components/main/main.component';
import {FtbPecRoutingModule} from './pec-routing.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FtbSharedModule} from '../shared/shared.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {FtCoreModule, MaterialModule} from '@ft/core';
import {TranslateModule} from '@ngx-translate/core';
import {FtTableModule} from '@ft/table';
import {NgBusyModule} from 'ng-busy';
import {SelectDocumentsDialogComponent} from '../shared/dialogs/select-documents-dialog/select-documents-dialog.component';
import {LinesSelectDialogComponent} from '../shared/dialogs/lines-select-dialog/lines-select-dialog.component';
import {ListDialogComponent} from '../shared/dialogs/list-dialog/list-dialog.component';
import {ResponseDialogComponent} from './dialogs/response-dialog/response-dialog.component';
import {ResponseLinesComponent} from './components/response-lines/response-lines.component';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    TranslateModule.forChild(),
    FtbSharedModule,
    FtCoreModule,
    FtTableModule,
    NgBusyModule,
    FtbPecRoutingModule,
  ],
  declarations: [MainComponent, PecFormComponent, ResponseDialogComponent, ResponseLinesComponent
  ]
})
export class FtbPecModule {
}
