import {TableColumnDefinition} from '@ft/table/table/models/table-definitions';
import * as _ from "lodash";

export const INVOICE_COLUMNS: TableColumnDefinition[] = [
  {
    'label': 'invoicing.invoice_number',
    'key': 'number',
    'type': 'text',
    'is_global_searchable': true,
    'sortBy': 'number'
  },
  {
    'label': 'shared.date',
    'key': 'doc_date',
    'sortBy': 'doc_date',
    'type': 'date',
    'is_global_searchable': true
  } ,
  {
    'label': 'shared.patient_first_name',
    'type': 'text',
    'key': 'patient.first_name',
    'sortBy': 'patient.first_name',
    'is_global_searchable': true,
    'is_ref': true,
    "model":"shared.FtPatient"
  },
  {
    'label': 'shared.patient_last_name',
    'type': 'text',
    'key': 'patient.last_name',
    'sortBy': 'patient.last_name',
    'is_global_searchable': true,
    'is_ref': true,
    "model":"shared.FtPatient"
  }
  ,
  {
    'label': 'shared.insurance',
    'key': 'organization.name',
    'sortBy': 'organization.name',
    'type': 'text',
    'is_ref': true,
    'model':'shared.insurance.Organization',
    'is_global_searchable': true
  },
  {
    'label': 'shared.state',
    'key': 'state.is_valid',
    'type': 'boolean',
    'icon': 'mdi-checkbox-blank-circle',
    'tooltip':(row)=>{return   _.get(row, 'state.is_valid') ? "shared.is_valid" : "shared.is_draft" } ,
    'style': (row) => {
      return _.get(row, 'state.is_valid')  ? {
        'color': '#76FF03',
        'font-size': '20px'
      } : {
        'color': 'grey',
        'font-size': '20px'
      };
    }
  },
  {
    'label': 'shared.taxed_amount',
    'key': 'taxed_amount',
    'sortBy': 'taxed_amount',
    'type': 'number',
    'numberConfig':'currency',
    'is_global_searchable': true
  }
];
