import {Component, OnInit} from '@angular/core';
import * as moment from 'moment';
import * as _ from 'lodash';
import {StateService} from '../../services/state-service.service';
import {Subscription} from "rxjs";
import {MatTableDataSource} from "@angular/material/table";

@Component({
  selector: 'ftb-daily-payments',
  templateUrl: './daily-payments.component.html',
  styleUrls: ['./daily-payments.component.scss']
})
export class DailyPaymentsComponent implements OnInit {
  results = [];
  busy: Subscription;

  filter = {doc_date: moment()};

  public dataSource: MatTableDataSource<any> = new MatTableDataSource([]);
  public dailyTreasurySource: MatTableDataSource<any> = new MatTableDataSource([]);
  public anteriorTreasurySource: MatTableDataSource<any> = new MatTableDataSource([]);

  constructor(private stateService: StateService) {
  }

  ngOnInit() {
    this.handleFilterChange(null);
  }

  handleFilterChange(ev) {
    this.busy = this.stateService.getPaymentState(this.filter).subscribe(data => {
      this.results = data;
      this.dataSource.data = [data];
      this.dailyTreasurySource.data = [{
        empty: 'states.nbr',
        CASH: data['cash_daily_paid_amount_nbr'],
        CHCK: data['check_daily_paid_amount_nbr'],
        DEBC: data['credit_daily_card_paid_amount_nbr']
      },
        {
          empty: 'states.amount',
          CASH: data['cash_daily_paid_amount'],
          CHCK: data['check_daily_paid_amount'],
          DEBC: data['credit_daily_card_paid_amount']

        }]
      this.anteriorTreasurySource.data = [{
        empty: 'states.nbr',
        CASH: data['cash_anterior_paid_amount_nbr'],
        CHCK: data['check_anterior_paid_amount_nbr'],
        DEBC: data['credit_card_anterior_paid_amount_nbr']
      },
        {
          empty: 'states.amount',
          CASH: data['cash_anterior_paid_amount'],
          CHCK: data['check_anterior_paid_amount'],
          DEBC: data['credit_card_anterior_paid_amount']

        }]
    });

  }

  formatFilter(){
    return {
      doc_date: _.get(this.filter, 'doc_date').format('DD/MM/YYYY')
    }
  }

}
