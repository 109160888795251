import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DocumentLinesComponent} from './components/document-lines/document-lines.component';
import {DocumentActionsComponent} from './components/document-actions/document-actions.component';
import {BillingService} from './services/billing.service';
import {HttpClientModule} from '@angular/common/http';
import {FtCoreModule, MaterialModule} from '@ft/core';
import {NgBusyModule} from 'ng-busy';
import {FormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {DocumentsListComponent} from './components/documents-list/documents-list.component';
import {FtTableModule} from '@ft/table';
import {RouterModule} from '@angular/router';
import {SelectDocumentsDialogComponent} from './dialogs/select-documents-dialog/select-documents-dialog.component';
import {ProcedureDialogComponent} from '../settings/dialogs/procedure-dialog/procedure-dialog.component';
import {AlertDialogComponent} from '../settings/dialogs/alert-dialog/alert-dialog.component';
import {DeleteButtonComponent} from './components/delete-button/delete-button.component';
import {LinesSelectDialogComponent} from './dialogs/lines-select-dialog/lines-select-dialog.component';
import {SelectInputComponent} from './components/select-input/select-input.component';
import {SelectListComponent} from './components/select-list/select-list.component';
import {SharedService} from './services/shared-service';
import {ListDialogComponent} from './dialogs/list-dialog/list-dialog.component';
import {DeleteDialogComponent} from './dialogs/delete-dialog/delete-dialog.component';
import {ngfModule} from "angular-file";
import { ImportCsvButtonComponent } from './components/import-csv-button/import-csv-button.component';
import { XlsExportDirective } from './directives/xls-export.directive';


@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    TranslateModule.forChild(),
    HttpClientModule,
    FormsModule,
    NgBusyModule,
    FtTableModule,
    FtCoreModule,
    RouterModule,
    ngfModule
  ],
  declarations: [
    DocumentLinesComponent,
    DocumentActionsComponent,
    DocumentsListComponent,
    SelectDocumentsDialogComponent,
    LinesSelectDialogComponent,
    DeleteButtonComponent,
    SelectInputComponent,
    SelectListComponent,
    DeleteDialogComponent,
    ListDialogComponent,
    ImportCsvButtonComponent,
    XlsExportDirective
     ],
  exports: [DocumentLinesComponent, DocumentActionsComponent, SelectDocumentsDialogComponent, DeleteButtonComponent, SelectInputComponent, SelectListComponent,ImportCsvButtonComponent,XlsExportDirective],
  providers: [BillingService, SharedService]

})
export class FtbSharedModule {
}
