import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {FtWsService} from "@ft/core";

@Injectable({
  providedIn: 'root'
})
export class PecService {

  constructor(private http: HttpClient, private ws: FtWsService) {

  }


  getNonFullyConsumedPecs(query) {
    return this.ws.call('pec.get_non_fully_consumed_pecs', query);
  }
}
