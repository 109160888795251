import {Component, OnInit} from '@angular/core';
import {BehaviorSubject, Observable, of, Subscription} from "rxjs";
import {TableColumnDefinition} from "@ft/table";
import * as _ from "lodash";
import {StateService} from "../../services/state-service.service";
import {TranslateService} from "@ngx-translate/core";
import * as moment from "moment";

@Component({
  selector: 'ftb-recoveries-state',
  templateUrl: './recoveries-state.component.html',
  styleUrls: ['./recoveries-state.component.scss']
})
export class RecoveriesStateComponent implements OnInit {


  items: any = [];
  busy: Subscription;

  linesSource$: Observable<any[]> = of([]);
  filter = {'from_date': moment(), 'to_date': moment()};
  has_res = false;
  viewColumns: TableColumnDefinition[] = [{
    label: "invoicing.invoice_number",
    type: "text",
    key: "number"
  }, {
    label: "states.m_15",
    type: "number",
    key: "m_15",
    numberConfig: 'float',
    footer: () => {
      return _.sumBy(this.recoveries, 'm_15');
    }
  }, {
    label: "states.m_30",
    type: 'number',
    key: "m_30",
    numberConfig: 'float',
    footer: () => {
      return _.sumBy(this.recoveries, 'm_30');
    }
  }, {
    label: "states.m_60",
    type: "number",
    key: "m_60",
    numberConfig: 'float',
    footer: () => {
      return _.sumBy(this.recoveries, 'm_60');
    }
  }, {
    label: "states.m_120",
    type: "number",
    key: "m_120",
    numberConfig: 'float',
    footer: () => {
      return _.sumBy(this.recoveries, 'm_120');
    }
  }];
  linesColumns: BehaviorSubject<TableColumnDefinition[]> = new BehaviorSubject<TableColumnDefinition[]>(this.viewColumns);
  recoveries = [];

  constructor(private stateService: StateService, private translateService: TranslateService) {
  }

  ngOnInit() {
    this.handleFilterChange(null);
  }


  handleFilterChange(ev) {

    this.busy = this.stateService.getClientRecoveries(this.formatFilter()).subscribe(data => {
      let totals = _.get(data, 'totals', {});
      if (!_.isEmpty(data)) {
        this.has_res = true;
        this.recoveries = data;
        this.linesSource$ = of(data);
      } else {
        this.has_res = false;
      }
    });

  }

  formatFilter() {
    return {
      _from: _.get(this.filter, 'from_date').format('DD/MM/YYYY'),
      _to: _.get(this.filter, 'to_date').format('DD/MM/YYYY'),
      state: 'client_recoveries'
    }
  }

}
