import {Injectable} from '@angular/core';
import {FtWsService} from '@ft/core';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class StateService {

  constructor(private ws: FtWsService) {
  }

  getState(filter) {
    return this.ws.call('billing-request.handle_financial_state', filter);
  }

  getPaymentState(filter) {
    return this.ws.call('payment.handle_daily_financial_state', filter);
  }

  getCareRequestState(filter) {
    return this.ws.call('pec.handle_requests_state', filter);
  }

  getBalanceStatus(filter) {
    return this.ws.call('invoice.balance_status', filter);
  }

  getClientRecoveries(filter) {
    console.log('dshkflsdkfhkdhfgkfdgkfdkh')
    return this.ws.call('invoice.client_recoveries', filter);
  }

  getDailyInvoices(filter) {
    return this.ws.call('invoice.get_daily_invoices', filter);
  }

  getAnteriorPayments(filter) {
    return this.ws.call('payment.get_payments_anterior_state', filter);
  }

  getPaymentsState(query) {
    return this.ws.call('payment.get_payments_state', {query});
  }
}
