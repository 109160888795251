import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';

import {AuthGuard} from '@ft/core';
import {StateViewComponent} from './components/state-view/state-view.component';
import {FtbMainComponent} from './components/main/main.component';
import {DailyPaymentsComponent} from './components/daily-payments/daily-payments.component';
import {CareRequestStateComponent} from "./components/care-request-state/care-request-state.component";
import {BalancesStateComponent} from "./components/balances-state/balances-state.component";
import {RecoveriesStateComponent} from "./components/recoveries-state/recoveries-state.component";
import {DailyInvoicesComponent} from "./components/daily-invoices/daily-invoices.component";
import {AnteriorViewComponent} from "./components/anterior-view/anterior-view.component";
import {PaymentActivityComponent} from "./components/payment-activity/payment-activity.component";

const routes: Routes = [
  {
    path: '',
    component: FtbMainComponent,
    children: [
      {path: '', pathMatch: 'full', redirectTo: 'daily-activity'},
      {path: 'state-view', component: StateViewComponent},
      {path: 'daily-payments', component: DailyPaymentsComponent},
      {path: 'care-requests', component: CareRequestStateComponent},
      {path: 'balances', component: BalancesStateComponent},
      {path: 'recoveries', component: RecoveriesStateComponent},
      {path: 'daily-invoices', component: DailyInvoicesComponent},
      {path: 'anterior-view', component: AnteriorViewComponent},
      {path: 'daily-activity', component: PaymentActivityComponent, data:{"type":"DAILY", label:"states.daily_activity", name:"daily_activity"}},
      {path: 'periodic-activity', component: PaymentActivityComponent, data:{"type":"PERIODIC", label:"states.periodic_activity", name:"periodic_activity"}}
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class FtbStatesRoutingModule {
}
