import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {BillingService} from '../../../shared/services/billing.service';
import {Subscription} from 'rxjs';
import {HlSevenService} from "../../services/hl-seven.service";

@Component({
  selector: 'ftb-hl-seven-client-dialog',
  templateUrl: './hl-seven-client-dialog.component.html',
  styleUrls: ['./hl-seven-client-dialog.component.scss']
})
export class HlSevenClientDialogComponent implements OnInit {
  hlSevenClient = null;
  public loader: Subscription;

  constructor(private hlSevenService: HlSevenService, public dialogRef: MatDialogRef<HlSevenClientDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit() {
    this.hlSevenClient = this.data ? this.data : {};
  }

  save() {
    this.loader=this.hlSevenService.saveClient(this.hlSevenClient).subscribe(data => this.dialogRef.close(data));
  }
}
