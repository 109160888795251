import {CERTIFICATE_LINES_COLUMNS, LINES_COLUMNS} from "./lines-headers";
import * as _ from 'lodash';
import {BILLING_REQUEST_VIEW} from "../../daily-workflow/utils/views-settings";
import {FEES_NOTES_VIEW, QUOTATIONS_VIEW} from "../../invoicing/utils/views-settings";
import {PROCEDURE_VIEW} from "./views-settings";

export function handleDisplayedColumns(cls) {
  switch (cls) {
    case 'certificate':
      return CERTIFICATE_LINES_COLUMNS;
    default:
      return LINES_COLUMNS.filter((e) => {
        return !_.includes(['feesNote', 'Quotation', 'pec', 'careSheet'], cls) ? true : !_.includes(['tariff.tm', 'tariff.tp', 'tm_amount', 'tp_amount'], e.key);
      });
  }
}

export function isDisableTab(cls, tab?) {
  return cls != 'Invoice' && tab != 'procedures' ? true : false;
}

export function handleTabs(cls, data) {
  switch (cls) {
    case 'Certificate':
      return [{
        label: 'shared.patient_visits',
        disable_cond: isDisableTab(cls, 'patientVisits'),
        icon: 'mdi-stethoscope',
        name: 'patientVisits',
        tableSettings: _.chain(BILLING_REQUEST_VIEW).cloneDeep().assignIn({query: _.get(data, 'patientVisits', [])}).value(),
        selected: []
      }]
    case 'Invoice':
      return [
        {
          label: 'shared.patient_visits',
          disable_cond: isDisableTab(cls, 'patientVisits'),
          icon: 'mdi-stethoscope',
          name: 'patientVisits',
          tableSettings: _.chain(BILLING_REQUEST_VIEW).cloneDeep().assignIn({query: _.get(data, 'patientVisits', [])}).value(),
          selected: []
        },
        {
          label: 'shared.fees_notes',
          disable_cond: isDisableTab(cls, 'feesNotes'),
          icon: 'mdi-note-text',
          name: 'feesNotes',
          tableSettings: _.chain(FEES_NOTES_VIEW).cloneDeep().assignIn({query: _.get(data, 'feesNotes', [])}).value(),
          selected: []
        },
        {
          label: 'shared.quotation',
          disable_cond: isDisableTab(cls, 'quotations'),
          icon: ' mdi-file-document',
          name: 'quotations',
          tableSettings: _.chain(QUOTATIONS_VIEW).cloneDeep().assignIn({query: _.get(data, 'quotations', [])}).value(),
          selected: []
        },
        {
          label: 'shared.b_procedures',
          disable_cond: false,
          icon: ' mdi-playlist-plus',
          name: 'procedures',
          tableSettings: PROCEDURE_VIEW,
          selected: []
        }
      ]
    default:
      return [
        {
          label: 'shared.b_procedures',
          disable_cond: false,
          icon: ' mdi-playlist-plus',
          name: 'procedures',
          tableSettings: PROCEDURE_VIEW,
          selected: []
        }
      ]
  }
}

