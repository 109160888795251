import {BrowserModule} from '@angular/platform-browser';
import {APP_INITIALIZER, NgModule} from '@angular/core';
import {FtbAppComponent} from './app.component';
import {HttpClientModule} from '@angular/common/http';
import {
    AppConfigsService,
    DefaultValuesService,
    FtAuthModule,
    FtCoreModule,
    ftTranslateFactory,
    FtWsConnectionService,
    FtWsModule,
    JwtConfig,
    MaterialModule,
    POST_AUTH_CONFIG,
    PrintingService,
    WS_CONFIG
} from '@ft/core';
import {NgBusyModule} from 'ng-busy';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {JwtModule} from '@auth0/angular-jwt';
import {lang} from './i18n';
import {FtbAppRoutingModule} from './app-routing.module';
import {FtbCoreModule} from './core/core.module';
import {FtTableModule} from '@ft/table';
import {FtbSettingsModule} from './settings/settings.module';
import {FtbDailyWorkflowModule} from './daily-workflow/daily-workflow.module';
import {FtbPecModule} from './pec/pec.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {PatientModule} from "./patient/patient.module";
import {ServiceWorkerModule} from "@angular/service-worker";
import {environment} from "../environments/environment";
import {FtbInvoicingModule} from "./invoicing/invoicing.module";
import {FtbStatesModule} from "./states/states.module";
import {FtbPaymentModule} from "./payment/payment.module";

import {SHARED_MODULE_CONFIG, SubLayoutModule} from "@ft/stock";

const translateConfig = {
    loader: {
        provide: TranslateLoader,
        useFactory: ftTranslateFactory(lang)
    }
};


export function postAuthFactory(defaultValuesService: DefaultValuesService, printingService: PrintingService) {
    return [
        defaultValuesService.load(),
        printingService.load()
    ];
}


@NgModule({
    declarations: [
        FtbAppComponent
    ],
    imports: [
        BrowserModule,
        HttpClientModule,
        BrowserAnimationsModule,
        FtCoreModule,
        FtWsModule,
        NgBusyModule,
        TranslateModule.forRoot(translateConfig),
        JwtModule.forRoot(JwtConfig),
        FtAuthModule.forRoot({
            provide: POST_AUTH_CONFIG, deps: [DefaultValuesService, PrintingService], useFactory: postAuthFactory
        }),
        FtWsModule.forRoot({
            provide: WS_CONFIG, useFactory: () => new Object({path: 'ws'})
        }),
        ServiceWorkerModule.register('ngsw-worker.js', {enabled: environment.production}),
        FtbAppRoutingModule,
        MaterialModule,
        FtTableModule,

        FtbCoreModule,
        FtbSettingsModule,
        FtbDailyWorkflowModule,
        FtbPecModule,
        PatientModule,
        FtbInvoicingModule,
        FtbStatesModule,
        FtbPaymentModule,
        SubLayoutModule.forRoot({
            provide: SHARED_MODULE_CONFIG, useFactory: () => new Object({pathName: '/stock'})
        })
    ],
    providers: [
        {
            multi: true,
            provide: APP_INITIALIZER,
            deps: [AppConfigsService],
            useFactory: (config: AppConfigsService) => () => config.load('/api/system/'),
        },
        {
            multi: true,
            provide: APP_INITIALIZER,
            deps: [FtWsConnectionService],
            useFactory: (config: FtWsConnectionService) => () => config.connect().toPromise(),
        }
    ],
    bootstrap: [FtbAppComponent]
})
export class AppModule {
}
