import {Component, OnInit} from '@angular/core';
import {DocumentsListComponent} from "../../../shared/components/documents-list/documents-list.component";
import {assignIn, cloneDeep, get, has, isNil} from "lodash";
import {PAYMENTS_VIEW} from "../../utils/views-settings";
import {TableColumnDefinition} from "@ft/table";
import {debounceTime} from "rxjs/operators";

@Component({
  selector: 'ftb-payment-list',
  templateUrl: '../../../shared/components/documents-list/documents-list.component.html',
  styleUrls: ['../../../shared/components/documents-list/documents-list.component.scss']
})
export class PaymentListComponent extends DocumentsListComponent implements OnInit {
  footerData = {};
  amountColumns: TableColumnDefinition[] = [

    {
      'label': 'shared.beneficiary',
      "type": "badge",
      'key': 'beneficiary_type',
      'sortBy': 'beneficiary_type',
      'is_global_searchable': false,
      callBack: (row) => {
        return `shared.${row.beneficiary_type}`;
      },
      class: 'beneficiary_type_color'
    },
    {
      'label': 'shared.payer',
      "type": "badge",
      'key': 'payer_type',
      'sortBy': 'payer_type',
      'is_global_searchable': false,
      callBack: (row) => {
        return `shared.${row.payer_type}`;
      },
      style: (row) => {
        return {'background-color': this.defaultValuesService.getValue(`payer_type_color`)}
      }
    },
    {
      'label': 'shared.payment_mode',
      'type': 'badge',
      'key': 'payment_mode.type',
      'sortBy': 'payment_mode.type',
      'is_global_searchable': false,
      callBack: (row) => {
        return `shared.${row.payment_mode.type}`;
      },
      style: (row) => {
        return {'background-color': this.defaultValuesService.getValue(`payment_mode_color`)}
      }
    },
    {
      'label': 'payment.received_amount',
      'type': 'number',
      'key': 'received_amount',
      'sortBy': 'received_amount',
      'numberConfig': 'currency',
      'is_global_searchable': true,
      footer: () => {
        return this.handleFooterValue('received_amount')
      }
    },
    {
      'label': 'payment.consumed_amount',
      'type': 'number',
      'key': 'consumed_amount',
      'numberConfig': 'currency',
      'is_global_searchable': false,
      footer: () => {
        return this.handleFooterValue('consumed_amount')
      }
    },
    {
      'label': 'payment.remaining_amount',
      'type': 'number',
      'key': 'remaining_amount',
      'numberConfig': 'currency',
      'is_global_searchable': false,
      footer: () => {
        return this.handleFooterValue('remaining_amount')
      }
    }];
  prev_search = null;


  ngOnInit() {
    this.footerHandler = this.handleViewQueryChange;
    let data = assignIn(PAYMENTS_VIEW, {defaultFilters: true, hasFooter: true});
    data.viewSettings.viewColumns = data.viewSettings.viewColumns.concat(this.amountColumns);
    this.viewSettings = cloneDeep(data);
    this.handleSettings(data);
  }

  handleViewQueryChange(value) {
    if (get(value, 'search_all.search', '') != '') {
      this.viewSettings.hasFooter = false;
    }
    if (this.prev_search != '' && get(value, 'search_all.search') == '') {
      this.viewSettings.hasFooter = false;
      this.paymentService.handlePaymentFooter(value).pipe(debounceTime(250)).subscribe(data => {
        this.viewSettings.hasFooter = true;
        this.footerData = data;
      });
    } else {
      if (isNil(this.prev_search) && !has(value, 'search_all.search')) {
        this.viewSettings.hasFooter = true;
        this.paymentService.handlePaymentFooter(value).subscribe(data => {
          this.footerData = data;
        })
      }
    }
    this.prev_search = get(value, 'search_all.search');
  }

  handleFooterValue(key) {
    return get(this.footerData, `${key}`, 0);
  }
}
