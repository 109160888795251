<div fxFlex fxLayout="column">
  <div class="document-lines-container">
    <!--<ft-body-table *ngIf="cls" [settings]='responseSettings' [ObservableData]='tableDataChange'-->
                   <!--(linesChange)="onLinesChange($event)" [isExtendedCol]='extendedColHandler'></ft-body-table>-->

    <ft-generic-body-table fxFlex
                           [remote]="false"
                           [source]="linesSource$"
                           [selectable]="false"
                           [hasPagination]="true"
                           [columns]="linesColumns | async"
                           [multiActions]="linesActions"
                           [extendedHandler]="extendedColHandler"
                           [hasSearch]="hasSearch"
    >
    </ft-generic-body-table>

  </div>

</div>
