<div fxFlex fxLayout="column" class="ft-content ft-padding">
 <div [ngBusy]="loading"></div>
  <div *ngIf="feesNote.state?.is_valid" fxLayoutAlign="center center" class="validate-icon">
    <img mat-card-image src="/assets/lock-outline.svg" alt="validation img">
  </div>
  <form fxLayout="column" #feesNoteForm="ngForm" [ngBusy]="loading" fxFlex fxLayoutGap="6px">
    <div fxLayout="row" fxLayoutGap="6px">
      <div fxFlex fxLayout="column">
        <div fxLayout="row" fxLayoutGap="6px">
          <mat-form-field>
            <input matInput type="text" required [ftSequenceInput]="seqQuery.cls" [ftSequenceToggle]="toggle"
                   name="sequence"
                   placeholder="{{'invoicing.fees_note_number'|translate}}" [(ngModel)]="feesNote.number"/>
            <ft-sequence-toggle [disabled]="feesNote.id"  matSuffix #toggle [(objectId)]="loadedObject"
                                [config]="{cls: seqQuery.cls, field: 'number'}">
            </ft-sequence-toggle>
          </mat-form-field>
          <mat-form-field>
            <input matInput ftDate [matDatepicker]="note_date" [placeholder]="'shared.date'|translate"
                   [(ngModel)]="feesNote.doc_date" name="doc_date">
            <mat-datepicker-toggle matSuffix [for]="note_date">
              <mat-icon matDatepickerToggleIcon fontSet="mdi" fontIcon="mdi-calendar-today"></mat-icon>
            </mat-datepicker-toggle>
            <mat-datepicker #note_date></mat-datepicker>
          </mat-form-field>
        </div>
        <div fxLayout="row" fxLayoutGap="6px">
          <ftb-select-input fxFlex="44" [required]="true" [disabled]="feesNoteForm.disabled"
                            [label]="'shared.patient'"
                            [(model)]="feesNote.patient"
                            value="full_name"
                            [settings]="patientInputSettings"
                            [dialog]="patientDialog"
                            (modelChange)="handleBeneficiaryChange($event)"></ftb-select-input>
        </div>
      </div>
      <mat-divider [vertical]="true"></mat-divider>
      <div fxFlex fxLayout="column">
        <div fxLayout="row" fxLayoutGap="6px">
          <ftb-select-input fxFlex="50" [label]="'shared.physician'" [(model)]="feesNote.physician" value="full_name"
                            [settings]="physicianInputSettings"
                            (modelChange)="onPhysicianChange($event)"></ftb-select-input>
        </div>
        <div fxLayout="row" fxLayoutGap="6px">
          <mat-form-field fxFlex="50">
            <input matInput [placeholder]="'invoicing.quotation_pattern'|translate"
                   [(ngModel)]="feesNote.pattern"
                   name="number"/>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div fxFlex>
      <ftb-document-lines fxFlex [(lines)]="feesNote.lines" [model]="feesNote" [cls]="'feesNote'" [showDetailedAmounts]="false"></ftb-document-lines>
    </div>
  </form>
</div>
<ftb-document-actions fxFlex="nogrow" [model]="feesNote" [cls]="cls" (modelChange)="onModelChange($event)" [loadedObject]="loadedObject"
                      [formInvalid]="feesNoteForm.invalid || !feesNote?.patient "
                      [routes]="routes" [(busy)]="loading"></ftb-document-actions>
