import {DocumentListSettings} from '../../shared/models/document-list-definition';
import {SYSTEM_COLUMNS} from '../../shared/utils/table-configs/common-system-column';
import {PATIENT_COLUMNS} from "../../shared/utils/table-configs/patient";
import {PatientDialogComponent} from "../dialogs/patient-dialog/patient-dialog.component";

export const PATIENTS_VIEW: DocumentListSettings = {
  trackByKey: 'id',
  hasSearch: true,
  selectable: true,
  hasPagination: true,
  viewNamespace: 'patientList',
  query: [],
  viewSettings: {
    'model': 'shared.FtPatient',
    'viewColumns': PATIENT_COLUMNS,
    'systemColumns': SYSTEM_COLUMNS,
    'defaultName': 'Liste des patients'
  },
  actions: [],
  resource: 'ft-patient',
  formRoute: null,
  formLabel: 'shared.patient',
  headerLabel: 'shared.patient_list',
  headerIcon: 'mdi-account-multiple',
  deleteTopic: 'patient.check_and_delete',
  saveLabel: 'patient.save_success',
  formDialog: PatientDialogComponent
};
