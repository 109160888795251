import {
  wsFrLang,
  authFrLang,
  coreFrLang,
  commonFrLang,
  wsEnLang,
  authEnLang,
  coreEnLang,
  commonEnLang
} from '@ft/core';

import {tableFrLang, tableEnLang} from '@ft/table';
import {sharedLang as stockSharedLang} from '@ft/stock';
import {storeLang as store} from '@ft/stock';
import {purchaseLang as purchase} from '@ft/stock';
import {saleLang as sale} from '@ft/stock';
import {inventoryLang as inventory} from '@ft/stock';
import {fileManagerLang as file_manager, mediaLang as media, visualizerLang as viewer} from '@ft/file-manager';


import {lang as billingCoreFr} from './core/i18n/fr';
import {lang as invoicingFr} from './invoicing/i18n/fr';
import {lang as BillingSharedFr} from './shared/i18n/fr';
import {lang as paymentFr} from './payment/i18n/fr';
import {lang as pecFr} from './pec/i18n/fr';
import {lang as settingsFr} from './settings/i18n/fr';
import {lang as statesFr} from './states/i18n/fr';
import {lang as patientFr} from './patient/i18n/fr';
import {lang as dailyWorkflowFr} from './daily-workflow/i18n/fr';

import {lang as billingCoreEn} from './core/i18n/en';
import {lang as invoicingEn} from './invoicing/i18n/en';
import {lang as BillingSharedEn} from './shared/i18n/en';
import {lang as paymentEn} from './payment/i18n/en';
import {lang as pecEn} from './pec/i18n/en';
import {lang as settingsEn} from './settings/i18n/en';
import {lang as statesEn} from './states/i18n/en';
import {lang as patientEn} from './patient/i18n/en';
import {lang as dailyWorkflowEn} from './daily-workflow/i18n/en';

const fr = Object.assign(
  {},
  {ws: wsFrLang},
  {auth: authFrLang},
  {core: Object.assign({}, coreFrLang, billingCoreFr)},
  {common: commonFrLang},
  {table: tableFrLang},
  {shared: Object.assign({}, stockSharedLang, BillingSharedFr)},
  {invoicing: invoicingFr},
  {payment: paymentFr},
  {pec: pecFr},
  {settings: settingsFr},
  {states: statesFr},
  {patient: patientFr},
  {dailyWorkflow: dailyWorkflowFr},
  // TODo stock & file manager fr lang
  {file_manager},
  {viewer},
  {media},
  {store},
  {purchase},
  {sale},
  {inventory}
);


const en = Object.assign(
  {},
  {ws: wsEnLang},
  {auth: authEnLang},
  {core: Object.assign({}, coreEnLang, billingCoreEn)},
  {common: commonEnLang},
  {table: tableEnLang},
  {shared: Object.assign({}, stockSharedLang, BillingSharedEn)},
  {invoicing: invoicingEn},
  {payment: paymentEn},
  {pec: pecEn},
  {settings: settingsEn},
  {states: statesEn},
  {patient: patientEn},
  {dailyWorkflow: dailyWorkflowEn},
  // TODo stock & file manager en lang
  {file_manager},
  {viewer},
  {media},
  {store},
  {purchase},
  {sale},
  {inventory}
);


export const lang = {
  fr, en
};
