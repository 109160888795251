import {Component, OnInit} from '@angular/core';
import {BehaviorSubject, Observable, of, Subscription} from 'rxjs';
import {TableColumnDefinition} from '@ft/table';
import * as _ from 'lodash';
import {TranslateService} from '@ngx-translate/core';
import {MatDialog} from "@angular/material/dialog";
import {HlSevenClientDialogComponent} from '../../dialogs/hl-seven-client-dialog/hl-seven-client-dialog.component';
import {SMALL_DIALOG} from '@ft/core';
import {HlSevenService} from '../../services/hl-seven.service';

@Component({
  selector: 'ftb-hl-seven-setup',
  templateUrl: './hl-seven-setup.component.html',
  styleUrls: ['./hl-seven-setup.component.scss']
})
export class HlSevenSetupComponent implements OnInit {
  public loader: Subscription;
  // hlSeven: any = {port: 2575, title: 'HL7_Server'};

  linesSource$: Observable<any> = of([]);
  hlSevenClients = [];


  linesColumns = new BehaviorSubject<TableColumnDefinition[]>([
    {
      'label': 'shared.title',
      'key': 'title',
      'type': 'text'
    },
    {
      'label': 'settings.host',
      'key': 'host',
      'type': 'text'
    },
    {
      'label': 'settings.port',
      'key': 'port',
      'type': 'number'
    },
    {
      'label': 'settings.active',
      'key': 'active',
      'type': 'boolean'
      // 'editable': true
    },
    {
      label: '', key: 'actions', type: 'actions', actions: [
        {
          class: 'mat-primary',
          icon: 'mdi-pencil',
          method: (item, index, ev) => {
            return this.updateClient(item, index);
          },
        },
        // {
        //   class: 'mat-warn',
        //   icon: 'mdi-close-circle',
        //   method: (item, index, ev) => {
        //     return this.removeClient(item, index);
        //   },
        // }
      ]
    }
  ]);

  clients: any = [];

  linesActions = [
    {
      class: 'mat-primary',
      icon: 'mdi-plus',
      isMultipleSelection: false,
      method: (item, ev) => {
        return this.addClient();
      },
      tooltip: this.translateService.instant('settings.hl_seven_client'),
      hidden: this.clients.length == 1
    }
  ];


  constructor(private translateService: TranslateService, protected dialog: MatDialog, private hlSevenService: HlSevenService) {
  }

  ngOnInit() {
    // this.loader = this.hlSevenService.getConfig().subscribe(res => {
    //   this.hlSeven=res[0];
    // });
    this.loader = this.handleClients();

  }

  handleClients() {
    return this.hlSevenService.getClients().subscribe(res => {
      if (res) {
        this.clients = res;
        this.linesSource$ = of(res);
      }
    })
  }

  startServer() {
    // this.loader = this.hlSevenService.handleConfig(this.hlSeven).subscribe(data => {
    //   if (data) {
    //     this.hlSeven = data;
    //     this.loader = this.hlSevenService.startService().subscribe(res => {
    //     });
    //   }
    // });
  }

  stopServer() {
    // this.loader = this.hlSevenService.stopServer().subscribe(res => {
    // });
  }

  restartServer() {
    // this.loader = this.hlSevenService.restartServer().subscribe(res => {
    // });
  }

  removeClient(item, idx) {
    this.hlSevenService.removeClient(item.id).subscribe(data => {
      this.handleClients();
    });
  }

  updateClient(item, idx) {
    this.dialog.open(HlSevenClientDialogComponent, _.assign(SMALL_DIALOG, {data: item})).afterClosed().subscribe(data => {
      this.handleClients();
    });
  }

  addClient() {
    this.dialog.open(HlSevenClientDialogComponent, _.assign(SMALL_DIALOG, {data: null})).afterClosed().subscribe(data => {
      this.handleClients();
    });
  }
}
