<div fxFlex class="ft-actions">
  <ng-template [ftAcl]="{resource:  'payment', action: 'delete', behavior: 'remove'}">
    <button type="button" *ngIf="model.id && !model?.state?.is_valid" mat-mini-fab color="warn" (click)="remove()">
      <mat-icon fontSet="mdi" fontIcon="mdi-delete"></mat-icon>
    </button>
  </ng-template>

  <button type="button" *ngIf="model.id" mat-mini-fab color="primary"
          (prePrint)="prePrint()" [disabled]="model?.consumed_amount===0"
          [ftPrint]="{group:cls, view:getPrintUrl('payment'),  model:model?.id}">
    <mat-icon fontSet="mdi" fontIcon="mdi-printer"></mat-icon>
  </button>
  <button type="button" *ngIf="model.id" mat-mini-fab color="primary"
          (prePrint)="prePrint()" [disabled]="model?.consumed_amount===0"
          [ftPrint]="{group:'payment', view:getPrintUrl('payment'),type:'pdf',  model:model?.id}">
    <mat-icon fontSet="mdi" fontIcon="mdi-file-pdf-box"></mat-icon>
  </button>

  <span fxFlex></span>

  <button type="button" mat-raised-button color="warn" (click)="exit()">
    <span>{{'shared.exit'|translate}}</span>
  </button>
  <ng-template [ftAcl]="{resource: 'payment', action: 'invalidate', behavior: 'remove'}">
    <button type="button" *ngIf="model?.lines.length>0" mat-raised-button color="warn" (click)="dissociateLines()">
      <span>{{'payment.dissociate'|translate}}</span>
    </button>
  </ng-template>
  <ng-template [ftAcl]="{resource: 'payment', action: 'invalidate', behavior: 'remove'}">
    <button type="button" *ngIf="headerDisabled" mat-raised-button color="warn"
            (click)="invalidateHeader()">
      <span>{{'payment.header_invalidate'|translate}}</span>
    </button>
  </ng-template>
  <button type="button" *ngIf="model?.received_amount>0 && preLines.length>0" mat-raised-button color="primary"
          (click)="shareReceivedAmount()" class="success-button">
    <span>{{'payment.calculate'|translate}}</span>
  </button>
  <button type="button" *ngIf="!model?.state?.is_valid" mat-raised-button color="primary" (click)="save(true)"
          [disabled]="formInvalid">
    <span>{{'shared.save_exit'|translate}}</span>
  </button>
  <button type="button" *ngIf="!model?.state?.is_valid" mat-raised-button color="primary" (click)="save()"
          [disabled]="formInvalid">
    <span>{{'shared.save'|translate}}</span>
  </button>
</div>


