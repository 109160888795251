import {Component, OnInit} from '@angular/core';
import {BehaviorSubject, Observable, of, Subscription} from "rxjs";
import {TableColumnDefinition} from "@ft/table";
import * as moment from "moment";
import * as _ from "lodash";
import {get} from "lodash";
import {StateService} from "../../services/state-service.service";
import {TranslateService} from "@ngx-translate/core";
import {SCALE_ANIMATION} from "@ft/core";

@Component({
  selector: 'ftb-daily-invoices',
  templateUrl: './daily-invoices.component.html',
  styleUrls: ['./daily-invoices.component.scss'],
  animations: [
    SCALE_ANIMATION
  ]
})
export class DailyInvoicesComponent implements OnInit {
  items: any = [];
  busy: Subscription;

  linesSource$: Observable<any[]> = of([]);
  filter = {'from_date': moment(), 'to_date': moment()};

  percentModel = {center_percent: 100, physician_percent: 0, base_percent: 'taxed_amount', show_percents: false};

  has_res = false;
  invoices = [];
  showPercents = false;
  percentsColumns: TableColumnDefinition[] = [
    {
      label: "states.center_percent",
      type: "number",
      key: "center_percent",
      numberConfig: 'float',
      footer: () => {
        return _.sumBy(this.invoices, 'center_percent');
      }
    },
    {
      label: "states.physician_percent",
      type: "number",
      key: "physician_percent",
      numberConfig: 'float',
      footer: () => {
        return _.sumBy(this.invoices, 'physician_percent');
      }
    },
  ];
  viewColumns: TableColumnDefinition[] = [
    {
      label: "invoicing.invoice_number",
      type: "text",
      key: "number"
    },
    {
      label: "invoicing.payment_deadline",
      type: "text",
      key: "deadline"
    },
    {
      label: "shared.taxed_amount",
      type: 'number',
      key: "taxed_amount",
      numberConfig: 'float',
      footer: () => {
        return _.sumBy(this.invoices, 'taxed_amount');
      }
    },
    {
      label: "states.paid_total",
      type: "number",
      key: "paid_total",
      numberConfig: 'float',
      footer: () => {
        return _.sumBy(this.invoices, 'paid_total');
      }
    }
  ];
  linesColumns: BehaviorSubject<TableColumnDefinition[]> = new BehaviorSubject<TableColumnDefinition[]>(this.viewColumns);

  constructor(private stateService: StateService, private translateService: TranslateService) {
  }

  ngOnInit() {
    this.handleFilterChange();
  }


  handleFilterChange() {
    this.busy = this.stateService.getDailyInvoices(this.formatFilter()).subscribe(data => {
      if (!_.isEmpty(data)) {
        this.has_res = true;
        this.invoices = data;
        this.linesSource$ = of(data);
      }
      else {
        this.has_res = false;
      }
    });
  }

  formatFilter() {
    return _.assign({
      _from: _.get(this.filter, 'from_date').format('DD/MM/YYYY'),
      _to: _.get(this.filter, 'to_date').format('DD/MM/YYYY'),
      state: 'daily_invoices'
    }, this.percentModel.show_percents ? this.percentModel : {})
  }

  handlePercents() {
    this.linesColumns = new BehaviorSubject<TableColumnDefinition[]>(this.viewColumns.concat(this.percentModel.show_percents ? this.percentsColumns : []));
    this.handleFilterChange();
  }

  handlePercentChange(p) {
    switch (p) {
      case 'physician_percent':
        this.percentModel.center_percent = 100 - this.percentModel.physician_percent;
        break;
      case 'center_percent':
        this.percentModel.physician_percent = 100 - this.percentModel.center_percent;
        break;
    }
    this.handlePercents();
  }
}
