import {TableColumnDefinition} from '@ft/table/table/models/table-definitions';
import {get} from 'lodash';

export const CARE_REQUEST_COLUMNS: TableColumnDefinition[] = [
  {
    "label": "pec.pec_number",
    "key": "number",
    "sortBy": "number",
    "type": "text",
    "is_global_searchable": true
  },
  {
    "label": "shared.date",
    "key": "doc_date",
    "sortBy": "doc_date",
    "type": "date",
    "is_global_searchable": true,
  },
  {
    'label': 'shared.patient_first_name',
    'type': 'text',
    'key': 'patient.first_name',
    'sortBy': 'patient.first_name',
    'is_global_searchable': true,
    'is_ref': true,
    "model": "shared.FtPatient"
  },
  {
    'label': 'shared.patient_last_name',
    'type': 'text',
    'key': 'patient.last_name',
    'sortBy': 'patient.last_name',
    'is_global_searchable': true,
    'is_ref': true,
    "model": "shared.FtPatient"
  },
  {
    "label": "shared.insurance",
    "key": "organization.name",
    "sortBy": "organization.name",
    "type": "text",
    "is_global_searchable": true,
    'model': 'shared.insurance.Organization'
  },
  {
    "label": "shared.taxed_amount",
    "key": "taxed_amount",
    "sortBy": "taxed_amount",
    "type": "number",
    'numberConfig': 'currency',
    "is_global_searchable": true
  },
  {
    "label": "shared.agreement_state",
    "key": "agreement_state",
    "type": "badge",
    "is_global_searchable": false,
    "class": (row) => get(row, 'agreement_state', ''),
    callBack: (row) => `shared.${row.agreement_state}`
  },
  {
    'label': 'pec.ref',
    'type': 'text',
    'key': 'agreement.ref',
    'is_global_searchable': false
  },
  {
    "label": "pec.confirmed_amount",
    "key": "agreement.confirmed_amount",
    "type": "number",
    'numberConfig': 'currency',
    "is_global_searchable": false
  }
]
