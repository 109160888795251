export const lang = {
  schedule: "Plan",
  debtor: "Admission modality",
  taxed_amount: "Amount",
  received_amount: "Received amount",
  fence_payment: "close payment",
  fenced_payment_message_success: "Payment closed successfully",
  cancel_payment_close: "Cancel payment close",
  cancel_payment_close_success: "Payment close Canceled successfully"

};
