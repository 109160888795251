import {NgModule} from '@angular/core';
import {MainComponent} from './components/main/main.component';
import {RouterModule, Routes} from '@angular/router';
import {FeesNoteComponent} from './components/fees-note/fees-note.component';
import {QuotationComponent} from './components/quotation/quotation.component';
import {InvoiceFromComponent} from './components/invoice-from/invoice-from.component';
import {DocumentsListComponent} from '../shared/components/documents-list/documents-list.component';
import {CareSheetComponent} from './components/care-sheet/care-sheet.component';
import {
  CARE_SHEET_VIEW,
  CERTIFICATE_VIEW,
  FEES_NOTES_VIEW,
  INVOICES_VIEW,
  QUOTATIONS_VIEW
} from './utils/views-settings';
import {MainContainerComponent} from './components/main-container/main-container.component';
import {assignIn} from 'lodash';
import {InvoiceListComponent} from "./components/invoice-list/invoice-list.component";
import {CertificateComponent} from "./components/certificate-form/certificate.component";

const routes: Routes = [
  {
    path: '',
    component: MainComponent,
    children: [
      {path: '', pathMatch: 'full', redirectTo: 'invoice'},
      {
        path: 'invoice', component: MainContainerComponent, children: [
          {path: '', pathMatch: 'full', redirectTo: 'invoices'},
          {path: 'invoices', component: InvoiceListComponent, data: assignIn(INVOICES_VIEW, {defaultFilters:true, hasFooter:false})},
          {path: 'invoice-form', component: InvoiceFromComponent},
          {path: 'invoice-form/:id', component: InvoiceFromComponent},
        ]
      },
      {
        path: 'quotation', component: MainContainerComponent, children: [
          {path: '', pathMatch: 'full', redirectTo: 'quotations'},
          {path: 'quotations', component: DocumentsListComponent, data: QUOTATIONS_VIEW},
          {path: 'quotation-form', component: QuotationComponent},
          {path: 'quotation-form/:id', component: QuotationComponent},
        ]
      },
      {
        path: 'fees-note', component: MainContainerComponent, children: [
          {path: '', pathMatch: 'full', redirectTo: 'fees-notes'},
          {path: 'fees-notes', component: DocumentsListComponent, data: FEES_NOTES_VIEW},
          {path: 'fees-note-form', component: FeesNoteComponent},
          {path: 'fees-note-form/:id', component: FeesNoteComponent}
        ]
      },
      {
        path: 'care-sheet', component: MainContainerComponent, children: [
          {path: '', pathMatch: 'full', redirectTo: 'care-sheets'},
          {path: 'care-sheets', component: DocumentsListComponent, data: CARE_SHEET_VIEW},
          {path: 'care-sheet-form', component: CareSheetComponent},
          {path: 'care-sheet-form/:id', component: CareSheetComponent}]
      },
      {
        path: 'certificate', component: MainContainerComponent, children: [
          {path: '', pathMatch: 'full', redirectTo: 'certificates'},
          {path: 'certificates', component: DocumentsListComponent, data: CERTIFICATE_VIEW},
          {path: 'certificate-form', component: CertificateComponent},
          {path: 'certificate-form/:id', component: CertificateComponent}]
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class FtbInvoicingRoutingModule {
}


