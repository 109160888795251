import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {RouterModule, Routes} from "@angular/router";
import {DocumentsListComponent} from "../shared/components/documents-list/documents-list.component";
import {MainComponent} from "./components/main/main.component";
import {PATIENTS_VIEW} from "./utils/views-settings";


const routes: Routes = [
  {
    path: '',
    component: MainComponent,
    children: [

          {path: '', pathMatch: 'full', redirectTo: 'patients'},
          {path: 'patients', component: DocumentsListComponent, data: PATIENTS_VIEW}
          // {path: 'payment-form', component: PaymentFormComponent},
          // {path: 'payment-form/:id', component: PaymentFormComponent},
        ]



  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PatientRoutingModule { }
