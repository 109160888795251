import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {DocumentsListComponent} from '../shared/components/documents-list/documents-list.component';
import {MainComponent} from './components/main/main.component';
import {PecFormComponent} from './components/pec-form/pec-form.component';
import {AuthGuard} from '@ft/core';
import {CARE_REQUEST_VIEW} from './utils/views-settings';
import {PAYMENTS_VIEW} from '../payment/utils/views-settings';
import {PaymentFormComponent} from '../payment/components/payment-form/payment-form.component';


const routes: Routes = [

  {
    path: '',
    component: MainComponent,
    children: [
      {
        path: 'pecs', component: MainComponent, children: [
          {path: '', pathMatch: 'full', redirectTo: 'pecs'},
          {path: 'pecs', component: DocumentsListComponent, data: CARE_REQUEST_VIEW},
          {path: 'pec-form', component: PecFormComponent},
          {path: 'pec-form/:id', component: PecFormComponent},
        ]
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class FtbPecRoutingModule {
}


