import {Component, EventEmitter, OnInit} from '@angular/core';
import {ActivatedRoute, ParamMap} from '@angular/router';

import {BillingService} from '../../../shared/services/billing.service';
import * as _ from 'lodash';
import {Subscription} from 'rxjs';
import * as moment from 'moment';
import {RouterDataService} from '../../../shared/services/router-data.service';
import {PHYSICIAN_VIEW} from '../../../shared/utils/views-settings';
import {PATIENTS_VIEW} from '../../../patient/utils/views-settings';
import {CONVENTION_VIEW, ORGANIZATION_VIEW} from '../../../settings/utils/views-settings';
import {HEIGHT_ANIMATION, SCALE_ANIMATION} from '@ft/core';
import {PAYMENT_CONDITIONS} from '../../../shared/utils/payment-conditions';
import {PatientDialogComponent} from "../../../patient/dialogs/patient-dialog/patient-dialog.component";

@Component({
  selector: 'ftb-invoice-from',
  templateUrl: './invoice-from.component.html',
  animations: [
    SCALE_ANIMATION,
    HEIGHT_ANIMATION
  ],
  styleUrls: ['./invoice-from.component.scss']
})
export class InvoiceFromComponent implements OnInit {
  cls = 'invoice';
  loading: Subscription;
  invoice: any = {
    number: null,
    tariff: null,
    doc_date: moment(),
    deadline: moment(),
    beneficiary_type: 'patient',
    beneficiary: null,
    patient: null,
    organization: null,
    lines: []
  };
  paymentConditions = PAYMENT_CONDITIONS;

  routes = {list: '/invoicing/invoice/', form: '/invoicing/invoice/invoice-form/'};
  seqQuery = {
    cls: 'invoicing.Invoice',
    label: 'invoicing.invoice_number',
    fieldName: 'number',
    reloadDeleted: true

  };
  loadedObject = null;
  patientInputSettings = _.assignIn(_.cloneDeep(PATIENTS_VIEW), {selectable: false});
  organizationInputSettings = _.assignIn(_.cloneDeep(ORGANIZATION_VIEW), {selectable: false});
  conventionInputSettings = _.assignIn(_.cloneDeep(CONVENTION_VIEW), {selectable: false});
  physicianInputSettings = _.assignIn(_.cloneDeep(PHYSICIAN_VIEW), {selectable: false});


  conventionChangeEvent = new EventEmitter();
  PricingChangeEvent = new EventEmitter();
  pricingList: any = [];
  patientDialog = PatientDialogComponent;

  constructor(private route: ActivatedRoute, private billingService: BillingService, private routerDataService: RouterDataService) {
  }

  ngOnInit() {
    this.route.params.subscribe((params: ParamMap) => this.initData(params));

    this.billingService.getBillingDocs('pricing').subscribe(data => {
      this.pricingList = data;
    });

  }

  initData(params) {
    if (_.get(params, 'id')) {
      this.loading = this.billingService.getBillingDoc(this.cls, _.get(params, 'id'))
        .subscribe(data => {
          this.invoice = data;
        });
    } else {
      let invoiceData = _.get(this.routerDataService.routerData, 'invoiceData');
      if (invoiceData) {
        this.invoice = _.assignIn(this.invoice, invoiceData);
      }
    }

  }

  onSequenceChange(data) {
    if (typeof data === 'string') {
      this.invoice.number = data;
    } else {
      this.invoice.number = data[this.seqQuery.fieldName];
      this.invoice.id = data['id'];
    }
  }

  onPatientChange = (data) => {
    this.invoice.patient = data;
  };
  onOrganizationChange = (data) => {
    this.invoice.organization = data;
    this.invoice.convention = _.get(_.find(_.chain(this.invoice).get('organization.conventions').value(), {is_default: true}), 'convention', this.invoice.convention);
    this.invoice.payment_condition = !_.isEmpty(_.get(this.invoice, 'organization.payment_condition')) ? _.get(this.invoice, 'organization.payment_condition') : this.invoice.payment_condition;
    this.invoice.pricing = !_.isEmpty(_.get(this.invoice, 'organization.pricing')) ? _.get(this.invoice, 'organization.pricing') : this.invoice.pricing;
    this.handlePaymentCondition({value: this.invoice.payment_condition})
  };
  onPhysicianChange = (data) => {
    this.invoice.physician = data;
  };

  onModelChange(data) {
    this.invoice = data;
  }

  compareConvention(o1, o2) {
    return o1 && o2 ? o1.label === o2.label : o1 == o2;
  }

  handleConventionChange(value) {
    this.invoice.convention = value;
  }

  handlePricingChange(value) {
    this.PricingChangeEvent.emit(value);
  }

  compareCondition(o1, o2) {
    return o1 && o2 ? o1.key == o2.key || o1.id == o2.id : false;
  }

  comparePricingCondition(o1, o2) {
    return o1 && o2 ? o1.id == o2.id : false;
  }

  handlePaymentCondition(ev) {
    this.invoice.deadline = moment(this.invoice.doc_date).add(_.get(ev, 'value.value'), 'days');
  }

  handleLinesQuery() {
    let patient_org_query = [];
    let docs_query = [
      {key: 'state.is_valid', logical_operator: 'and', operator: '$eq', value: true},
      {key: 'fully_billed', logical_operator: 'and', operator: '$eq', value: false}
    ];
    if (this.invoice?.patient && this.invoice?.organization) {
      patient_org_query = [
        {key: 'patient', logical_operator: 'and', operator: '$eq', value: _.get(this.invoice?.patient, 'id')},
        {key: 'organization', logical_operator: 'and', operator: '$eq', value: _.get(this.invoice?.organization, 'id')},
      ];
    } else if (this.invoice?.patient && !this.invoice?.organization) {
      patient_org_query = [{
        key: 'patient',
        logical_operator: 'and',
        operator: '$eq',
        value: _.get(this.invoice?.patient, 'id')
      }];
    } else if (!this.invoice?.patient && this.invoice?.organization) {
      patient_org_query = [{
        key: 'organization',
        logical_operator: 'and',
        operator: '$eq',
        value: _.get(this.invoice?.organization, 'id')
      }];
    } else {
      patient_org_query = [];
    }

    return {
      cls: 'Invoice',
      'patientVisits': patient_org_query,
      'feesNotes': docs_query,
      'quotations': docs_query,
      'procedures': []
    }

  }
}
