<mat-toolbar color="primary">
  <div class="ft-module-icon">
    <button type="button" mat-icon-button>
      <mat-icon fontSet="mdi" fontIcon="mdi-ticket-percent"></mat-icon>
    </button>
  </div>
  <h2>{{ 'shared.convention' | translate }}</h2>
  <span fxFlex></span>
  <button type="button" mat-icon-button mat-dialog-close tabindex="-1">
    <mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
  </button>
</mat-toolbar>
<form novalidate #form="ngForm">
  <div [ngBusy]="loader"></div>

  <div mat-dialog-content fxLayout="column" class="ft-busy-container" style="min-height: 350px;">
    <div fxLayout="row" fxLayoutGap="6px">
      <mat-form-field fxFlex>
        <input matInput [(ngModel)]="convention.label" required name="label"
               #label="ngModel"
               ftCustomValidation="convention.check_uniqueness"
               [query]="{field:'label', pk:convention?.id}"
               [ftAutoFocus]="true"
               [placeholder]="'settings.name'|translate">
        <mat-error *ngIf="label?.errors?.exists" translate="shared.already_exist"></mat-error>
      </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutGap="6px">
      <!--To DO add after ft_core_fix-->
      <mat-form-field fxFlex>
        <input matInput [(ngModel)]="convention.tariff.tp" [ftNumber]="'float'" required name="tp" [min]="0"
               (change)="handleTariffPercents($event,'tp', 'tm')"
               [placeholder]="'settings.tp'|translate">
      </mat-form-field>
      <mat-form-field fxFlex>
        <input matInput [(ngModel)]="convention.tariff.tm" [ftNumber]="'float'" required name="tm" [min]="0"
               (change)="handleTariffPercents($event, 'tm', 'tp')"
               [placeholder]="'settings.tm'|translate">
      </mat-form-field>
    </div>
    <hr class="ft-sep">

    <div fxLayout="column" fxFlex>
      <ft-generic-body-table fxFlex
                             [remote]="false"
                             [source]="linesSource$"
                             [hasPagination]="true"
                             [columns]="linesColumns | async"
                             [hasSearch]="true"
      >
      </ft-generic-body-table>
    </div>

  </div>
  <div mat-dialog-actions>

    <button type="button" mat-raised-button color="primary" class="has-icon" (click)="newException()" type="button">
      <mat-icon fontSet="mdi" fontIcon="mdi-medical-bag"></mat-icon>
      <span>{{'settings.add_exception'|translate}}</span>
    </button>
    <button type="button" mat-mini-fab color="primary" [disabled]="!convention.id"
            ngfSelect [(files)]="files"
            (filesChange)="importExceptions($event)"
            accept=".csv" multiple="1"
            matTooltip="{{'settings.import_exceptions'|translate}}">
      <mat-icon fontSet="mdi" fontIcon="mdi-import"></mat-icon>
    </button>
    <button type="button" mat-mini-fab color="primary"
            *ngIf="convention.id && convention.exceptions.length>0"
            [columns]="viewColumns"
            [ftbXlsExport]="{filter:{pk:convention.id},topic:'convention.export_exceptions', fileName: convention.label +' exceptions' }"
            matTooltip="{{'settings.export_exceptions'|translate}}">
      <mat-icon fontSet="mdi" fontIcon="mdi-download"></mat-icon>
    </button>
    <span fxFlex></span>
    <button type="button" color="primary" mat-raised-button tabindex="2" (click)="save()" [disabled]="form.invalid">
      {{'shared.save'|translate}}
    </button>
    <button type="button" [mat-dialog-close]="null" type="button" color="warn" mat-raised-button
            tabindex="-1">{{'shared.reset'|translate}}</button>
  </div>
</form>

