<div class="ft-parent-layout mat-elevation-z1" [class.ft-content-margin]="true" fxFlex fxLayout="column">
  <mat-toolbar color="accent">
    <div class="ft-module-icon">
      <mat-icon fontSet="mdi" fontIcon="mdi-brightness-7"></mat-icon>
    </div>
    <h2 mat-dialog-title>{{ 'settings.organization' | translate }}</h2>
    <span fxFlex></span>
    <button type="button" mat-button class="with-icon" (click)="goToList()">
      <mat-icon fontSet="mdi" fontIcon="mdi-format-list-bulleted"></mat-icon>
      <span>{{ 'settings.organization_list' | translate }}</span>
    </button>
  </mat-toolbar>
  <div class="ft-content" fxFlex>
    <form #organizationForm="ngForm" fxLayout="column" fxFlex>
      <div [ngBusy]="loading"></div>
      <div fxLayout="row" fxLayoutGap="6px">
        <div fxLayoutGap="6px" fxFlex>
          <mat-form-field fxFlex>
            <input matInput [(ngModel)]="organization['code']" name="code" [ftAutoFocus]="true"
                   [placeholder]="'shared.code'|translate">
          </mat-form-field>
          <mat-form-field fxFlex class="with-error-label">
            <input required matInput [(ngModel)]="organization['name']" name="name"
                   #name="ngModel" ftCustomValidation="organization.check_uniqueness"
                   [query]="{field:'name', pk:organization?.id}"
                   [placeholder]="'shared.name'|translate">
            <mat-error *ngIf="name?.errors?.exists" translate="shared.already_exist"></mat-error>
          </mat-form-field>
        </div>
        <mat-form-field fxFlex>
          <input matInput [(ngModel)]="organization['full_name']" name="full_name"
                 [placeholder]="'shared.full_name'|translate">
        </mat-form-field>
      </div>
      <div fxLayout="row" fxLayoutGap="6px">
        <mat-form-field fxFlex>
          <mat-select [placeholder]="'shared.payment_condition' | translate" [compareWith]="compareCondition"
                      ftDefaultValues="default_payment_condition"
                      [(ngModel)]="organization.payment_condition" name="payment_condition">
            <mat-option *ngFor="let condition of paymentConditions" [value]="condition">
              {{'shared.' + condition.key|translate}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field fxFlex>
          <mat-select [placeholder]="'settings.default_pricing' | translate" [compareWith]="comparePricingCondition"
                      [(ngModel)]="organization.pricing" name="mode" [ngModelOptions]="{standalone:true}">
            <mat-option *ngFor="let p of pricingList" [value]="p">
              {{p.name}}
            </mat-option>
          </mat-select>
          <button *ngIf="organization.pricing" matSuffix mat-icon-button type="button" color="warn" matTooltip="clean"
                  type="button" color="warn"
                  (click)="$event.stopImmediatePropagation(); organization.pricing=null;">
            <mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
          </button>
        </mat-form-field>
      </div>
      <div fxLayout="row" fxLayoutGap="6px">
        <mat-form-field fxFlex class="with-error-label">
          <input matInput [(ngModel)]="organization['email']" name="email" type="email" required
                 [placeholder]="'shared.email'|translate" [formControl]="emailFormControl">
          <mat-error *ngIf="emailFormControl.hasError('email') && !emailFormControl.hasError('required')">
            {{'shared.invalid_value'|translate}}
          </mat-error>
          <mat-error *ngIf="emailFormControl.hasError('required')">
            {{'shared.is_required'|translate}}
          </mat-error>
        </mat-form-field>
        <mat-form-field fxFlex>
          <input matInput [(ngModel)]="organization['accounting_code']" name="accounting_code"
                 [placeholder]="'settings.accounting_code'|translate">
        </mat-form-field>
      </div>
      <div fxLayout="row" fxLayoutGap="6px">
        <mat-form-field fxFlex="50">
          <mat-chip-list #chipList class="ft-chip-list">
            <mat-chip *ngFor="let num of organization['phone_numbers']" [selectable]="true"
                      [removable]="true" (removed)="removePhoneItem(num)">
              <span>{{num}}</span>
              <mat-icon color="primary" matChipRemove fontSet="mdi" fontIcon="mdi-close"></mat-icon>
            </mat-chip>
            <input [placeholder]="'shared.phone_number'|translate"
                   [matChipInputFor]="chipList"
                   [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                   [matChipInputAddOnBlur]="true"
                   (matChipInputTokenEnd)="addPhoneItem($event)">
          </mat-chip-list>
        </mat-form-field>
        <mat-form-field fxFlex="50">
            <textarea matInput [(ngModel)]="organization['address']" name="address" [rows]="1"
                      [placeholder]="'shared.address'|translate"></textarea>
        </mat-form-field>
      </div>
      <div fxLayout="row" fxLayoutGap="6px">
        <mat-form-field fxFlex>
          <input matInput [(ngModel)]="organization['postal_code']" name="postal_code"
                 [placeholder]="'settings.postal_code'|translate">
        </mat-form-field>
        <mat-form-field fxFlex>
          <mat-select [placeholder]="'settings.city' | translate" [compareWith]="compareCityCondition"
                      [(ngModel)]="organization.city" name="mode" [ngModelOptions]="{standalone:true}">
            <mat-option *ngFor="let p of citiesList" [value]="p">
              {{p.full_name}}
            </mat-option>
          </mat-select>
          <button *ngIf="organization.pricing" matSuffix mat-icon-button type="button" color="warn" matTooltip="clean"
                  type="button" color="warn"
                  (click)="$event.stopImmediatePropagation(); organization.pricing=null;">
            <mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
          </button>
        </mat-form-field>
      </div>
      <hr class="ft-sep">
      <div fxFlex fxLayout="column">
        <ft-generic-body-table fxFlex
                               [remote]="false"
                               [source]="linesSource$"
                               [selectable]="false"
                               [hasPagination]="true"
                               [columns]="linesColumns | async"
                               [multiActions]="linesActions"
        >
        </ft-generic-body-table>
      </div>
    </form>
  </div>
  <div class="ft-actions">
    <ftb-delete-button *ngIf="organization['id']" [model]="organization" [deleteTopic]="'organization.check_and_delete'"
                       [afterDelete]="goToList"></ftb-delete-button>
    <span fxFlex></span>
    <!--<button type="button" mat-raised-button color="primary" class="has-icon" (click)="addConvention()">-->
    <!--<mat-icon fontSet="mdi" fontIcon="mdi-ticket-percent"></mat-icon>-->
    <!--<span>{{'settings.conventions'|translate}}</span>-->
    <!--</button>-->
    <button type="button" mat-raised-button color="warn" (click)="exit()">
      <span>{{'shared.exit'|translate}}</span>
    </button>
    <button type="button" color="primary" mat-raised-button tabindex="2" (click)="save()"
            [disabled]="organizationForm.invalid">
      {{'shared.save'|translate}}
    </button>

  </div>
</div>

