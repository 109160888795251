import {INVOICE_COLUMNS} from '../../shared/utils/table-configs/invoice';
import {QUOTATION_COLUMNS} from '../../shared/utils/table-configs/quotation';
import {FEES_NOTES_COLUMNS} from '../../shared/utils/table-configs/fees-note';
import {CARE_SHEET_COLUMNS} from '../../shared/utils/table-configs/care-sheet';
import {DocumentListSettings} from '../../shared/models/document-list-definition';
import {SYSTEM_COLUMNS} from '../../shared/utils/table-configs/common-system-column';
import {CERTIFICATE_COLUMNS} from "../../shared/utils/table-configs/certificate";


export const INVOICES_VIEW: DocumentListSettings = {
  trackByKey: 'id',
  hasSearch: true,
  selectable: true,
  hasPagination: true,
  viewNamespace: 'invoiceList',
  query: [],
  viewSettings: {
    'model': 'invoicing.InvoiceTable',
    'viewColumns': INVOICE_COLUMNS,
    'systemColumns': SYSTEM_COLUMNS,
    'defaultName': 'Liste des factures',
    topic:'invoice'
  },
  actions: [],
  isSubModule: true,
  resource: 'invoice',
  formRoute: '/invoicing/invoice/invoice-form/',
  formLabel: 'invoicing.invoice',
  deleteTopic: 'invoice.check_and_delete',
  removeLinearDelete:true,
  linearPrint:true,
};


export const QUOTATIONS_VIEW: DocumentListSettings = {
  trackByKey: 'id',
  hasSearch: true,
  selectable: true,
  hasPagination: true,
  viewNamespace: 'QUOTATIONS_List',
  query: [],
  viewSettings: {
    'model': 'invoicing.Quotation',
    'viewColumns': QUOTATION_COLUMNS,
    'systemColumns': SYSTEM_COLUMNS,
    'defaultName': 'Liste des devis'
  },
  actions: [],
  isSubModule: true,
  resource: 'quotation',
  formRoute: '/invoicing/quotation/quotation-form/',
  formLabel: 'invoicing.quotations',
  deleteTopic: 'quotation.check_and_delete',
  removeLinearDelete:true,
  linearPrint:true
};


export const FEES_NOTES_VIEW: DocumentListSettings = {
  trackByKey: 'id',
  hasSearch: true,
  selectable: true,
  hasPagination: true,
  viewNamespace: 'FEES_NOTES_List',
  query: [],
  viewSettings: {
    'model': 'invoicing.FeesNote',
    'viewColumns': FEES_NOTES_COLUMNS,
    'systemColumns': SYSTEM_COLUMNS,
    'defaultName': 'Liste des notes'
  },
  actions: [],
  isSubModule: true,
  resource: 'fees-note',
  formRoute: '/invoicing/fees-note/fees-note-form/',
  formLabel: 'invoicing.fees_note',
  deleteTopic: 'fees-note.check_and_delete',
  removeLinearDelete:true,
  linearPrint:true
};


export const CARE_SHEET_VIEW: DocumentListSettings = {
  trackByKey: 'id',
  hasSearch: true,
  selectable: true,
  hasPagination: true,
  viewNamespace: 'CARE_SHEET_List',
  query: [],
  viewSettings: {
    'model': 'invoicing.CareSheet',
    'viewColumns': CARE_SHEET_COLUMNS,
    'systemColumns': SYSTEM_COLUMNS,
    'defaultName': 'Liste des Mituelles'
  },
  actions: [],
  isSubModule: true,
  resource: 'care-sheet',
  formRoute: '/invoicing/care-sheet/care-sheet-form/',
  formLabel: 'invoicing.care-sheet',
  deleteTopic: 'care-sheet.check_and_delete',
  removeLinearDelete:true,
  linearPrint:true
};


export const CERTIFICATE_VIEW: DocumentListSettings = {
  trackByKey: 'id',
  hasSearch: true,
  selectable: true,
  hasPagination: true,
  viewNamespace: 'CERTIFICATE_List',
  query: [],
  viewSettings: {
    'model': 'invoicing.Certificate',
    'viewColumns': CERTIFICATE_COLUMNS,
    'systemColumns': SYSTEM_COLUMNS,
    'defaultName': 'Liste des Attestations'
  },
  actions: [],
  isSubModule: true,
  resource: 'certificate',
  formRoute: '/invoicing/certificate/certificate-form/',
  formLabel: 'invoicing.certificate',
  deleteTopic: 'certificate.check_and_delete',
  removeLinearDelete:true,
  linearPrint:true
};

