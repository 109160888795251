import * as moment from "moment";

export const MONTHS = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

export const   DEFAULT_FILTERS = [
  {
    label: 'shared.today', query: [
      {
        value: moment().format('YYYY-MM-DDT00:00:00.000') + 'Z',
        operator: '$eq',
        logical_operator: 'and',
        key: 'created_at',
        type: 'date'
      }
    ],
    checked: true
  },
  {
    label: 'shared.three_days', query: [
      {
        value: moment().format('YYYY-MM-DDT00:00:00.000') + 'Z',
        operator: '$lt',
        logical_operator: 'and',
        key: 'created_at',
        type: 'date'
      },
      {
        value: moment().subtract(4, 'days').format('YYYY-MM-DDT00:00:00.000') + 'Z',
        operator: '$gt',
        logical_operator: 'and',
        key: 'created_at',
        type: 'date'
      }
    ]
  },
  {
    label: 'shared.this_week', query: [
      {
        value: moment().format('YYYY-MM-DDT00:00:00.000') + 'Z',
        operator: '$lt',
        logical_operator: 'and',
        key: 'created_at',
        type: 'date'
      },
      {
        value: moment().subtract(1,'week').format('YYYY-MM-DDT00:00:00.000') + 'Z',
        operator: '$gt',
        logical_operator: 'and',
        key: 'created_at',
        type: 'date'
      }
    ]
  },
  {
    label: 'shared.this_month', query: [
      {
        value: moment().format('YYYY-MM-DDT00:00:00.000') + 'Z',
        operator: '$lt',
        logical_operator: 'and',
        key: 'created_at',
        type: 'date'
      },
      {
        value: moment().subtract(1,'month').format('YYYY-MM-DDT00:00:00.000') + 'Z',
        operator: '$gt',
        logical_operator: 'and',
        key: 'created_at',
        type: 'date'
      }
    ]
  },
  {
    label: 'shared.all', query: [
      {
        value: false,
        operator: '$eq',
        logical_operator: 'and',
        key: 'is_deleted',
        type: 'boolean'
      }
    ]
  },
]
