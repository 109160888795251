import {Component, Inject, OnInit} from '@angular/core';
import {BillingService} from '../../../shared/services/billing.service';
import {MAT_DIALOG_DATA,  MatDialogRef} from '@angular/material/dialog';
import * as _ from 'lodash';
import {FormControl, Validators} from '@angular/forms';
import {SharedService} from '../../services/shared-service';

@Component({
  selector: 'ftb-list-dialog',
  templateUrl: './list-dialog.component.html',
  styleUrls: ['./list-dialog.component.scss']
})
export class ListDialogComponent implements OnInit {

  currentModel = null;
  cls = null;
  label=null;

  constructor(private sharedService: SharedService, public dialogRef: MatDialogRef<ListDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    this.cls = _.get(this.data, 'cls');
    this.currentModel = _.get(this.data, 'model',{});
    this.label = _.get(this.data, 'label');


  }

  ngOnInit() {


  }

  save() {

    this.sharedService.handleList(this.currentModel, this.cls).subscribe(data => this.dialogRef.close(data));
  }


}
