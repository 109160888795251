<mat-toolbar color="primary">
  <div class="ft-module-icon">
    <button type="button" mat-icon-button  tabindex="-2">
      <mat-icon  *ngIf="!model?.id" fontSet="mdi" fontIcon="mdi-timetable"></mat-icon>
      <mat-icon *ngIf="model?.id" fontSet="mdi" fontIcon="mdi-file"></mat-icon>
    </button>
  </div>
  <h2>
    {{ !model?.id ? ('shared.new_exam' | translate) :  ('shared.exam_file' | translate) }}   {{model?.id ? " - " + model?.patient?.full_name:''}}
    {{national_id?.errors?.exists ||last_name?.errors?.exists ||first_name?.errors?.exists ? ' - ' +('shared.patient_already_exist' |translate)  : ''}}
  </h2>

  <span fxFlex></span>
  <button type="button" mat-icon-button mat-dialog-close  tabindex="-1">
    <mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
  </button>
</mat-toolbar>
<form novalidate #form="ngForm" >
  <div mat-dialog-content fxLayout="column"  fxLayoutGap="2px" class="ft-busy-container" style="min-height: 230px;">
   <div [ngBusy]="loader"></div>
   <div *ngIf="model?.paid_amount >0 && model?.payment_status  != 'unpaid'" fxLayoutAlign="center center" class="validate-icon">
   </div>
  <div fxLayout="row" fxLayoutGap="6px" >
        <ftb-select-list fxFlex  label="shared.title"  [(model)]="model.patient.title" [cls]="'Title'"></ftb-select-list>
        <mat-form-field fxFlex>
          <input matInput type="text" required
                  name="last_name"
                  placeholder="{{'pec.last_name'|translate}}"
                  [(ngModel)]="model.patient.last_name"
                   #last_name="ngModel"
                   ftCustomValidation="patient.check_uniqueness"
                   [query]="{field:'last_name',
                              pk:model?.patient?.id,
                              first_name:model?.patient?.first_name,
                              last_name:model?.patient?.last_name,
                              national_id:model?.patient?.national_id
                            }"
                 />
        </mat-form-field>
        <mat-form-field fxFlex>
          <input matInput [ftAutoFocus]="true"  type="text" required  name="first_name" placeholder="{{'pec.first_name'|translate}}"
                 [(ngModel)]="model.patient.first_name"
                  #first_name="ngModel"
                  ftCustomValidation="patient.check_uniqueness"
                  [query]="{field:'first_name',
                             pk:model?.patient?.id,
                             first_name:model?.patient?.first_name,
                             last_name:model?.patient?.last_name,
                             national_id:model?.patient?.national_id
                           }"
                 />
          <button  matSuffix mat-icon-button mat-raised-button type="button"  color="primary"
                  (click)="searchPatient()"
                   matBadge="1"
                   matBadgePosition="before"
                   matBadgeColor="warn"
                   matBadgeSize="small"
                   [matBadgeHidden]="!(national_id?.errors?.exists ||last_name?.errors?.exists ||first_name?.errors?.exists )">
            <mat-icon fontSet="mdi" fontIcon="mdi-magnify"></mat-icon>
          </button>
        </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutGap="24px" >
        <mat-form-field fxFlex="nogrow" >
            <input matInput ftDate [matDatepicker]="b_date" [placeholder]="'shared.birth_date'|translate"
                   [(ngModel)]="model.patient.birth_date" name="b_date"  >
            <mat-datepicker-toggle matSuffix [for]="b_date">
              <mat-icon matDatepickerToggleIcon fontSet="mdi" fontIcon="mdi-calendar-today"></mat-icon>
            </mat-datepicker-toggle>
            <mat-datepicker #b_date></mat-datepicker>
        </mat-form-field>
        <mat-form-field floatLabel="always" class="ft-radio-field">
          <input matInput placeholder="{{'shared.gender'|translate}}" style="display: none">
          <mat-radio-group [(ngModel)]="model.patient.gender" name="g_type" fxLayout="row" >
            <mat-radio-button *ngFor="let b_type of ['M','F']" [value]="b_type">
              {{b_type}}
            </mat-radio-button>
          </mat-radio-group>
        </mat-form-field>

    </div>
    <div fxLayout="row" fxLayoutGap="6px" >
        <ftb-select-list fxFlex  label="common.list.marital_status"  [(model)]="model.patient.marital_status" [cls]="'MaritalStatus'" ></ftb-select-list>

        <mat-form-field fxFlex  class="with-error-label">
          <input matInput type="text" name="national_id" placeholder="{{'patient.national_id'|translate}}"
            ftCustomValidation="patient.check_uniqueness"
            [query]="{field:'national_id',
                      pk:model?.patient?.id,
                       first_name:model?.patient?.first_name,
                       last_name:model?.patient?.last_name,
                       national_id:model?.patient?.national_id
                     }"
            name="national_id" #national_id="ngModel"
            [(ngModel)]="model.patient.national_id"/>
        </mat-form-field>
        <mat-form-field fxFlex>
          <input matInput type="text" name="phone_numbers" placeholder="{{'shared.phone_number'|translate}}"
                 [(ngModel)]="model.patient.contact_info.phone_numbers[0]" />
        </mat-form-field>
    </div>

    <hr class="ft-sep">
    <div fxLayout="row" style="z-index: 1001" fxLayoutGap="6px">
        <mat-form-field floatLabel="always" class="ft-radio-field" fxFlex="nogrow" fxFlex="50">
           <input matInput placeholder="{{'dailyWorkflow.debtor'|translate}}" style="display: none">
           <mat-radio-group [(ngModel)]="model.debtor" name="b_type" fxLayout="row"
                            (change)="onDebtorChange($event)">
             <mat-radio-button *ngFor="let b_type of ['PATIENT','INSURED_PATIENT','THIRD_PARTY_PAYMENT','PEC']" [value]="b_type">
               {{'shared.'+b_type|translate}}
             </mat-radio-button>
           </mat-radio-group>
         </mat-form-field>
    </div>
    <div fxLayout="row" style="z-index: 1001" fxLayoutGap="6px"  >
      <ftb-select-input   [disabled]="model.debtor== 'PATIENT' || model.debtor== 'PEC'" fxFlex="50"
                          [label]="'shared.organization'"
                          [(model)]="model.organization"
                          (modelChange)="handlePecFilter($event)"
                          value="name"
                          [settings]="organizationInputSettings" ></ftb-select-input>

        <mat-form-field fxFlex="50">
          <mat-select [placeholder]="'settings.pricing_list' | translate" [compareWith]="comparePricingCondition"
                      [(ngModel)]="model.financial_detail.pricing" name="mode"   [ngModelOptions]="{standalone:true}"  >
            <mat-option *ngFor="let p of pricingList" [value]="p">
              {{p.name}}
            </mat-option>
          </mat-select>
            <button *ngIf="model.financial_detail.pricing" matSuffix mat-icon-button type="button" color="warn" matTooltip="clean"
                    (click)="$event.stopImmediatePropagation(); model.financial_detail.pricing=null;">
              <mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
            </button>
        </mat-form-field>
        <ftb-select-input  *ngIf="model.debtor== 'THIRD_PARTY_PAYMENT'" fxFlex="50"  [label]="'invoicing.applied_tariff'"
                                       [(model)]="model.financial_detail.convention" value="label"
                                       [settings]="conventionInputSettings" ></ftb-select-input>
       <ftb-select-input  *ngIf="model.debtor== 'PEC'" fxFlex="50"  [label]="'pec.ref'" [disabled]="!model?.patient?.id"
                                       [(model)]="model.financial_detail.pec" value="agreement.ref"
                                       [settings]="pecInputSettings"
                                       (modelChange)="!(model?.paid_amount >0 && model?.payment_status  != 'unpaid') ? handlePecChange($event) : null"></ftb-select-input>
    </div>
    <hr class="ft-sep">
    <div fxLayout="row" fxLayoutGap="6px" >
        <mat-form-field fxFlex="15">
          <input matInput   name="global_discount_amount" [ftNumber]="'float'" placeholder="{{'shared.discount_amount'|translate}}"
                 [(ngModel)]="model.global_discount_amount" (change)="handleDiscount(true)" />
        </mat-form-field>
        <mat-form-field  fxFlex="15">
          <input matInput    name="global_discount" [ftNumber]="'float'" placeholder="{{'shared.discount_percent'|translate}}"
                 [(ngModel)]="model.global_discount" (change)="handleDiscount()"/>
        </mat-form-field>
    </div>
    <div fxFlex>
        <ftb-document-lines fxFlex
                          [(lines)]="model.procedures"
                          [model]="model"
                          [cls]="'billingRequest'"
                          [hasSearch]= "true"
                          [showDetailedAmounts]="true"
                          [pageSize]="5"
                          [readOnly]="model?.paid_amount >0 && model?.payment_status  != 'unpaid'"
                          [(convention)]="model.financial_detail.convention"
                          [(pricing)]="model.financial_detail.pricing"
                          >
      </ftb-document-lines>
    </div>
  </div>
  <ng-template  [ftAcl]="{resource: 'billing-request', action: 'create', behavior: 'remove'}">
    <div mat-dialog-actions  >
      <span fxFlex></span>
      <button type="button" *ngIf="model?.id && !model?.doc_id && model?.procedures?.length>0"
              class="success-button"   mat-raised-button   (click)="scheduleRequest()" [disabled]="form.invalid">
              {{'dailyWorkflow.schedule'|translate}}
      </button>
        <!--              *ngIf="!(model?.paid_amount >0 && model?.payment_status  != 'unpaid')"-->
      <button type="button" color="primary"
                mat-raised-button tabindex="2"   (click)="save()" [disabled]="form.invalid">
              {{'shared.save'|translate}}
      </button>
      <button type="button" [mat-dialog-close]="null"  type="button"  color="warn" mat-raised-button tabindex="-1">{{'shared.close'|translate}}</button>
    </div>
  </ng-template>
</form>

