<mat-toolbar color="primary">
  <div class="ft-module-icon">
    <button type="button" mat-icon-button tabindex="-2">
      <mat-icon fontSet="mdi" fontIcon="mdi-account-settings"></mat-icon>
    </button>
  </div>
    <h2>{{ 'patient.patient_file' | translate }} {{national_id?.errors?.exists ||last_name?.errors?.exists ||first_name?.errors?.exists ? ' - ' +('shared.patient_already_exist' |translate)  : ''}}</h2>

  <span fxFlex></span>
  <button type="button" mat-icon-button mat-dialog-close tabindex="-1">
    <mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
  </button>
</mat-toolbar>
<form novalidate #form="ngForm" >
  <div [ngBusy]="loader"></div>

  <div mat-dialog-content fxLayout="column" class="ft-busy-container" style="min-height: 230px;">
    <div fxLayout="row" fxLayoutGap="6px" >
        <ftb-select-list fxFlex  label="shared.title"  [(model)]="patient.title" [cls]="'Title'" (modelChange)="listChange($event)"></ftb-select-list>

        <mat-form-field fxFlex>
          <input matInput type="text" required name="last_name" placeholder="{{'pec.last_name'|translate}}" [ftAutoFocus]="true"
                 [(ngModel)]="patient.last_name"
                  #last_name="ngModel"
                  ftCustomValidation="patient.check_uniqueness"
                  [query]="{field:'last_name',
                             pk:patient?.id,
                             first_name:patient?.first_name,
                             last_name:patient?.last_name,
                             national_id:patient?.national_id
                           }"
                 />
        </mat-form-field>
        <mat-form-field fxFlex>
                    <input matInput  type="text" required  name="first_name" placeholder="{{'pec.first_name'|translate}}"
                           [(ngModel)]="patient.first_name"
                            #first_name="ngModel"
                           ftCustomValidation="patient.check_uniqueness"
                           [query]="{field:'first_name',
                                      pk:patient?.id,
                                      first_name:patient?.first_name,
                                      last_name:patient?.last_name,
                                      national_id:patient?.national_id }"
                           />
        </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutGap="6px" >
        <mat-form-field fxFlex="nogrow" >
            <input matInput ftDate [matDatepicker]="b_date" [placeholder]="'shared.birth_date'|translate"
                   [(ngModel)]="patient.birth_date" name="b_date">
            <mat-datepicker-toggle matSuffix [for]="b_date">
              <mat-icon matDatepickerToggleIcon fontSet="mdi" fontIcon="mdi-calendar-today"></mat-icon>
            </mat-datepicker-toggle>
            <mat-datepicker #b_date></mat-datepicker>
        </mat-form-field>
        <mat-form-field floatLabel="always" class="ft-radio-field">
          <input matInput placeholder="{{'shared.gender'|translate}}" style="display: none">
          <mat-radio-group [(ngModel)]="patient.gender" name="b_type" fxLayout="row" >
            <mat-radio-button *ngFor="let b_type of ['M','F']" [value]="b_type">
              {{b_type}}
            </mat-radio-button>
          </mat-radio-group>
        </mat-form-field>
    </div>
    <hr class="ft-sep">

    <div fxLayout="row" fxFlex fxLayoutGap="6px">
      <!--<ftb-select-input fxFlex="50" [label]="'shared.insurance'" [model]="patient?.medical_insurances[0]" [value]="'name'"
                                [settings]="organizationInputSettings"></ftb-select-input>-->
    </div>
    <div fxLayout="row" fxFlex fxLayoutGap="6px">
        <ftb-select-list fxFlex  label="common.list.marital_status"  [(model)]="patient.marital_status" [cls]="'MaritalStatus'"  ></ftb-select-list>
        <mat-form-field fxFlex  class="with-error-label">
          <input matInput type="text" name="national_id" placeholder="{{'patient.national_id'|translate}}"
            [(ngModel)]="patient.national_id"
            ftCustomValidation="patient.check_uniqueness"
            [query]="{field:'national_id',
                      pk:patient?.id,
                       first_name:patient?.first_name,
                       last_name:patient?.last_name,
                       national_id:patient?.national_id }"
            name="national_id" #national_id="ngModel"
            />
        <!--  <mat-error *ngIf="name?.errors?.exists" translate="shared.already_exist"></mat-error>-->
        </mat-form-field>
        <mat-form-field fxFlex>
          <input matInput type="text" name="phone_numbers" placeholder="{{'shared.phone_number'|translate}}"
                 [(ngModel)]="patient.contact_info.phone_numbers[0]" />
        </mat-form-field>

    </div>
     <div fxLayout="row" fxFlex fxLayoutGap="6px">
       <mat-form-field fxFlex>
              <textarea matInput [(ngModel)]="patient.address.content" name="address"
                                  [placeholder]="'shared.address'|translate"></textarea>
        </mat-form-field>
     </div>
  </div>
  <div mat-dialog-actions>

    <span fxFlex></span>
    <button type="button" color="primary" mat-raised-button tabindex="2"   (click)="save()" [disabled]="form.invalid">
      {{'shared.save'|translate}}
    </button>
    <button type="button" [mat-dialog-close]="null"  type="button"  color="warn" mat-raised-button tabindex="-1">{{'shared.reset'|translate}}</button>
  </div>
</form>

