<mat-toolbar color="primary">
  <div class="ft-module-icon">
    <mat-icon fontSet="mdi" fontIcon="mdi-medical-bag"></mat-icon>
  </div>
  <h2 mat-dialog-title>{{ 'settings.procedure' | translate }}</h2>
  <span fxFlex></span>

  <button type="button" mat-icon-button mat-dialog-close tabindex="-1">
    <mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
  </button>
</mat-toolbar>
<form novalidate #form="ngForm" (ftAsyncSubmit)="save()" autocomplete="false">
  <div mat-dialog-content fxLayout="column" class="ft-busy-container">
    <div fxLayout="row" fxLayoutGap="6px" >
      <mat-form-field fxFlex>
        <input required matInput
                        [(ngModel)]="procedure['code']"
                        name="code"
                        #code="ngModel"
                        ftCustomValidation="procedure.check_uniqueness"
                        [query]="{field:'code', pk:procedure?.id}"
                        [ftAutoFocus]="true"
                        [placeholder]="'settings.code'|translate">
        <mat-error *ngIf="code?.errors?.exists" translate="shared.already_exist"></mat-error>
      </mat-form-field>
      <mat-form-field fxFlex>
        <input required matInput [(ngModel)]="procedure['name']" name="name"
               [placeholder]="'settings.procedure_name'|translate">

      </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutGap="6px" >
      <mat-form-field fxFlex>
        <input required matInput [ftNumber]="'float'" [(ngModel)]="procedure['price']" name="price" step="0.1"
               [placeholder]="'settings.procedure_price'|translate"/>
      </mat-form-field>
      <mat-form-field fxFlex>
        <input matInput  [ftNumber]="'float'" [(ngModel)]="procedure['tnr']" name="tnr" step="0.1"
               [placeholder]="'settings.tnr'|translate"/>
      </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutGap="6px" >
      <mat-form-field fxFlex>
        <input matInput [(ngModel)]="procedure['NGAP_code']" name="NGAP_code" [placeholder]="'settings.ngap_code'|translate">

      </mat-form-field>
      <mat-form-field fxFlex>
        <input matInput [(ngModel)]="procedure['CCAM_code']" name="CCAM_code"
               [placeholder]="'settings.ccam_code'|translate">
      </mat-form-field>
      <mat-form-field fxFlex>
        <input matInput [(ngModel)]="procedure['accounting_code']" name="accounting_code"
               [placeholder]="'settings.accounting_code'|translate">
      </mat-form-field>
    </div>

  </div>
  <div mat-dialog-actions>
    <span fxFlex="2"></span>
    <mat-slide-toggle  [(ngModel)]="procedure['is_refundable']"  [checked]="procedure['is_refundable']" name="is_refundable" >
      {{'settings.is_refundable'|translate}}
    </mat-slide-toggle>

    <span fxFlex></span>
    <button type="button" color="primary" mat-raised-button tabindex="2"  type="submit" [disabled]="form.invalid">
      {{'shared.save'|translate}}
    </button>
    <button type="button" [mat-dialog-close]="null"  color="warn" mat-raised-button tabindex="-1">{{'shared.reset'|translate}}</button>
  </div>
</form>

