<div class="ft-parent-layout mat-elevation-z1 ft-content-margin" fxFlex fxLayout="column">
  <mat-toolbar color="accent">
    <div class="ft-module-icon">
      <button type="button" mat-icon-button>
        <mat-icon fontSet="mdi" fontIcon="mdi-folder-open"></mat-icon>
      </button>
    </div>
    <h2>{{ 'settings.procedures_catalogues' | translate }}</h2>
    <span fxFlex></span>
    <button type="button" mat-button class="with-icon" [disabled]="!catalog.id" (click)=" initCatalog()">
      <mat-icon fontSet="mdi" fontIcon="mdi-plus"></mat-icon>
      <span>{{ 'shared.new' | translate }}</span>
    </button>
  </mat-toolbar>
  <div fxFlex fxLayout="column" class="ft-content no-padding">
    <mat-drawer-container class="ft-content-container" fxFlex>
      <mat-drawer mode="side" [opened]="true">
        <mat-toolbar color="primary">
          <div class="ft-module-icon">
            <mat-icon fontSet="mdi" fontIcon="mdi-format-list-bulleted"></mat-icon>
          </div>
          <h2 translate="settings.catalogues"></h2>
        </mat-toolbar>
        <mat-action-list role="list" class="ft-content">
          <div *ngIf="!catalogs || catalogs.length == 0" class="ft-empty-content" >
            <mat-icon fontSet="mdi" fontIcon="mdi-alert-circle-outline"></mat-icon>
            <span translate="common.no_element_to_show"></span>
          </div>
          <mat-list-item role="listitem" *ngFor="let c of catalogs">
            <button type="button" mat-line (click)="showCatalog(c)" [disabled]="c == catalog">
              <mat-icon fxFlex="noshrink" fontSet="mdi" fontIcon="mdi-file-document"></mat-icon>
              <span fxFlex>{{c.name}}</span>
              <mat-icon fxFlex="noshrink"
                        class="ft-success"
                        [@heightAnimation] *ngIf="catalog.id==c.id" fontSet="mdi"
                        fontIcon="mdi-pencil-box-outline"></mat-icon>
            </button>
          </mat-list-item>
        </mat-action-list>
      </mat-drawer>
      <mat-drawer-content fxLayout="column">
        <form #catalogForm="ngForm" fxLayout="column" fxFlex class="ft-padding" fxLayoutGap="6px">
          <div fxLayout="row">
                <mat-form-field fxFlex="50"  fxLayout="column"   class="with-error-label">
                  <input  matInput [(ngModel)]="catalog.name" ftCustomValidation="procedure-catalog.check_uniqueness"
                         [query]="{field:'name', pk:catalog?.id}" required name="name" #name="ngModel"
                         [placeholder]="'shared.name'|translate">
                  <mat-error *ngIf="name?.errors?.exists" translate="shared.already_exist"></mat-error>
                </mat-form-field>
          </div>
          <ft-generic-body-table fxFlex
                                 [remote]="false"
                                 [source]="linesSource$"
                                 [selectable]="false"
                                 [hasPagination]="true"
                                 [columns]="linesColumns | async"
                                 [multiActions]="[]"
          >
          </ft-generic-body-table>
        </form>
        <div class="ft-actions">
          <ftb-delete-button *ngIf="catalog?.id" [model]="catalog"
                             [deleteTopic]="'procedure-catalog.check_and_delete'"
                             [afterDelete]="initCatalog"></ftb-delete-button>
          <span fxFlex></span>
          <button type="button" mat-raised-button color="primary" class="has-icon" (click)="addProcedure()">
            <mat-icon fontSet="mdi" fontIcon="mdi-medical-bag"></mat-icon>
            <span>{{'settings.add_procedure'|translate}}</span>
          </button>
          <button type="button" [disabled]="catalogForm.invalid" mat-raised-button color="primary" (click)="save()">
            <span>{{'shared.save'|translate}}</span>
          </button>
        </div>
      </mat-drawer-content>
    </mat-drawer-container>
  </div>
</div>
