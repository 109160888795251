import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {SelectDocumentsDialogComponent} from '../../dialogs/select-documents-dialog/select-documents-dialog.component';
import {LARGE_DIALOG, MEDIUM_DIALOG} from '@ft/core';
import {MatDialog} from "@angular/material/dialog";

import * as _ from 'lodash';
import {BehaviorSubject} from 'rxjs';

@Component({
  selector: 'ftb-select-input',
  templateUrl: './select-input.component.html',
  styleUrls: ['./select-input.component.scss']
})
export class SelectInputComponent implements OnInit {
  currentModel = null;

  @Input() settings;
  @Input('required') is_required ? = false;

  @Input() label;
  @Input() value: string;
  @Input('dialog') addDialog;

  private _disabled = new BehaviorSubject(null);

  _model = null;


  @Input()
  set model(value) {
    this._model = value;
    this.currentModel = _.get(this._model, this.value);
  }

  @Output() modelChange = new EventEmitter();


  @Input('disabled') set is_disabled(value: any[]) {
    this._disabled.next(value);
  }

  get is_disabled() {
    return this._disabled.getValue();
  }

  constructor(private dialog: MatDialog) {
  }

  ngOnInit() {
  }

  handleChange() {
    this.modelChange.emit(this._model);
  }

  showDialog(is_new = false) {
    if (is_new) {
      this.dialog.open(this.addDialog, _.assign(MEDIUM_DIALOG, {
        data: null,
        disableClose: true
      })).afterClosed().subscribe(res => {
        if (res) {
          this._model = res;
          this.modelChange.emit(_.cloneDeep(this._model));
          this.currentModel = _.get(this._model, this.value);
        }
      });
    }
    else {
      this.dialog.open(SelectDocumentsDialogComponent, _.assign(LARGE_DIALOG, {
        data: this.settings,
        disableClose: true
      })).afterClosed().subscribe(res => {
        if (res) {
          this._model = res;
          this.modelChange.emit(_.cloneDeep(this._model));
          this.currentModel = _.get(this._model, this.value);
        }
      });
    }
  }

  reset() {
    this._model = null;
    this.currentModel = null;
    this.modelChange.emit(null);
  }
}
