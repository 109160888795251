export const lang = {
  'states_list': 'Reports list',
  states: 'Reports',
  daily_activities: 'Daily activity',
  personalized_states: 'Custom reports',
  checkout: 'Checkout',
  values: 'Values',
  value: 'value',
  paid: 'paid',
  invoiced: 'Invoice',
  remaining: 'Unpaid',
  patients: 'Patients',
  treasury: 'Cash',
  'daily_immediate_payments': 'Immediate payments',
  nbr: 'Nbr',
  amount: 'Amount',
  anterior_payments: 'Anterior payments',
  general_activity: 'General activity',
  daily_activity: 'Daily activity',
  visits: 'Exams count',
  taxed_amount: 'Amount',
  paid_amount: 'Received amount',
  remaining_amount: 'Remains to be paid',
  daily_paid_amount: 'Daily paid amount',
  anterior_paid_amount: 'Anterior paid amount',
  full_paid_amount: 'Full paid amount',
  patient_payments: 'Patient payments',
  organization_payments: 'Third part payments',
  care_requests_state: 'Care requests status',
  patient_fullname: 'Patient',
  exams: 'Exam',
  exams_date: 'Exam date',
  send_date: 'Sending date',
  payment_date: 'Settlement date',
  "aging_balance": 'Aged balance',
  "customer_recovery": 'Recovery report',
  "b_15": "+15j",
  "b_30": "+30j",
  "b_60": "+60j",
  "b_120": "+120j",
  "m_15": "-15j",
  "m_30": "-30j",
  "m_60": "-60j",
  "m_120": "-120j",
  daily_invoices: 'Daily activity',
  paid_total: 'Paid Total',
  show_percents: 'Percentages Center/Doctor',
  center_percent: 'Center %',
  physician_percent: 'Doctor %',
  base_percent: 'Base %',
  received_amount: 'Received amount',
  anterior_activity: 'Anterior activity',
  doc_number: 'Exam N°',
  periodic_activity: 'Periodic activity',
  taxed_amount_sum: 'Total taxed amount',
  paid_amount_sum: 'Total paid amount',
  exams_count: 'Exams count',
  has_invoice: "Billed",
  total_has_invoice: 'Billed exams',
  remaining_amount_sum: 'Total remains',
  daily_state: 'Daily report'
}
