<div fxFlex class="ft-padding" fxLayout="column">
  <form fxFlex fxLayout="column" fxLayoutGap="6px">
    <div fxLayout="row" fxLayoutGap="6px">

      <ftb-select-input fxFlex="30" [label]="'shared.organization'" [(model)]="filter.organization" value="full_name"
                        [settings]="organizationInputSettings"
                        (modelChange)="onOrganizationChange($event)"></ftb-select-input>

      <mat-form-field fxFlex="nogrow">
        <input matInput ftDate [matDatepicker]="from_date" [placeholder]="'shared.from_date'|translate"
               [(ngModel)]="filter.from_date" name="from_date" (ngModelChange)="handleFilterChange($event)">
        <mat-datepicker-toggle matSuffix [for]="from_date">
          <mat-icon matDatepickerToggleIcon fontSet="mdi" fontIcon="mdi-calendar-today"></mat-icon>
        </mat-datepicker-toggle>
        <mat-datepicker #from_date></mat-datepicker>
      </mat-form-field>

      <mat-form-field fxFlex="nogrow">
        <input matInput ftDate [matDatepicker]="to_date" [placeholder]="'shared.to_date'|translate"
               [(ngModel)]="filter.to_date" name="to_date" (change)="handleFilterChange($event)">
        <mat-datepicker-toggle matSuffix [for]="to_date">
          <mat-icon matDatepickerToggleIcon fontSet="mdi" fontIcon="mdi-calendar-today"></mat-icon>
        </mat-datepicker-toggle>
        <mat-datepicker #to_date></mat-datepicker>
      </mat-form-field>
    </div>
    <div [ngBusy]="busy" >
      <ft-generic-body-table *ngIf="has_res" fxFlex
                             [remote]="false"
                             [hasSearch]="false"
                             [source]="linesSource$"
                             [selectable]="false"
                             [hasPagination]="true"
                             [columns]="linesColumns | async"
      >
      </ft-generic-body-table>
    </div>
  </form>
  <div  fxLayout="row" fxLayoutGap="6px">
    <button fxLayoutAlign="left bottom" type="button"  mat-mini-fab color="primary"
             [disabled]="!has_res"
            [ftPrint]="{group:'pec-state', view:'/api/billing/pec/', customView:'print-unique',  model:null, extraQuery:{data:formatFilter()},  unique:true}">
      <mat-icon fontSet="mdi" fontIcon="mdi-printer"></mat-icon>
    </button>

        <button fxLayoutAlign="left bottom" type="button"  mat-mini-fab color="primary"
              [disabled]="!has_res" [columns]="viewColumns"
              [ftbXlsExport]="{filter:formatFilter() ,topic:'pec.handle_requests_state_xls', fileName: 'states.care_requests_state'|translate}"
              >
          <mat-icon fontSet="mdi" fontIcon="mdi-file-excel"></mat-icon>
        </button>
  </div>
</div>
