import {DocumentListSettings} from '../../shared/models/document-list-definition';
import {BILLING_REQUEST_COLUMNS} from '../../shared/utils/table-configs/billing-request';
import * as moment from 'moment';
import {SYSTEM_COLUMNS} from '../../shared/utils/table-configs/common-system-column';

export const BILLING_REQUEST_VIEW: DocumentListSettings = {
  trackByKey: 'id',
  hasSearch: true,
  selectable: true,
  hasPagination: true,
  viewNamespace: 'BILLING_REQUEST_List',
  query: [
  ],
  viewSettings: {
    'model': 'request_flow.BillingRequestTable',
    'viewColumns': BILLING_REQUEST_COLUMNS,
    'systemColumns': SYSTEM_COLUMNS,
    'defaultName': 'Tous les flux'},
  actions: [],
  resource: 'billing-request',
  headerLabel: 'core.daily_workflow',
  headerIcon: 'mdi-calendar-today',
};
