export const lang = {
  generals: 'Paramètres généraux',
  app_config: 'Configuration de l\'application',
  organization_list: 'Organismes',
  organization: 'Organisme',
  search: 'Rechercher',
  procedures: 'Procedures',
  procedure: 'Procedures',
  procedure_name: 'Désignation',
  code: 'Code',
  procedure_price: 'Honoraire',
  is_refundable: 'Remboursable',
  tnr: 'TNR',
  ngap_code: 'Code NGAP',
  ccam_code: 'Code CCAM',
  procedure_save_success: 'Procedure enregistré!',
  organization_save_success: 'Organisme enregistré!',
  convention_save_success: 'Convention enregistré!',
  delete_object_warning: 'Vous êtes sur le point de supprimer cet objet. Êtes-vous sûr de vouloir continuer?',
  delete_object_failed: 'Impossible de supprimer ce document veuillez verifier ses dépendences!',
  delete_object_success: 'Objet supprimer avec succès',
  procedures_catalogues: 'Catalogues des procedures',
  sequence_numbers: 'Numéros de sequence',
  add_procedure: 'Ajouter procédure',
  conventions: 'Conventions',
  name: 'Nom',
  tp: 'Tiers-Payant',
  tm: 'Ticket modérateur',
  add_exception: 'Exception',
  convention_list: 'Liste des conventions',
  users_management: 'Gestion des utilisateurs',
  profiles: 'Gestion des profiles',
  list_setup: 'Gestion des listes',
  assistants: 'Assistantes',
  physicians: 'Medecins',
  catalogues: 'Catalogues',
  is_default: 'Par défaut',
  base_settings: 'Paramètres de base',
  general: 'Général',
  system: 'Système',
  exercise: 'Exercice',
  exercise_start: 'L’année fiscale démarre en',
  devices_shared_files: 'Appareils et Connexions',
  hl_seven: 'HL7',
  port: 'Port',
  start: 'Démarrer',
  stop: 'Arrêtez',
  restart: 'Redémarrer',
  hl_seven_server: 'Serveur HL7 setup',
  hl_seven_services: 'HL7 services',
  hl_seven_client: 'HL7 Client',
  host: 'Address IP',
  active: 'Activer',
  quotation_valid_duration: "Validité des devis",
  printing_setup: 'Templates d\'impression',
  set_default: 'Par défaut',
  import: 'Importer',
  import_exceptions: 'Importer des exceptions .csv \n code,TM(%),TP(%) \n les nombre doivent contient . non pas virgule',
  export_exceptions: 'Télécharger  les exceptions .xls',
  pricing_list: 'Tarification',
  tariffs: "Tarifs",
  default_pricing: "Tarif par défaut",
  invoice_payment_from_docs: "Paiement des factures lié au documents",
  accounting_code: "Code comptable",
  shared_code: "Code partagé",
  import_procedure_tooltip: 'fichier csv code,price,name,ngap_code,tnr,is_refundable(0|1)',
  postal_code: "Code postal",
  city: "Ville"
};
