<div mat-dialog-content class="ft-content">

  <div fxLayout="row" fxLayoutGap="6px">
    <p class="confirm-modal-text">{{message_text|translate}}</p>
  </div>

</div>

<mat-dialog-actions fxLayout="row">
  <span fxFlex></span>
  <button type="button" mat-button color="primary" [mat-dialog-close]="true" (click)="confirm()">
    <mat-icon fontSet="mdi" fontIcon="mdi-check"></mat-icon>
    <span>{{'shared.confirm' | translate}}</span>
  </button>
  <button type="button" mat-button color="warn" [mat-dialog-close]="null">
    <mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
    <span>{{'shared.reset' | translate}}</span>
  </button>
</mat-dialog-actions>
