<mat-toolbar color="primary">
  <div class="ft-module-icon">
    <mat-icon fontSet="mdi" fontIcon="mdi-format-list-bulleted"></mat-icon>
  </div>
  <h2 mat-dialog-title>{{ 'shared.invoice_select_details' | translate }}</h2>
  <span fxFlex></span>
  <button type="button" mat-icon-button mat-dialog-close tabindex="-2">
    <mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
  </button>
</mat-toolbar>
<mat-tab-group mat-dialog-content (selectedTabChange)="onTabChange($event)" class="no-padding"
               [selectedIndex]="getDefaultIndex()">
  <mat-tab fxLayout="row" *ngFor="let tab of tabsConfig" label="{{tab.label|translate}}" tabindex="-1"
           [disabled]="isDisableTab(tab.name)">
    <div class="ft-parent-layout">
      <div class="ft-content no-padding" fxFlex>
        <ft-generic-view-table fxFlex [namespace]="tab.tableSettings?.viewNamespace"
                               [viewSettings]="tab.tableSettings?.viewSettings"
                               [hasPagination]="tab.tableSettings?.hasPagination"
                               [selectable]="tab.tableSettings?.selectable"
                               [query]="tab.tableSettings?.query"
                               (selectionChange)="handleSelectionChange($event)"
                               [(selected)]="tab.selected"
        ></ft-generic-view-table>
      </div>
    </div>
  </mat-tab>
</mat-tab-group>
<div mat-dialog-actions>
  <span fxFlex></span>
  <button type="button" color="primary" mat-raised-button (click)="select()">
    {{'shared.save'|translate}}
  </button>
  <button type="button" [mat-dialog-close]="null" color="warn" mat-raised-button>{{'shared.reset'|translate}}</button>
</div>
