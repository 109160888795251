export const lang = {
  pec_header_number: 'Dossier N°',
  pec_folder: 'Dossier',
  pec_list: 'Liste des dossiers',
  pec_number: 'N°.Dossier',
  'PATIENT':"Patient",
  'PARTNER':"Conjoint",
  'CHILD':"Enfant",
  'PARENT':"Parent",
  affiliate_number:'N°.Affiliation',
  first_name:'Prénom',
  last_name:'Nom',
  national_id:'CIN',
  answers:'Réponses assurance',
  demande_detail:'Demande détails',
  answer_type:'Nature de réponses',
  new_answer:"nouvelle réponse",
  reject_reason:"motif de rejet",
  confirmed_amount:"Cotation accordée",
  ref:"Référence",
  'filed_application':'demande déposé',
  'agreement_subject':'accord sous réserve',
  'request_final_agreement_in_progress':'demande accord définitif en cours',
  'final_agreement_received':'accord definitif reçus',
  'request_for_further_information':'demande complément d\'information',
  'rejection':'Rejet',
  'unmanaged_folder':'dossier non géré',
  'imatr_number':'N°. Immatriculation',
  'same_patient':'Lui même',
  send_date:'Date d\'envoi'
};
