<!--<div fxLayout="column">-->
<!--<mat-toolbar color="accent">-->
<!--<h2></h2>-->
<!--<span fxFlex></span>-->
<!--</mat-toolbar>-->
<!--<div class="ft-content-container" fxLayout="column" fxFlex>-->
<!--<div fxLayout="row">-->

<!--</div>-->
<!--<div fxLayout="column">-->

<!--</div>-->
<!--</div>-->
<!--</div>-->

<!--<ng-container *ngFor="let item of reports;  let isLast = last" [@heightAnimation]>-->
<!--<h3 matSubheader color="primary">{{item.label | translate}}</h3>-->
<!--<ng-container *ngFor="let link of item.items">-->
<!--<ng-template [ftAcl]="link.acl" [ignoreEmpty]="true">-->
<!--<mat-list-item [class.ft-list-item-disabled]="!link.path" routerLinkActive="ft-list-item-active">-->
<!--<a matLine [routerLink]="link.path">-->
<!--{{link.label | translate}}-->
<!--</a>-->

<!--</mat-list-item>-->
<!--</ng-template>-->
<!--</ng-container>-->
<!--<mat-divider *ngIf="!isLast"></mat-divider>-->
<!--</ng-container>-->

<div fxFlex fxLayout="column">
  <div class="ft-parent-layout mat-elevation-z1" [class.ft-content-margin]="true" fxFlex fxLayout="column">
    <mat-toolbar color="accent">
      <div class="ft-module-icon">
        <button type="button" mat-icon-button>
          <mat-icon fontSet="mdi" fontIcon="mdi-chart-line-variant"></mat-icon>
        </button>
      </div>
      <h2>{{'states.states'|translate}}</h2>
    </mat-toolbar>
    <div fxFlex fxLayout="column" class="ft-content no-padding">
      <mat-drawer-container class="ft-content-container ft-state-container" fxFlex>
        <mat-drawer mode="side" [opened]="true">
          <!--<div class="side-nav-links">-->
            <mat-toolbar color="primary">
              <div class="ft-module-icon">
                <mat-icon fontSet="mdi" fontIcon="mdi-format-list-bulleted"></mat-icon>
              </div>
              <h2>{{'states.states_list'|translate}} </h2>
              <span fxFlex></span>
            </mat-toolbar>
            <mat-nav-list role="list">
              <div *ngIf="!reports || reports.length == 0" class="ft-empty-content" [@heightAnimation]>
                <mat-icon fontSet="mdi" fontIcon="mdi-alert-circle-outline"></mat-icon>
                <span>Aucun élément trouvé</span>
              </div>
              <ng-container *ngFor="let item of reports; trackBy:trackByFn; let isLast = last" [@heightAnimation]>
                <h4 matSubheader color="primary" [@heightAnimation]>{{item.label | translate}}</h4>
                <ng-container *ngFor="let link of item.items; trackBy:trackByFn">
                  <ng-template [ftAcl]="link.acl">
                    <mat-list-item [@heightAnimation] [class.ft-list-item-disabled]="!link.path"
                                   routerLinkActive="ft-list-item-active">
                      <a matLine [routerLink]="link.path"  >
                        {{link.label | translate}}
                      </a>
                    </mat-list-item>
                  </ng-template>
                </ng-container>
                <mat-divider *ngIf="!isLast"></mat-divider>
              </ng-container>
            </mat-nav-list>
          <!--</div>-->
        </mat-drawer>
        <mat-drawer-content fxLayout="column">
          <!--<div fxFlex class="ft-padding">-->
            <router-outlet></router-outlet>
          <!--</div>-->
        </mat-drawer-content>
      </mat-drawer-container>
    </div>
  </div>
</div>
