

export function pivotTabs(component) {
      return function () {
          const labels = this.Labels;
          return [
              {
                  title: labels.export_print,
                  id: "wdr-tab-export-print",
                  mobile: false,
                  rightGroup: true,
                  icon: "<md-icon class=\"mdi mdi-printer\"></md-icon>",
                  handler: () => {
                      if (!component.emptyData) this.printHandler();
                  }
              },
              {
                  title: labels.export,
                  id: "wdr-tab-export",
                  mobile: false,
                  rightGroup: true,
                  icon: "<md-icon class=\"mdi mdi-database-export\"></md-icon>",
                  menu: [
                      {
                          title: labels.export_excel,
                          id: "wdr-tab-export-excel",
                          handler: type => {
                              if (!component.emptyData) component.exportHandler(type);
                          },
                          args: "excel",
                          icon: "<md-icon class=\"mdi mdi-file-excel-outline\"></md-icon>"
                      },
                      {
                          title: labels.export_pdf,
                          id: "wdr-tab-export-pdf",
                          handler: type => {
                              if (!component.emptyData) component.exportHandler(type);
                          },
                          args: "pdf",
                          icon: "<md-icon class=\"mdi mdi-file-pdf-outline\"></md-icon>"
                      },
                  ]
              },
              {
                  title: labels.format,
                  id: "wdr-tab-format",
                  icon: "<md-icon class=\"mdi mdi-table-edit\"></md-icon>",
                  rightGroup: true,
                  menu: [
                      {
                          title: this.osUtils.isMobile ? labels.format_cells_mobile : labels.format_cells,
                          id: "wdr-tab-format-cells",
                          handler: () => {
                              if (!component.emptyData) this.formatCellsHandler()
                          },
                          icon: "<md-icon class=\"mdi mdi-format-letter-matches\"></md-icon>"
                      },
                      {
                          title: this.osUtils.isMobile ? labels.conditional_formatting_mobile : labels.conditional_formatting,
                          id: "wdr-tab-format-conditional",
                          handler: () => {
                              if (!component.emptyData) this.conditionalFormattingHandler()
                          },
                          icon: "<md-icon class=\"mdi mdi-format-color-highlight\"></md-icon>"
                      }
                  ]
              },
              {
                  title: labels.fields,
                  id: "wdr-tab-fields",
                  handler: () => {
                      if (!component.emptyData) this.fieldsHandler();
                  },
                  icon: "<md-icon class=\"mdi mdi-table-settings\"></md-icon>",
                  rightGroup: true
              },
              {
                  title: labels.options,
                  id: "wdr-tab-options",
                  handler: () => {
                      if (!component.emptyData) this.optionsHandler()
                  },
                  icon: "<md-icon class=\"mdi mdi-cog\"></md-icon>",
                  rightGroup: true
              }
          ];
      };
  }
