import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {SharedService} from '../../services/shared-service';
import {SelectDocumentsDialogComponent} from '../../dialogs/select-documents-dialog/select-documents-dialog.component';
import { SMALL_DIALOG} from '@ft/core';
import {MatDialog} from "@angular/material/dialog";
import {ListDialogComponent} from '../../dialogs/list-dialog/list-dialog.component';
import * as _ from 'lodash';

@Component({
  selector: 'ftb-select-list',
  templateUrl: './select-list.component.html',
  styleUrls: ['./select-list.component.scss']
})
export class SelectListComponent implements OnInit {
  private _currentModel = new BehaviorSubject(null);
  @Input() label ? = '';
  @Input() cls ? = '';
  @Input('required') is_required ? = false;
  @Input('disabled') is_disabled ? = false;

  @Input('model') set currentModel(value: any[]) {
    this._currentModel.next(value);
  }

  get currentModel() {
    return this._currentModel.getValue();
  }
  @Output() modelChange= new EventEmitter() ;

  data = [];

  constructor(private   sharedService: SharedService, private dialog:MatDialog) {
  }

  ngOnInit() {
    if (this.cls) {
      this.sharedService.list(this.cls).subscribe(res => this.data = res);
    }
  }


  compareObj(o1,o2) {
    return o1 && o2 ? o1.id === o2.id: o1 == o2;
  }

  changeModel() {
    this.modelChange.emit(this.currentModel);
  }

  addObj() {
    this.dialog.open(ListDialogComponent, _.assign(SMALL_DIALOG, {
      data: {
        currentModel: null,
        cls: this.cls,
        label: this.label,
      }
    })).afterClosed().subscribe(res => {
      if (res) {
        this.data.push(res);
        this.currentModel=res;
        this.modelChange.emit(this.currentModel);
      }
    });

  }
}
