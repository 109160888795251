import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {HEIGHT_ANIMATION, LARGE_DIALOG, SCALE_ANIMATION, SMALL_DIALOG} from '@ft/core';
import {MatDialog} from "@angular/material/dialog";
import * as _ from 'lodash';

import {BillingService} from '../../../shared/services/billing.service';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {AlertDialogComponent} from '../../dialogs/alert-dialog/alert-dialog.component';
import {SelectDocumentsDialogComponent} from '../../../shared/dialogs/select-documents-dialog/select-documents-dialog.component';
import {PROCEDURE_VIEW} from '../../../shared/utils/views-settings';
import {TableColumnDefinition} from '@ft/table';
import {TranslateService} from '@ngx-translate/core';

const LINES_COLUMNS: TableColumnDefinition[] = [
  {
    'label': 'shared.name',
    'key': 'name',
    'type': 'text'
  },
  {
    'label': 'settings.code',
    'key': 'code',
    'type': 'text'
  },
  {
    'label': 'settings.procedure_price',
    'key': 'price',
    'type': 'number',
    editable:true,
    numberConfig:'float'
  },
  {
    'label': 'settings.is_refundable',
    'key': 'is_refundable',
    'type': 'boolean',
    'icon':'mdi-currency-usd'
    // 'class': (row) => {
    //   return _.get(row, 'is_refundable') ? 'ft-success' : 'mat-warn';
    // }
  }
];

@Component({
  selector: 'ftb-catalogue-list',
  templateUrl: './catalogue-list.component.html',
  animations: [
    SCALE_ANIMATION,
    HEIGHT_ANIMATION
  ],
  styleUrls: ['./catalogue-list.component.scss']
})
export class CatalogueListComponent implements OnInit {
  @Output() linesChange = new EventEmitter();
  tableLines = new BehaviorSubject([]);
  tableDataChange = this.tableLines.asObservable();
  catalog: any = {procedures: []};

  catalogs: any = [];

  cls = 'procedures-catalog';

  linesSource$: Observable<any[]> = of([]);


  actionColumn: TableColumnDefinition = {
    label: '', key: 'actions', type: 'actions', actions: [
      {
        class: 'mat-warn',
        icon: 'mdi-close-circle',
        method: (item, index, ev) => {
          return this.removeProcedure(item, index);
        },


        // acl: {resource: 'profile', action: 'get', behavior: 'remove'}
      }
    ]
  };
  linesColumns = new BehaviorSubject<TableColumnDefinition[]>(_.concat(_.cloneDeep(LINES_COLUMNS), this.actionColumn));
  linesActions = [
    {
      class: 'mat-primary',
      icon: 'mdi-plus',
      isMultipleSelection: false,
      method: (item, ev) => {
        return this.addProcedure();
      },
      tooltip: this.translateService.instant('shared.add_line')
    }
  ];
  // linesSource$
  //  linesActions
  constructor(private billingService: BillingService, protected dialog: MatDialog, private activatedRoute: ActivatedRoute, private router: Router, private translateService: TranslateService) {

  }

  ngOnInit() {
    this.catalog.name = '';

    this.activatedRoute.params.subscribe((params) => this.initData(params));
  }


  initData(params) {
    this.billingService.getBillingDocs(this.cls)
      .subscribe(data => {
        if (data) {
          this.catalogs = data;
        }
      });

    if (_.get(params, 'id')) {
      this.billingService.getBillingDoc(this.cls, _.get(params, 'id'))
        .subscribe(data => {
          this.catalog = data;
          this.linesSource$ = of(this.catalog['procedures']);
          this.tableLines.next(this.catalog['procedures']);
        });
    }
  }

  onLinesChange(data) {
    this.catalog['procedures'] = data;
  }

  addProcedure() {

    this.dialog.open(SelectDocumentsDialogComponent, _.assign(LARGE_DIALOG, {
      data: PROCEDURE_VIEW
    })).afterClosed().subscribe(res => {
      if (res) {
        this.catalog['procedures'] = _.uniqBy(_.concat(this.catalog['procedures'], res), 'code');
        this.linesSource$ = of(this.catalog['procedures']);

        this.tableLines.next(this.catalog['procedures']);
      }
    });
  }

  save() {
    this.billingService.saveBillingDoc(this.catalog, this.cls).subscribe(data => {
      this.catalog = data;
      this.router.navigate(['/settings/procedures-catalogues-list', this.catalog['id']], {replaceUrl: false});
    });

  }

  showCatalog(c) {
    this.catalog = c;
    this.linesSource$ = of(this.catalog['procedures']);

    // this.router.navigate(['/settings/procedures-catalogues-list', this.catalog['id']], {replaceUrl: false});

  }

  removeProcedure(item, index) {
    this.catalog.procedures.splice(index, 1);
    this.linesSource$ = of(this.catalog.procedures);
  }


  initCatalog() {
    this.router.navigate(['/settings/procedures-catalogues-list'], {replaceUrl: false});
  }
}
