<div fxFlex class="ft-padding" fxLayout="column">
<form  fxFlex fxLayout="column" fxLayoutGap="6px">
  <div fxLayout="row" fxLayoutGap="6px">
    <mat-form-field fxFlex="15">
      <input matInput ftDate [matDatepicker]="from_date" [placeholder]="'shared.from_date'|translate"
             [(ngModel)]="filter.from_date" name="from_date" (ngModelChange)="handleFilterChange($event)">
      <mat-datepicker-toggle matSuffix [for]="from_date">
        <mat-icon matDatepickerToggleIcon fontSet="mdi" fontIcon="mdi-calendar-today"></mat-icon>
      </mat-datepicker-toggle>
      <mat-datepicker #from_date></mat-datepicker>
    </mat-form-field>
  </div>
  <div [ngBusy]="busy">
    <ft-generic-body-table  fxFlex
                           [remote]="false"
                           [hasSearch]="false"
                           [hasFooter]="has_res"
                           [source]="linesSource$"
                           [selectable]="false"
                           [hasPagination]="true"
                           [columns]="linesColumns | async"
    >
    </ft-generic-body-table>
  </div>
</form>
  <div fxLayout="row" fxLayoutGap="6px">

    <button fxLayoutAlign="left bottom" type="button"  mat-mini-fab color="primary"
          [disabled]="!has_res" [columns]="viewColumns"
          [ftbXlsExport]="{filter:formatFilter() ,topic:'payment.handle_payments_state_xls', fileName: 'states.anterior_activity'|translate}"
          >
      <mat-icon fontSet="mdi" fontIcon="mdi-file-excel"></mat-icon>
    </button>
  </div>
</div>

