import {TableColumnDefinition} from '@ft/table/table/models/table-definitions';

export const PHYSICIAN_COLUMNS: TableColumnDefinition[] = [
  {
    'label': 'shared.first_name',
    'key': 'first_name',
    'sortBy': 'first_name',
    'type': 'text',
    'is_global_searchable': true
  }, {
    'label': 'shared.last_name',
    'key': 'last_name',
    'sortBy': 'last_name',
    'type': 'text',
    'is_global_searchable': true
  }
];
