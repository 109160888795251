import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {getTopic, getUrl} from '../utils/functions';
import * as _ from 'lodash';
import {FtWsService} from '@ft/core';
import {zip} from "rxjs";


@Injectable({
  providedIn: 'root'
})
export class BillingService {

  constructor(private http: HttpClient, private ws: FtWsService) {

  }

  loadDocument(cls, loadedObject) {
    return this.ws.call(`${ getTopic(cls)}.load_document`, {pk: loadedObject});
  }


  saveBillingDoc(model, cls, loadedObject?) {
    let pk, data;
    pk = loadedObject ? loadedObject : model.id;
    data = !_.get(model, 'id') && loadedObject ? _.assignIn(model, {'id': loadedObject}) : model;
    return this.http.post(`${ getUrl(cls)}${pk ? pk + '/' : ''}`, data);
  }

  getBillingDoc(cls, id) {
    return this.http.get(`${ getUrl(cls)}${id}/`);
  }

  getBillingDocs(cls) {
    return this.http.get(`${ getUrl(cls)}`);
  }

  allowInvalidate(_pk, cls) {
    return this.ws.call(`${ getTopic(cls)}.allow_invalidate`, {cls: cls, pk: _pk});
  }

  invalidateBillingDoc(_pk, cls) {
    return this.ws.call(`${ getTopic(cls)}.invalidate`, {cls: cls, pk: _pk});
  }

  validateBillingDoc(model, cls) {
    return this.ws.call(`${ getTopic(cls)}.validate`, {cls: cls, model: model});
  }

  deleteBillingDoc(cls, id) {
    return this.http.delete(`${ getUrl(cls)}${id}/`);
  }

  removeBillingDoc(query) {
    return this.ws.call(query.topic, {_pk: query._pk});
  }

  handleNonBilledLines(docs){
    return zip(..._.map(docs,(doc)=>this.ws.call(`${doc['cls']}.get_non_billed_lines` , {pk:doc['pk']})));
  }
}
