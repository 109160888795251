import {Component, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import * as moment from 'moment';
import {ActivatedRoute} from '@angular/router';
import {BillingService} from '../../../shared/services/billing.service';
import * as _ from 'lodash';
import {PATIENTS_VIEW} from '../../../patient/utils/views-settings';
import {ORGANIZATION_VIEW} from '../../../settings/utils/views-settings';
import {PatientDialogComponent} from "../../../patient/dialogs/patient-dialog/patient-dialog.component";
import {getLineAmount} from "../../../shared/utils/functions";

@Component({
  selector: 'ftb-certificate',
  templateUrl: './certificate.component.html',
  styleUrls: ['./certificate.component.scss']
})
export class CertificateComponent implements OnInit {
  cls = 'certificate';
  loading: Subscription;
  certificate: any = {
    number: null,
    tariff: null,
    doc_date: moment(),
    patient: null,
    insurance: null,
    lines: [],
    insured_details: {
      insured_type: 'PATIENT',
      affiliate_number: null,
      title: null,
      first_name: null,
      last_name: null,
      birth_date: null,
      national_id: null,
      address: null
    }
  };
  loadedObject = null;

  routes = {list: '/invoicing/certificate/', form: '/invoicing/certificate/certificate-form/'};
  patientInputSettings = _.assignIn(_.cloneDeep(PATIENTS_VIEW), {selectable: false});
  organizationInputSettings = _.assignIn(_.cloneDeep(ORGANIZATION_VIEW), {selectable: false});
  patientDialog = PatientDialogComponent;

  onPatientChange = (data) => {
    this.certificate.patient = data;
    console.log(data)
    if (!this.certificate.insurance) {
      this.certificate.insurance = _.chain(this.certificate.patient?.medical_insurances).find({is_default: true}).get('insurance',_.get(this.certificate.patient, 'medical_insurances.0.insurance')).value();
      // this.certificate.insurance = _.get(this.certificate.patient, 'medical_insurances.0.insurance');
    }
  };
  onOrganizationChange = (data) => {
    this.certificate.insurance = data;
  };

  constructor(private route: ActivatedRoute, private billingService: BillingService) {
  }

  ngOnInit() {
    this.route.params.subscribe(params => this.initData(params));
  }

  initData(params) {
    if (_.get(params, 'id')) {
      this.loading = this.billingService.getBillingDoc(this.cls, _.get(params, 'id'))
        .subscribe(data => {
          this.certificate = data;
        });
    }
  }

  onModelChange(data) {
    this.certificate = data;
  }

  handleLinesQuery() {
    return {
      cls: 'Certificate',
      'patientVisits': this.certificate.patient ? [
        {key: 'patient', logical_operator: 'or', operator: '$eq', value: _.get(this.certificate?.patient, 'id')},
      ] : []
    }
  }

  getTotal() {
    return this.certificate.taxed_amount = !this.certificate.is_valid ? _.chain(this.certificate).get('lines').sumBy('taxed_amount') : this.certificate.taxed_amount;
  }

  getTmAmount() {
    return this.certificate.tm_amount = !this.certificate.is_valid ? _.chain(this.certificate).get('lines').sumBy('tm_amount') : this.certificate.tm_amount;
  }

  getTpAmount() {
    return this.certificate.tp_amount = !this.certificate.is_valid ? _.chain(this.certificate).get('lines').sumBy('tp_amount') : this.certificate.tp_amount;
  }
}
