import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FtCoreModule, MaterialModule} from '@ft/core';
import {TranslateModule} from '@ngx-translate/core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {FtTableModule} from '@ft/table';
import {NgBusyModule} from 'ng-busy';
import {FtbSharedModule} from '../shared/shared.module';
import {MainComponent} from './components/main/main.component';
import {PaymentFormComponent} from './components/payment-form/payment-form.component';
import {FtbPaymentRoutingModule} from './payment-routing.module';
import {PaymentLinesComponent} from './components/payment-lines/payment-lines.component';
import {PaymentService} from './services/payment.service';
import {PaymentActionsComponent} from './components/payment-actions/payment-actions.component';
import { PaymentListComponent } from './components/payment-list/payment-list.component';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    TranslateModule.forChild(),
    FtbSharedModule,
    FtCoreModule,
    FtTableModule,
    NgBusyModule,
    FtbPaymentRoutingModule
  ],
  declarations: [
    MainComponent,
    PaymentFormComponent,
    PaymentLinesComponent,
    PaymentActionsComponent,
    PaymentListComponent
  ],
  providers: [PaymentService]
})
export class FtbPaymentModule {
}
