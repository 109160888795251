<mat-toolbar color="primary">
  <div class="ft-module-icon">
    <mat-icon fontSet="mdi" fontIcon="mdi-ip-network-outline"></mat-icon>
  </div>
  <h2 mat-dialog-title>{{ 'settings.hl_seven_client' | translate }}</h2>
  <span fxFlex></span>
  <mat-slide-toggle  [(ngModel)]="hlSevenClient['active']"  [checked]="hlSevenClient['active']" name="active" >
    {{'settings.active'|translate}}
  </mat-slide-toggle>
  <button type="button" mat-icon-button mat-dialog-close tabindex="-1">
    <mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
  </button>
</mat-toolbar>
<form novalidate #form="ngForm"  autocomplete="false">
  <div mat-dialog-content fxLayout="column"  >
    <div fxLayout="row" fxLayoutGap="6px" >
      <mat-form-field fxFlex>
        <!-- we can change oly port there is one hl7 communication for that moment use hl7_Client as name -->
        <input required matInput [(ngModel)]="hlSevenClient['title']" name="title" [ftAutoFocus]="true" [placeholder]="'shared.title'|translate">
      </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutGap="6px" >
      <mat-form-field fxFlex>
        <input required matInput [(ngModel)]="hlSevenClient['host']" name="host"
               [placeholder]="'settings.host'|translate">

      </mat-form-field>
      <mat-form-field fxFlex>
        <input required matInput type="number"  [(ngModel)]="hlSevenClient['port']" name="port"
               [placeholder]="'settings.port'|translate"/>
      </mat-form-field>
    </div>
  </div>
  <div mat-dialog-actions>
    <span fxFlex></span>
    <button type="button" color="primary" mat-raised-button tabindex="2"  (click)="save()" [disabled]="form.invalid">
      {{'shared.save'|translate}}
    </button>
    <button type="button" [mat-dialog-close]="null"  color="warn" mat-raised-button tabindex="-1">{{'shared.reset'|translate}}</button>
  </div>
</form>
