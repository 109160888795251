export const lang = {
  pec_header_number: 'Folder N°',
  pec_folder: 'Folder',
  pec_list: 'Folders List',
  pec_number: 'Folder N°',
  'PATIENT': 'Patient',
  'PARTNER': 'Joint',
  'CHILD': 'Child',
  'PARENT': 'Parent',
  affiliate_number: 'No.Affiliation',
  first_name: 'First name',
  last_name: 'Name',
  national_id: 'NIC',
  answers: 'insurance Answers',
  demande_detail: 'Request Details',
  answer_type: 'Answers nature',
  new_answer: 'new answer',
  reject_reason: 'Rejection reason',
  confirmed_amount: 'Granted quotation',
  ref: 'Reference',
  'filed_application': 'Application submitted',
  'agreement_subject': 'subject to agreement',
  'request_final_agreement_in_progress': 'final agreement request in progress',
  'final_agreement_received': 'final agreement received',
  'request_for_further_information': 'request additional information',
  'rejection': 'Rejection',
  'unmanaged_folder': 'folder not managed',
  'imatr_number': 'Registration N°',
  'same_patient': 'Himself',
  send_date: 'Sending date'
};
