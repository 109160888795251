import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {BillingService} from '../../../shared/services/billing.service';
import {MAT_DIALOG_DATA,  MatDialogRef} from '@angular/material/dialog';
import * as _ from 'lodash';
import {FormControl, NgForm, Validators} from '@angular/forms';
import * as moment from 'moment';
import {TableColumnDefinition} from "@ft/table";

@Component({
  selector: 'ftb-response-dialog',
  templateUrl: './response-dialog.component.html',
  styleUrls: ['./response-dialog.component.scss']
})
export class ResponseDialogComponent implements OnInit {

  response = {
    type: null,
    date: moment(),
    reject_reason: null,
    confirmed_amount: 0,
    ref: null,
    description: ''
  };


  answers = [
    'filed_application',
    'agreement_subject',
    'request_final_agreement_in_progress',
    'final_agreement_received',
    'request_for_further_information',
    'rejection',
    'unmanaged_folder'
  ];

  @ViewChild('responseForm', {static: true}) public responseForm: NgForm;
  lines = [];
  response_details_columns: TableColumnDefinition[] = [
    {
      'key': 'code',
      'label': 'shared.code',
      'type': 'text',
      'class': 'simple-text'
    },
    {
      'key': 'description',
      'label': 'shared.description',
      'type': 'text'
    },
    {
      'key': 'qte',
      'label': 'shared.qte',
      'type': 'number',
      class: 'simple-number'
    },
    {
      'key': 'price',
      'label': 'shared.price',
      'type': 'number',
      'numberConfig': 'float'
    },
    {
      'key': 'discount',
      'label': 'shared.discount',
      'type': 'number',
      'numberConfig': 'float',
      class: 'three-numbers',
      max: 100
    },
    {
      'key': 'confirmed_amount',
      'label': 'pec.confirmed_amount',
      'type': 'number',
      'editable': true,
      'numberConfig': 'float'
    }
  ];

  constructor(public dialogRef: MatDialogRef<ResponseDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit() {
    this.lines = _.get(this.data, 'lines', this.lines);
    this.response = _.get(this.data, 'response', this.response);
  }

  save() {
    this.dialogRef.close({response: this.response, lines: this.lines});
    // this.sharedService.handleList(this.currentModel, this.cls).subscribe(data => );
  }

  listChange(data, type) {
    this.response['type'] = data;
  }

  compareObj(o1, o2) {
    return o1 && o2 && o1 == o2;
  }

}
