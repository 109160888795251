<mat-form-field fxFlex>

  <mat-select [placeholder]="label|translate"   [(ngModel)]="currentModel" [required]="is_required" [disabled]="is_disabled"
              [ngModelOptions]="{standalone:true}" (selectionChange)="changeModel()" [compareWith]="compareObj">
    <mat-option *ngFor="let e of data" [value]="e">{{e.value}}</mat-option>
  </mat-select>
  <button matSuffix mat-icon-button  type="button" matTooltip="ajouter" [disabled]="is_disabled" type="button"  color="primary"
          (click)="$event.stopImmediatePropagation();addObj()">
    <mat-icon fontSet="mdi" fontIcon="mdi-plus"></mat-icon>
  </button>


  <button matSuffix mat-icon-button type="button" color="warn" matTooltip="clean" [disabled]="is_disabled" type="button"  color="warn"
          (click)="$event.stopImmediatePropagation(); currentModel=null">
    <mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
  </button>
</mat-form-field>
