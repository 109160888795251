import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FtbStatesRoutingModule} from './states-routing.module';
import {FtAuthModule, FtCoreModule, MaterialModule} from '@ft/core';
import {TranslateModule} from '@ngx-translate/core';
import {HttpClientModule} from '@angular/common/http';
import {FormsModule} from '@angular/forms';
import {NgBusyModule} from 'ng-busy';
import {StateViewComponent} from './components/state-view/state-view.component';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {FtbMainComponent} from './components/main/main.component';
import {StateService} from './services/state-service.service';
import {FtTableModule} from '@ft/table';
import {FtbSharedModule} from '../shared/shared.module';
import {DailyPaymentsComponent} from './components/daily-payments/daily-payments.component';
import {CareRequestStateComponent} from './components/care-request-state/care-request-state.component';
import {BalancesStateComponent} from './components/balances-state/balances-state.component';
import {RecoveriesStateComponent} from './components/recoveries-state/recoveries-state.component';
import {DailyInvoicesComponent} from './components/daily-invoices/daily-invoices.component';
import {AnteriorViewComponent} from "./components/anterior-view/anterior-view.component";
import {WebdatarocksComponent} from "../webdatarocks/webdatarocks.angular4";
import { PaymentActivityComponent } from './components/payment-activity/payment-activity.component';

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    MaterialModule,
    TranslateModule.forChild(),
    HttpClientModule,
    FormsModule,
    NgBusyModule,
    FtCoreModule,
    FtTableModule,
    FtbSharedModule,
    FtAuthModule,
    FtbStatesRoutingModule
  ],
  declarations: [
    FtbMainComponent,
    StateViewComponent,
    DailyPaymentsComponent,
    CareRequestStateComponent,
    BalancesStateComponent,
    RecoveriesStateComponent,
    DailyInvoicesComponent,
    AnteriorViewComponent,
    WebdatarocksComponent,
    PaymentActivityComponent],
  providers: [StateService]
})
export class FtbStatesModule {
}
