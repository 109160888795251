import { Component } from '@angular/core';

@Component({
  selector: 'ftb-root',
  template : '  <router-outlet></router-outlet> ',
  styles: []
})
export class FtbCoreComponent {

  constructor() {
  }

}
