import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'ftb-main-container',
  template: `
    <router-outlet></router-outlet>
  `,
  styleUrls: ['./main-container.component.scss']
})
export class MainContainerComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
  }

}
