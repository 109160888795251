import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule, Routes} from '@angular/router';
import {MainComponent} from './components/main/main.component';
import {BillingRequestListComponent} from './components/billing-request-list/billing-request-list.component';
import {AuthGuard} from '@ft/core';


const routes: Routes = [

  {
    path: '',
    component: MainComponent,
    children: [
      {path: '', pathMatch: 'full', redirectTo: 'billing-requests'},
      {path: 'billing-requests', component: BillingRequestListComponent }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class FtbDailyWorkflowRoutingModule {
}


