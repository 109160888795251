<div fxFlex fxLayout="column">
  <div class="ft-parent-layout mat-elevation-z1" [class.ft-content-margin]="false" fxFlex
       fxLayout="column">
    <!--<mat-toolbar color="accent" >
      <div    class="ft-module-icon">
        <button type="button" mat-icon-button>
          <mat-icon fontSet="mdi" fontIcon="{{viewSettings.headerIcon}}"></mat-icon>
        </button>
      </div>
      <h2>{{ viewSettings.headerLabel | translate }}</h2>
    </mat-toolbar>-->
    <div fxFlex fxLayout="column" class="ft-content no-padding">
      <div fxLayout="row" fxLayoutAlign="end right" fxLayoutGap="6px" [class.ft-content-margin]="true">
        <div fxLayout="row" fxLayoutGap="6px">
          <mat-form-field fxFlex="nogrow">
            <input [disabled]="this.activeToggle!='shared.all'" matInput ftDate [matDatepicker]="from_date"
                   [placeholder]="'table.from_date'|translate"
                   (ngModelChange)="handleDateQuery($event, 'from_date')"
                   [(ngModel)]="dateQuery.from_date" name="from_date">
            <mat-datepicker-toggle matSuffix [for]="from_date">
              <mat-icon matDatepickerToggleIcon fontSet="mdi" fontIcon="mdi-calendar-today"></mat-icon>
            </mat-datepicker-toggle>
            <mat-datepicker #from_date></mat-datepicker>
          </mat-form-field>
          <mat-form-field fxFlex="nogrow">
            <input [disabled]="this.activeToggle!='shared.all'" matInput ftDate [matDatepicker]="to_date"
                   [placeholder]="'table.to_date'|translate"
                   (ngModelChange)="handleDateQuery($event,'to_date')"
                   [(ngModel)]="dateQuery.to_date" name="to_date">
            <mat-datepicker-toggle matSuffix [for]="to_date">
              <mat-icon matDatepickerToggleIcon fontSet="mdi" fontIcon="mdi-calendar-today"></mat-icon>
            </mat-datepicker-toggle>
            <mat-datepicker #to_date></mat-datepicker>
          </mat-form-field>
        </div>
        <mat-button-toggle-group #group="matButtonToggleGroup" aria-label="filter" class="custom-toggle-group"
                                 (change)="handleFilter($event)">
          <mat-button-toggle *ngFor="let f of defaultFilters" [value]="f" [checked]="f.checked">
            {{f.label|translate}}
          </mat-button-toggle>
        </mat-button-toggle-group>
      </div>
      <ft-generic-view-table [namespace]="viewSettings?.viewNamespace"
                             [viewSettings]="viewSettings?.viewSettings"
                             [hasPagination]="viewSettings?.hasPagination"
                             [selectable]="viewSettings?.selectable"
                             [InitialQuery]="query"
                             [actions]="multiActions"
                             [reloadEvent]="reloadEvent"
                             [exportExcel]="true"
                             (viewQueryChange)="handleViewQueryChange($event)"
                             [lineClass]="lineClass"
                             [hasFooter]="!hideFooter"
      ></ft-generic-view-table>
    </div>
  </div>
</div>
