import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {BillingService} from '../../../shared/services/billing.service';
import {Subscription} from 'rxjs';
import * as _ from 'lodash';
import * as moment from 'moment';
import { PHYSICIAN_VIEW} from '../../../shared/utils/views-settings';
import {PATIENTS_VIEW} from '../../../patient/utils/views-settings';
import {PatientDialogComponent} from "../../../patient/dialogs/patient-dialog/patient-dialog.component";

@Component({
  selector: 'ftb-fees-note',
  templateUrl: './fees-note.component.html',
  styleUrls: ['./fees-note.component.scss']
})
export class FeesNoteComponent implements OnInit {
  loading: Subscription;
  feesNote: any = {
    number: null,
    doc_date: moment(),
    lines: []
  };
  seqQuery = {
    cls: 'invoicing.FeesNote',
    label: 'invoicing.fees_note_number',
    fieldName: 'number',
    reloadDeleted: true
  };
  loadedObject=null;
  cls = 'fees-note';
  routes = {list: '/invoicing/fees-note/', form: '/invoicing/fees-note/fees-note-form/'};
  patientInputSettings = _.assignIn(_.cloneDeep(PATIENTS_VIEW), {selectable: false});
  physicianInputSettings = _.assignIn(_.cloneDeep(PHYSICIAN_VIEW), {selectable: false});
  patientDialog = PatientDialogComponent;


  constructor(private route: ActivatedRoute, private  billingService: BillingService) {
  }

  ngOnInit() {
    this.route.params.subscribe((params) => this.initData(params));
  }

  initData(params) {
    if (_.get(params, 'id')) {
      this.loading = this.billingService.getBillingDoc(this.cls, _.get(params, 'id'))
        .subscribe(data => this.feesNote = data);
    }
  }


  onModelChange(data) {
    this.feesNote = data;
  }

  handleBeneficiaryChange(data) {
    this.feesNote.patient = data;
  }

  onSequenceChange(data) {
    if (typeof data === 'string') {
      this.feesNote.number = data;
    } else {
      this.feesNote.number = data[this.seqQuery.fieldName];
      this.feesNote.id = data['id'];
    }
  }

  onPhysicianChange = (data) => {
    this.feesNote.physician = data;
  };
}
