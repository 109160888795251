<div fxFlex class="ft-padding" fxLayout="column">
<form  fxFlex fxLayout="column" fxLayoutGap="6px">
  <div fxLayout="row" fxLayoutGap="6px">
    <mat-form-field fxFlex="15">
      <input matInput ftDate [matDatepicker]="from_date" [placeholder]="'shared.from_date'|translate"
             [(ngModel)]="filter.from_date" name="from_date" (ngModelChange)="handleFilterChange()">
      <mat-datepicker-toggle matSuffix [for]="from_date">
        <mat-icon matDatepickerToggleIcon fontSet="mdi" fontIcon="mdi-calendar-today"></mat-icon>
      </mat-datepicker-toggle>
      <mat-datepicker #from_date></mat-datepicker>
    </mat-form-field>

    <mat-form-field fxFlex="15">
      <input matInput ftDate [matDatepicker]="to_date" [placeholder]="'shared.to_date'|translate"
             [(ngModel)]="filter.to_date" name="to_date" (ngModelChange)="handleFilterChange()">
      <mat-datepicker-toggle matSuffix [for]="to_date">
        <mat-icon matDatepickerToggleIcon fontSet="mdi" fontIcon="mdi-calendar-today"></mat-icon>
      </mat-datepicker-toggle>
      <mat-datepicker #to_date></mat-datepicker>
    </mat-form-field>
    <div fxLayout="row" class="row-toggle">
        <mat-slide-toggle  [(ngModel)]="percentModel.show_percents" (change)="handlePercents()"   name="showPercents" >
          {{'states.show_percents'|translate}}
        </mat-slide-toggle>
    </div>
  </div>

  <div fxLayout="row" fxLayoutGap="6px" *ngIf="percentModel.show_percents"  >
      <mat-form-field fxFlex="15">
        <input matInput
                name="center_percent"
                [ftNumber]="'float'" placeholder="{{'states.center_percent'|translate}}"
                [(ngModel)]="percentModel.center_percent"
                (change)="handlePercentChange('center_percent'); "
                 min="0"/>
      </mat-form-field>
      <mat-form-field  fxFlex="15">
        <input matInput
                name="physician_percent"
                [ftNumber]="'float'"
                (change)="handlePercentChange('physician_percent'); "
                placeholder="{{'states.physician_percent'|translate}}"
                [(ngModel)]="percentModel.physician_percent"
                 min="0">
      </mat-form-field>
      <mat-form-field floatLabel="always" class="ft-radio-field row-radio-group">
        <input matInput placeholder="{{'shared.base_percent'|translate}}" style="display: none">
        <mat-radio-group [(ngModel)]="percentModel.base_percent" (change)="handlePercents()" name="base_percent" fxLayout="row">
        <mat-radio-button value="taxed_amount">
            {{'dailyWorkflow.taxed_amount'|translate}}
          </mat-radio-button>
          <mat-radio-button  value="received_amount">
            {{'states.received_amount'|translate}}
          </mat-radio-button>
        </mat-radio-group>
      </mat-form-field>
  </div>
  <div [ngBusy]="busy" [@scaleAnimation]>
    <ft-generic-body-table   fxFlex
                           [remote]="false"
                           [hasSearch]="false"
                           [hasFooter]="has_res"
                           [source]="linesSource$"
                           [selectable]="false"
                           [hasPagination]="true"
                           [columns]="linesColumns | async"
    >
    </ft-generic-body-table>
  </div>
</form>
<div fxLayout="row" fxLayoutGap="6px">
    <button fxLayoutAlign="left bottom" type="button"  mat-mini-fab color="primary"
             [disabled]="!has_res"
            [ftPrint]="{group:'daily-invoices-state', view:'/api/billing/invoice/', customView:'print-unique',  model:null, extraQuery:{data:formatFilter()},  unique:true}">
      <mat-icon fontSet="mdi" fontIcon="mdi-printer"></mat-icon>
    </button>
    <button fxLayoutAlign="left bottom" type="button"  mat-mini-fab color="primary"
          [disabled]="!has_res" [columns]="viewColumns.concat(percentModel.show_percents ? percentsColumns :[])"
          [ftbXlsExport]="{filter:formatFilter() ,topic:'invoice.handle_daily_invoices_state_xls', fileName: 'states.daily_invoices'|translate}"
          >
      <mat-icon fontSet="mdi" fontIcon="mdi-file-excel"></mat-icon>
    </button>
  </div>
</div>
