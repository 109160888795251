import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {CatalogueListComponent} from './components/catalogue-list/catalogue-list.component';
import {DocumentsListComponent} from '../shared/components/documents-list/documents-list.component';
import {OrganizationFormComponent} from './components/organization-form/organization-form.component';
import {
  AuthGuard, ListItem,
  ListSetupComponent, PrintingSetupComponent,
  ProfileDetailComponent,
  ProfileSetupComponent,
  SequenceConfigComponent, StaffFormDialog,
  StaffSetupComponent, StaffTypes, TitleListFromDialog
} from '@ft/core';
import {CONVENTION_VIEW, ORGANIZATION_VIEW} from './utils/views-settings';
import {FtbMainComponent} from './components/main/main.component';
import { PROCEDURE_VIEW} from '../shared/utils/views-settings';
import {BaseSettingsComponent} from './components/base-settings/base-settings.component';
import {HlSevenSetupComponent} from './components/hl-seven-setup/hl-seven-setup.component';
import {PricingComponent} from "./components/pricing/pricing.component";
import {BillingStaffDialogComponent} from "./dialogs/billing-staff-dialog/billing-staff-dialog.component";


const APP_LISTS: ListItem[] = [
  {
    url: '/api/list/title/',
    label: 'common.list.title',
  },
  {
    url: '/api/list/bank/',
    label: 'payment.bank',
  },
  {
    url: "/api/list/profession/",
    label: "common.list.profession",
  },
  {
    url: "/api/list/marital-status/",
    label: "common.list.marital_status",
  },
  {
    url: 'api/list/unity/',
    label: 'shared.unity',
  },
  {
    url: 'api/list/vat/',
    label: 'shared.vat',
  }
];

const STAFF_TYPES: StaffTypes[] = [
  {
    label: 'settings.physicians',
    url: '/api/staff/staff-form/',
    dialog: BillingStaffDialogComponent,
  }
];

const defaultLinks= [
  {label: "core.daily_workflow", link: "/workflow/billing-requests"},
  {label: "core.patients", link: "/patient/patients"},
  {label: "core.billing", link: "/invoicing"},
  {label: "core.payment", link: "/payment/payments"},
  {label: "shared.pec", link: "/pec/pecs"},
  {label: "core.states", link: "/states"},
]


const routes: Routes = [
  {
    path: '',
    component: FtbMainComponent,
    children: [
      {path: '', pathMatch: 'full', redirectTo: 'base-settings'},
      {path: 'base-settings', component: BaseSettingsComponent },
      {path: 'printing-setup', component: PrintingSetupComponent, data: {componentPath: '/settings/printing-setup'}},
      {
        path: 'profile-setup',
        component: ProfileSetupComponent,
        data: {profileDetail: '/settings/profile-update', defaultLinks:defaultLinks}
      },
      {path: 'profile-update', component: ProfileDetailComponent, data: {profileList: '/settings/profile-setup'}},
      {path: 'staff-setup', component: StaffSetupComponent, data: {staffs: STAFF_TYPES}},

      {path: 'procedure-list', component: DocumentsListComponent, data: PROCEDURE_VIEW},

      {path: 'organization-list', component: DocumentsListComponent, data: ORGANIZATION_VIEW},
      {path: 'organization-form', component: OrganizationFormComponent},
      {path: 'organization-form/:id', component: OrganizationFormComponent},

      {path: 'procedures-catalogues-list', component: CatalogueListComponent},
      {path: 'procedures-catalogues-list/:id', component: CatalogueListComponent},

      {path: 'conventions-list', component: DocumentsListComponent, data: CONVENTION_VIEW},
      {path: 'pricing-list', component: PricingComponent },

      {path: 'sequences', component: SequenceConfigComponent},

      {path: 'list-setup', component: ListSetupComponent, data: {lists: APP_LISTS}},
      {path: 'hl-seven-setup', component: HlSevenSetupComponent }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class FtbSettingsRoutingModule {
}


