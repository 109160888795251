import {lang} from '../i18n';
import {TableColumnDefinition} from '@ft/table';
export const RESPONSE_LINES_HEADERS: TableColumnDefinition[] = [
  {
    'key': 'date',
    'label': 'shared.date',
    'type': 'date'
  },
  {
    'key': 'answer_type',
    'label': 'pec.answer_type',
    'type': 'text',
    'extended': true,
    'callBack': (row) => {
      return row['type'] ? lang[row['type']] : '';
    }
  },
  {
    'key': 'reject_reason',
    'label': 'pec.reject_reason',
    'type': 'text'
  },
  {
    'key': 'ref',
    'label': 'pec.ref',
    'type': 'text'
  },
  {
    'key': 'confirmed_amount',
    'label': 'pec.confirmed_amount',
    'type': 'number',
    'numberConfig': 'float'

  }
];
