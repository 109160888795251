import {TableViewDefinition} from '@ft/table/table/models/table-definitions';
import {DocumentListSettings} from '../../shared/models/document-list-definition';
import {PAYMENT_COLUMNS} from '../../shared/utils/table-configs/payment';
import {SYSTEM_COLUMNS} from '../../shared/utils/table-configs/common-system-column';

export const PAYMENTS_VIEW: DocumentListSettings = {
  trackByKey: 'id',
  hasSearch: true,
  selectable: true,
  hasPagination: true,
  viewNamespace: 'paymentList',
  query: [],
  viewSettings: {
    'model': 'payment.PaymentTable',
    'viewColumns': PAYMENT_COLUMNS,
    'systemColumns': SYSTEM_COLUMNS,
    'defaultName': 'Liste des paiements',
  },
  actions: [],
  resource: 'payment',
  formRoute: '/payment/payments/payment-form/',
  formLabel: 'payment.payment',
  headerLabel: 'payment.payment_list',
  headerIcon: 'mdi-currency-usd',
  removeLinearDelete:true,
  linearPrint:true
};
