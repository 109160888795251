import {Component, OnInit} from '@angular/core';
import {MONTHS} from '../../../shared/utils/default-conts';
import {PaymentService} from '../../../payment/services/payment.service';
import {PAYMENT_CONDITIONS} from '../../../shared/utils/payment-conditions';
import {DefaultValuesService} from '@ft/core';
import * as _ from 'lodash';
import {COMMA, ENTER} from "@angular/cdk/keycodes";

@Component({
  selector: 'ftb-base-settings',
  templateUrl: './base-settings.component.html',
  styleUrls: ['./base-settings.component.scss']
})
export class BaseSettingsComponent implements OnInit {
  startDate = new Date();
  months = MONTHS;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  payment_mode = this.defaultValuesService.getValue('payment_mode');
  payment_condition = this.defaultValuesService.getValue('default_payment_condition');
  valid_duration = this.defaultValuesService.getValue('quotation_valid_duration');
  invoice_payment_from_docs = this.defaultValuesService.getValue('invoice_payment_from_docs');

  paymentModeTypes = [];
  paymentConditions = PAYMENT_CONDITIONS;
  exercise_start_month = this.defaultValuesService.getValue('exercise_start_month');

  dataSource = [
    {name: "PATIENT_color", color: this.defaultValuesService.getValue('PATIENT_color') || "rgba(102, 179, 255, 1)"},
    {
      name: "INSURED_PATIENT_color",
      color: this.defaultValuesService.getValue('INSURED_PATIENT_color') || "rgba(102, 179, 255, 1)"
    },
    {
      name: "THIRD_PARTY_PAYMENT_color",
      color: this.defaultValuesService.getValue('THIRD_PARTY_PAYMENT_color') || "red"
    },
    {name: "PEC_color", color: this.defaultValuesService.getValue('PEC_color') || "red"},
    {
      name: "in_progress_color",
      color: this.defaultValuesService.getValue('in_progress_color') || "rgba(255, 128, 0, 1)"
    },
    {name: "granted_color", color: this.defaultValuesService.getValue('granted_color') || "rgba(102, 179, 255, 1)"},
    {name: "rejected_color", color: this.defaultValuesService.getValue('rejected_color') || "rgba(92, 0, 92, 1)"},
    {
      name: "beneficiary_type_color",
      color: this.defaultValuesService.getValue('beneficiary_type_color') || "rgba(0, 245, 123, 1)"
    },
    {
      name: "payer_type_color",
      color: this.defaultValuesService.getValue('payer_type_color') || "rgba(153, 51, 255, 1)"
    },
    {
      name: "payment_mode_color",
      color: this.defaultValuesService.getValue('payment_mode_color') || "rgba(255, 128, 0, 1)"
    },

  ]
  compareCondition = (o1, o2) => {
    return o1 == o2;
  };


  comparePaymentCondition = (o1, o2) => {
    return _.get(o1, 'key', 'n') == _.get(o2, 'key', 'a');
  };

  constructor(private paymentService: PaymentService, private defaultValuesService: DefaultValuesService) {
  }

  ngOnInit() {
    this.paymentService.getDefaultValue('PAYMENT_MODE_TYPE').subscribe(data => this.paymentModeTypes = data);
  }

}
