import {
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  Renderer2,
  SimpleChanges
} from '@angular/core';
import {AsyncEvent} from "@ft/core/core/classes/async-event";
import {SharedService} from "../services/shared-service";
import * as _ from "lodash";
import {TranslateService} from "@ngx-translate/core";
import {Subscription} from "rxjs";

@Directive({
  selector: 'button[ftbXlsExport]',
  host: {
    'type': 'button'
  }
})
export class XlsExportDirective {
  viewColumns = [];
  _subscription: Subscription = null;
  @Input('ftbXlsExport') options;

  @Input() set columns(value: any) {
    this.viewColumns = value;
  }


  constructor(protected element: ElementRef, protected renderer: Renderer2, private sharedService: SharedService, private translateService: TranslateService) {
  }

  @HostListener('click', ['$event'])
  clickEvent(event) {
    event.preventDefault();
    event.stopPropagation();
    this.handleExcelExport();
  }

  handleExcelExport() {
    function reduceFunc(items, item) {
      return _.set(items, item.key, this.translateService.instant(item.label));
    }

    let translatedColumns;
    translatedColumns = _.reduce(this.viewColumns, reduceFunc.bind(this), {
      file_name: _.get(this.options, 'fileName'),
      page_name: this.translateService.instant('table.export_excel_page_name')
    });
    let excelColumns;
    excelColumns = _.map(this.viewColumns, (e) => {
      return _.pick(e, 'key', 'type');
    });
    this._subscription = this.sharedService.handleXlsExport(_.get(this.options, 'topic'), _.assignIn({filter: _.get(this.options, 'filter')}, {
      excelColumns,
      translatedColumns
    })).subscribe(data => {
      if (data.download) {
        data.download();
      }
    });
  }
}


