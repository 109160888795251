import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import * as _ from 'lodash';
import {HEIGHT_ANIMATION, SCALE_ANIMATION} from '@ft/core';

@Component({
  selector: 'ftb-main',
  templateUrl: './main.component.html',
  animations: [
    SCALE_ANIMATION,
    HEIGHT_ANIMATION
  ],
  styleUrls: ['./main.component.scss']
})
export class FtbMainComponent implements OnInit {
  reports = [
    {
      label: 'core.payment',
      items: [

        {
          icon: null,
          path: '/states/daily-activity',
          label: 'states.daily_activity',
        },
        {
          icon: null,
          path: '/states/periodic-activity',
          label: 'states.periodic_activity',
        }
      ]
    },
    {
      label: 'core.billing',
      items: [
        {
          icon: null,
          path: '/states/daily-invoices/',
          label: 'states.daily_invoices',
        },
        {
          icon: null,
          path: '/states/balances/',
          label: 'states.aging_balance',
        },
        {
          icon: null,
          path: '/states/recoveries/',
          label: 'states.customer_recovery',
        }
      ]
    },

    {
      label: 'shared.pec',
      items: [
        {
          icon: null,
          path: '/states/care-requests/',
          label: 'states.care_requests_state',
        }
      ]
    },
    {
      label: 'states.personalized_states',
      items: [
        {
          icon: null,
          path: '/states/daily-payments',
          label: 'states.daily_state',
        },        {
          icon: null,
          path: '/states/state-view',
          label: 'states.general_activity'
        },
        {
          icon: null,
          path: '/states/anterior-view',
          label: 'states.anterior_activity'
        }
      ]
    }
  ];

  constructor(private router: Router) {
  }

  ngOnInit() {
  }

  reportClick(link) {
    this.router.navigate([link.path]);

  }

  trackByFn(index, item) {
    return _.get(item, 'label', index);
  }

}
