import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class RouterDataService {
  _routerData: any;

  set routerData(value: any) {
    this._routerData = value;
  }

  get routerData(): any {
    return this._routerData;
  }

  constructor() {
  }
}
