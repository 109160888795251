import {Component, OnInit} from '@angular/core';
import {BehaviorSubject, Observable, of, Subscription} from "rxjs";
import {TableColumnDefinition} from "@ft/table";
import * as moment from "moment";
import * as _ from "lodash";
import {PHYSICIAN_VIEW} from "../../../shared/utils/views-settings";
import {StateService} from "../../services/state-service.service";
import {TranslateService} from "@ngx-translate/core";
import {TableColumnType} from "@ft/table/table/models/table-definitions";

@Component({
  selector: 'ftb-balances-state',
  templateUrl: './balances-state.component.html',
  styleUrls: ['./balances-state.component.scss']
})
export class BalancesStateComponent implements OnInit {

  items: any = [];
  busy: Subscription;

  linesSource$: Observable<any[]> = of([]);
  filter = {'from_date': moment(), 'to_date': moment()};
  has_res = false;
  viewColumns: TableColumnDefinition[] = [
    {
      label: "invoicing.invoice_number",
      type: "text",
      key: "number"
    }, {
      label: "states.b_15",
      type: "number",
      key: "b_15",
      numberConfig: 'float',
      footer: () => {
        return _.sumBy(this.balances, 'b_15');
      }
    }, {
      label: "states.b_30",
      type: 'number',
      key: "b_30",
      numberConfig: 'float',
      footer: () => {
        return _.sumBy(this.balances, 'b_30');
      }
    }, {
      label: "states.b_60",
      type: "number",
      key: "b_60",
      numberConfig: 'float',
      footer: () => {
        return _.sumBy(this.balances, 'b_60');
      }
    }, {
      label: "states.b_120",
      type: "number",
      key: "b_120",
      numberConfig: 'float',
      footer: () => {
        return _.sumBy(this.balances, 'b_120');
      }
    }];
  linesColumns: BehaviorSubject<TableColumnDefinition[]> = new BehaviorSubject<TableColumnDefinition[]>(this.viewColumns);
  balances = [];

  constructor(private stateService: StateService, private translateService: TranslateService) {
  }

  ngOnInit() {
    this.handleFilterChange(null);
  }


  handleFilterChange(ev) {
    this.busy = this.stateService.getBalanceStatus(this.formatFilter()).subscribe(data => {
      let totals = _.get(data, 'totals', {});
      if (!_.isEmpty(data)) {
        this.has_res = true;
        this.balances = data;
        this.linesSource$ = of(data);
      }
      else {
        this.has_res = false;
      }
    });

  }

  formatFilter() {
    return {
      _from: _.get(this.filter, 'from_date').format('DD/MM/YYYY'),
      _to: _.get(this.filter, 'to_date').format('DD/MM/YYYY'),
      state: 'aging_balance'
    }
  }
}
