<div class="ft-parent-layout mat-elevation-z1 ft-content-margin" fxFlex fxLayout="column">
    <mat-toolbar color="accent">
        <div class="ft-module-icon">
            <button mat-icon-button>
                <mat-icon fontSet="mdi" fontIcon="mdi-currency-usd"></mat-icon>
            </button>
        </div>
        <h2 translate="settings.pricing_list"></h2>
        <span fxFlex></span>
      <ftb-import-csv-button  [importCallBack]="importCallBack"  [importTopic]="'pricing.import_from_csv'"></ftb-import-csv-button>

        <button mat-button class="with-icon" (click)="handleTariff()" [disabled]="!tariff?.id">
            <mat-icon fontSet="mdi" fontIcon="mdi-plus"></mat-icon>
            <span translate="shared.add"></span>
        </button>

    </mat-toolbar>
    <div fxFlex fxLayout="column" class="ft-content no-padding">
        <mat-drawer-container class="ft-content-container" fxFlex>
            <mat-drawer mode="side" [opened]="true">
                <mat-toolbar color="primary">
                    <div class="ft-module-icon">
                        <mat-icon fontSet="mdi" fontIcon="mdi-format-list-bulleted"></mat-icon>
                    </div>
                    <h2 translate="settings.tariffs"></h2>
                    <span fxFlex></span>
                </mat-toolbar>

                <mat-action-list role="list" class="ft-content">
                    <div *ngIf="!tariffs || tariffs.length == 0" class="ft-empty-content" [@scaleAnimation]>
                        <mat-icon fontSet="mdi" fontIcon="mdi-alert-circle-outline"></mat-icon>
                        <span translate="common.no_element_to_show"></span>
                    </div>
                    <mat-list-item role="listitem" *ngFor="let item of tariffs">
                        <button mat-line (click)="selectTariff(item)" [disabled]="item?.id == tariff?.id">
                            <mat-icon fxFlex="noshrink" fontSet="mdi" fontIcon="mdi-file-document"></mat-icon>
                            <span fxFlex [translate]="item.name"></span>
                            <mat-icon fxFlex="noshrink" *ngIf="item.id == tariff.id" [@scaleAnimation]
                                      fontSet="mdi" fontIcon="mdi-pencil-box-outline" class="ft-success">
                            </mat-icon>
                        </button>
                    </mat-list-item>
                </mat-action-list>
            </mat-drawer>
            <mat-drawer-content fxLayout="column">
                    <form #Form="ngForm" fxLayout="column" fxFlex class="ft-padding" fxLayoutGap="6px" [ngBusy]="busy">
                    <div fxLayout="row">
                        <mat-form-field fxFlex="50" fxLayout="column"   class="with-error-label">
                        <input matInput [(ngModel)]="tariff.name" ftCustomValidation="pricing.check_uniqueness" [ftAutoFocus]="true"
                               [query]="{field:'name', pk:tariff?.id}" required name="name" #name="ngModel"
                               [placeholder]="'shared.name'|translate">
                        <mat-error *ngIf="name?.errors?.exists" translate="shared.already_exist"></mat-error>
                      </mat-form-field>
                    </div>
                     <ft-generic-body-table fxFlex
                                             [remote]="false"
                                             [source]="linesSource$"
                                             [selectable]="false"
                                             [hasPagination]="true"
                                             [columns]="linesColumns | async"
                                             [multiActions]="linesActions"
                      >
                      </ft-generic-body-table>
                    </form>
                    <div class="ft-actions">
                      <ftb-delete-button *ngIf="tariff?.id" [model]="tariff"
                                         [deleteTopic]="'pricing.check_and_delete'"
                                         [afterDelete]="initTariffs"></ftb-delete-button>
                      <span fxFlex></span>
                      <button type="button" [disabled]="Form.invalid" mat-raised-button color="primary" (click)="save()">
                        <span>{{'shared.save'|translate}}</span>
                      </button>
                    </div>
            </mat-drawer-content>
        </mat-drawer-container>
    </div>
</div>
