import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import * as _ from 'lodash';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {MatDialog} from '@angular/material/dialog';
import {LinesSelectDialogComponent} from '../../dialogs/lines-select-dialog/lines-select-dialog.component';
import {LARGE_DIALOG} from '@ft/core';

import * as moment from 'moment';
import {getLineAmount} from '../../utils/functions';
import {TranslateService} from '@ngx-translate/core';
import {TableColumnDefinition} from '@ft/table';
import {BillingService} from "../../services/billing.service";
import {handleDisplayedColumns} from "../../utils/helpers";
import {DailyWorkflowService} from "../../../daily-workflow/services/daily-workflow.service";


@Component({
  selector: 'ftb-document-lines',
  templateUrl: './document-lines.component.html',
  styleUrls: ['./document-lines.component.scss']
})
export class DocumentLinesComponent implements OnInit {

  linesActions = [
    {
      class: 'mat-primary',
      icon: 'mdi-refresh',
      isMultipleSelection: false,
      method: (item, ev) => {
        return this.applyConvention();
      },
      hidden: () => {
        return this.cls != 'Invoice'
      },
      tooltip: this.translateService.instant('shared.apply_convention')
    },
    {
      class: 'mat-primary',
      icon: 'mdi-plus',
      isMultipleSelection: false,
      method: (item, ev) => {
        return this.showSelectDialog();
      },
      tooltip: this.translateService.instant('shared.add_line')
    },
    {
      class: 'mat-accent',
      icon: 'mdi-comment-plus-outline',
      isMultipleSelection: false,
      method: (item, ev) => {
        return this.addComment();
      },
      tooltip: this.translateService.instant('shared.add_comment')
    }
  ];
  linesColumns: BehaviorSubject<TableColumnDefinition[]>;
  linesSource$: Observable<any[]> = of([]);
  modelLines = [];
  selectQuery = {};
  convention = null;
  pricing = null;
  model = null;
  pageSize = 10;
  readOnly = false;
  defaultColumns = null;
  extendedColHandler = (index, item) => item.is_comment === true || _.has(item, 'details');

  @Input() cls;
  @Input() showAmounts: Boolean = true;
  @Input() showDetailedAmounts: Boolean = true;
  hasSearch: boolean = true;

  @Input('defaultColumns') set handleDefaultColumns(value: any) {
    this.defaultColumns = value;
  }

  @Input('hasSearch') set handleHasSearch(value: boolean) {
    this.hasSearch = value;
  }

  @Input('convention') set defaultConvention(value: any) {
    this.convention = value;
    if (this.convention) {
      this.handleLinesTariff(this.convention.tariff, this.convention.exceptions);
    } else {
      this.handleLinesTariff({tp: 0, tm: 100});
    }
  }

  // get defaultConvention() {
  //   return this.convention.getValue();
  // }

  @Input('pricing') set defaultPricing(value: any) {
    this.pricing = value;
    if (this.pricing) {
      this.handleLinesPrice(this.pricing.lines);
    }
  }

  get defaultPricing() {
    return this.pricing.getValue();
  }

  @Input() set linesSelectQuery(value: any) {
    this.selectQuery = value;
  }

  @Input('model') set handleModel(value: any[]) {
    this.model = value;
  }

  @Input('pageSize') set handlePageSize(value: number) {
    this.pageSize = value;
  }

  @Input('readOnly') set handleReadOnly(value: boolean) {
    this.readOnly = value;
  }

  @Input() set lines(value: any[]) {
    this.modelLines = value;
    this.linesSource$ = of(this.lineDisplayValue(value));
  }

  @Output() linesChange = new EventEmitter();

  constructor(private dialog: MatDialog, private translateService: TranslateService,
              private dailyWorkflowService: DailyWorkflowService,
              private billingService: BillingService) {
  }

  ngOnInit() {

    if (this.defaultColumns) {
      this.linesColumns = new BehaviorSubject<TableColumnDefinition[]>(this.defaultColumns);
    } else {
      this.linesColumns = new BehaviorSubject<TableColumnDefinition[]>(handleDisplayedColumns(this.cls).concat(!this.readOnly ? {
        label: '', key: 'actions', type: 'actions', actions: [
          {
            class: 'mat-warn',
            icon: 'mdi-close-circle',
            method: (item, index, ev) => {
              return this.removeLine(item, index);
            },
            disabled: (row) => this.readOnly
          }
        ]
      } : []));
    }
  }

  showSelectDialog() {
    this.dialog.open(LinesSelectDialogComponent, _.assignIn(LARGE_DIALOG, {
      data: this.selectQuery
    })).afterClosed().subscribe(res => {
      _.forEach(res, (e) => {
        this.refreshLines(e['selected'], e.name);
      });
    });
  }

  addComment() {
    this.modelLines.push({
      code: '',
      description: '',
      is_comment: true
    });
    this.linesSource$ = of(this.lineDisplayValue(this.modelLines));
  }

  refreshLines(data, type) {
    if (!_.isEmpty(data)) {
      switch (type) {
        case 'procedures':
          this.refreshProcedures(data);
          break;
        case 'patientVisits':
          this.refreshByPatientDocs(data, type, 'billing-request');
          break;

        case 'feesNotes':
          this.refreshByPatientDocs(data, type, 'fees-note');
          break;

        case 'quotations':
          this.refreshByPatientDocs(data, type, 'quotation');
          break;
      }
    }
  }

  refreshProcedures(data) {
    if (this.cls === 'Invoice') {
      let _idx = _.findIndex(this.modelLines, (l) => {
        return l.description == 'Procedures';
      });
      if (_idx < 0) {
        this.modelLines.push({
          description: 'Procedures',
          details: _.map(data, (obj) => {
            return {
              code: obj.code,
              description: obj.name,
              discount: 0,
              price: _.get(_.find(_.get(this.pricing, 'lines'), (p) => {
                return p.procedure.code == obj.code
              }), 'value', obj.price),
              qte: 1,
              tariff: _.get(this.convention, 'tariff', {tp: 0, tm: 100})
            };
          })
        });
      } else {
        this.refreshSimpleProcedure(data, this.modelLines[_idx].details);
      }
    } else {
      this.refreshSimpleProcedure(data, this.modelLines);
    }

    this.linesSource$ = of(this.lineDisplayValue(this.modelLines));

  }

  refreshSimpleProcedure(data, details) {
    _.forEach(data, (obj) => {
      let _dIdx = _.findIndex(details, {code: obj.code});
      if (_dIdx < 0) {
        details.push(
          {
            procedure: obj,
            code: obj.code,
            description: obj.name || '',
            discount: 0,
            price: _.get(_.find(_.get(this.pricing, 'lines'), (p) => {
              return p.procedure.code == obj.code
            }), 'value', obj.price),
            qte: 1,
            tariff: _.get(this.convention, 'tariff', {tp: 0, tm: 100})
          }
        );
      }
    });
  }

  refreshByPatientDocs(data, type, cls) {
    switch (_.get(this.selectQuery, 'cls')) {
      case 'Certificate':
        this.refreshByExams(data);
        break;
      default:
        let docs = _.chain(data).filter((item) => {
          return !_.get(item, 'fully_billed', true);
        }).map((e) => {
          return {
            cls: cls,
            pk: _.get(e, 'id'),
            description: `${this.translateService.instant(`shared.${type}`)}
      ${this.translateService.instant('shared.nr')}:
      ${e.number || e.doc_id} - ${moment(e['doc_date']).format('DD/MM/YYYY')}`,
          }
        }).value();
        this.billingService.handleNonBilledLines(docs).subscribe(data => {
          _.forEach(data, (d, i) => {
            this.refreshByDocumentLines(type, docs[i], data[i])
          })
          this.linesSource$ = of(this.lineDisplayValue(this.modelLines));

        })
    }

  }

  refreshByExams(data) {
    let loading = this.dailyWorkflowService.getBillingRequests(_.map(data, 'id'))
      .subscribe(r => {
        let lines = _.map(r, (e) => {
          return _.assignIn({}, _.pick(e, 'doc_id', 'doc_date', 'taxed_amount', 'external_physician', 'external_referring_physician'),
            {
              billing_request: _.get(e, 'id'),
              tm_amount: _.sumBy(e.procedures, (row) => {
                let lAmount = getLineAmount(row) - _.get(row, 'discount_amount_from_global', 0);
                return lAmount - ((100 - _.get(row, 'tariff.tm', 0)) * lAmount) / 100;
              }),
              tp_amount: _.sumBy(e.procedures, (row) => {
                let lAmount = getLineAmount(row) - _.get(row, 'discount_amount_from_global', 0);
                return lAmount - ((100 - _.get(row, 'tariff.tp', 0)) * lAmount) / 100;
              })
            }
          )
        });
        this.modelLines = [...this.modelLines, ...lines];
        this.linesChange.emit(this.modelLines)
        this.linesSource$ = of(this.lineDisplayValue(this.modelLines));
      });


  }

  lineDisplayValue(value) {
    return _.chain(value).map((line) => {
      let lIdx = _.findIndex(value, line);
      let details = _.map(line.details, (e) => {
        return _.assignIn(e, {parentIdx: lIdx, idx: _.findIndex(line.details, e)});
      });
      let _line = _.assignIn(line, {idx: lIdx});

      return _.flatten([_line, details]);
    }).flatten().value();
  }

  removeLine(row, idx) {
    if (!_.isNil(row.parentIdx)) {
      this.modelLines[row.parentIdx].details.splice(row.idx, 1);
      if (_.isEmpty(this.modelLines[row.parentIdx].details)) {
        this.modelLines.splice(row.parentIdx, 1);
      }
    } else {
      this.modelLines.splice(idx, 1);
    }
    this.linesSource$ = of(this.lineDisplayValue(this.modelLines));
  }

  getAmount() {
    let lines = _.filter(this.modelLines, (l) => {
      return !l.is_comment;
    });

    return _.chain(this.cls == 'Invoice' ? _.map(lines, 'details') : lines).flatten().sumBy((row) => {
      return getLineAmount(row) - _.get(row, 'discount_amount_from_global', 0);
    }).value();
  }

  getAmountNoGlobalDiscount() {
    let lines = _.filter(this.modelLines, (l) => {
      return !l.is_comment;
    });

    return _.chain(this.cls == 'Invoice' ? _.map(lines, 'details') : lines).flatten().sumBy((row) => {
      return getLineAmount(row);
    }).value();
  }

  getGlobalDiscount() {
    let lines = _.filter(this.modelLines, (l) => {
      return !l.is_comment;
    });
    return _.chain(this.cls == 'Invoice' ? _.map(lines, 'details') : lines).flatten().sumBy((row) => {
      return _.get(row, 'discount_amount_from_global', 0);
    }).value();
  }

  getOrganizationAmount() {
    let lines = _.filter(this.modelLines, (l) => {
      return !l.is_comment;
    });

    return _.chain(this.cls == 'Invoice' ? _.map(lines, 'details') : lines).flatten().filter((e) => {
      return _.get(e, 'tariff.tp', -1) > 0;
    }).sumBy((row) => {
      let lAmount = getLineAmount(row) - _.get(row, 'discount_amount_from_global', 0);
      return lAmount - ((100 - _.get(row, 'tariff.tp', 0)) * lAmount) / 100;
    }).value();
  }

  getPatientAmount() {
    let lines = _.filter(this.modelLines, (l) => {
      return !l.is_comment;
    });

    return _.chain(this.cls == 'Invoice' ? _.map(lines, 'details') : lines).filter((l) => {
      return !l.is_comment;
    }).flatten().filter((e) => {
      return _.get(e, 'tariff.tm', 0) > 0;
    }).sumBy((row) => {
      let lAmount = getLineAmount(row) - _.get(row, 'discount_amount_from_global', 0);
      return lAmount - ((100 - _.get(row, 'tariff.tm', 0)) * lAmount) / 100;
    }).value();
  }

  getDocumentFilter(_pk, type) {
    switch (type) {
      case 'patientVisits':
        return {billingRequest: {id: _pk}};
      case 'feesNotes':
        return {feesNote: {id: _pk}};
      case 'quotations':
        return {quotation: {id: _pk}};
    }
  }

  refreshByDocumentLines(type, doc, lines) {
    let _idx = _.findIndex(this.modelLines, this.getDocumentFilter(doc.pk, type));
    if (_idx < 0) {
      this.modelLines.push(_.assignIn({
        description: doc.description,
        details: _.map(lines, (obj) => {
          return {
            procedure: obj.procedure.id,
            code: obj.procedure.code,
            description: obj.procedure.name,
            price: _.get(_.find(_.get(this.pricing, 'lines'), (p) => {
              return p.procedure.code == obj.code
            }), 'value', obj.price),
            qte: obj.non_billed_qte,
            discount: obj.discount || 0,
            discount_amount_from_global: _.get(obj, 'discount_amount_from_global', 0),
            discount_from_global: _.get(obj, 'discount_from_global', 0),
            source_qte: obj.qte,
            tariff: _.get(obj, 'tariff', _.get(this.convention, 'tariff', {tp: 0, tm: 100}))
          };
        })

      }, this.getDocumentFilter(doc.pk, type)));
    } else {
      _.forEach(lines, (obj) => {
        let _dIdx = _.findIndex(this.modelLines[_idx].details, {code: obj.procedure.code});
        if (_dIdx < 0) {
          this.modelLines[_idx].details.push({
            procedure: obj.procedure.id,
            code: obj.procedure.code,
            description: obj.procedure.name,
            discount: obj.discount || 0,
            discount_amount_from_global: _.get(obj, 'discount_amount_from_global', 0),
            discount_from_global: _.get(obj, 'discount_from_global', 0),
            price: _.get(_.find(_.get(this.pricing, 'lines'), (p) => {
              return p.procedure.code == obj.code
            }), 'value', obj.price),
            qte: obj.non_billed_qte || 0,
            source_qte: obj.qte,
            tariff: _.get(obj, 'tariff', _.get(this.convention, 'tariff', {tp: 0, tm: 100}))
          });
        }
      });
    }
  }

  handleLinesTariff(tariff, exceptions = [], force = false) {
    if (this.cls === 'Invoice') {
      _.forEach(this.modelLines, (l) => {
        _.forEach(l.details, function (d) {
          let ex = _.chain(exceptions).find((e) => {
            return e.procedure.code == d.procedure.code
          }).pick('tm', 'tp').value();
          return d.tariff = force ? (!_.isEmpty(ex) ? ex : tariff) : (!d.tariff ? (!_.isEmpty(ex) ? ex : tariff) : d.tariff);
        }.bind(this));
      });
    } else {
      _.forEach(this.modelLines, (l) => {
        return l.tariff =  l.tariff ?  l.tariff : _.cloneDeep(tariff);
      });
    }
    this.linesSource$ = of(this.lineDisplayValue(this.modelLines));
  }

  handleLinesPrice(lines) {
    if (this.cls === 'Invoice') {
      _.forEach(this.modelLines, (l) => {
        _.forEach(l.details, function (d) {
          return d.price = _.get(_.find(lines, (p) => {
            return p.procedure.code == d.code
          }), 'value', d.price);
        }.bind(this));
      });
    } else {
      _.forEach(this.modelLines, (l) => {
        return l.price = _.get(_.find(lines, (p) => {
          return p.procedure.code == l.code
        }), 'value', l.price);
      });
    }
    this.linesSource$ = of(this.lineDisplayValue(this.modelLines));
  }

  applyConvention() {
    this.handleLinesTariff(this.convention.tariff, this.convention.exceptions, true);
  }
}
