import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import * as _ from 'lodash';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {LARGE_DIALOG} from '@ft/core';

import * as moment from 'moment';
import {paymentLinesHeaders} from '../../utils/lines-headers';
import {TableColumnDefinition} from '@ft/table';
import {MatDialog} from "@angular/material/dialog";

@Component({
  selector: 'ftb-payment-lines',
  templateUrl: './payment-lines.component.html',
  styleUrls: ['./payment-lines.component.scss']
})
export class PaymentLinesComponent implements OnInit {

  private _lines = new BehaviorSubject([]);


  @Input() set lines(value: any[]) {
    this.linesSource$ = of(value);
    this._lines.next(value);
  }

  get lines() {
    return this._lines.getValue();
  }

  @Input() cls;
  @Input() hideActions;
  @Input('model') payment;
  @Input() showAmounts: Boolean = true;
  @Output() linesChange = new EventEmitter();
  @Output() dissociateLineHandler = new EventEmitter();
  @Output() actionHandler = new EventEmitter();

  organizationLinesHeaders: TableColumnDefinition[] = [
    {
      label: "states.patient_fullname",
      type: "text",
      key: "paid_doc.patient.full_name"
    },
    {
      label: "pec.imatr_number",
      type: "text",
      key: "imatr_number",
      callBack: (row) => {
        return this.handlePatientImatr(row);
      }
    }
  ];
  beneficiary = null;


  @Input('beneficiaryType') set handleBeneficiaryType(value) {
    if (value == 'organization') {
      let paymentOrganizationLinesHeaders = _.cloneDeep(paymentLinesHeaders);
      paymentOrganizationLinesHeaders.splice(1, 0, ...this.organizationLinesHeaders);
      this.handleColumns(paymentOrganizationLinesHeaders);
    }
    else {
      this.handleColumns(paymentLinesHeaders);
    }
  }

  @Input('beneficiary') set handleBeneficiary(value) {
    this.beneficiary = value;
  }

  displayedColumns = [];
  encasement_col: TableColumnDefinition;

  actionColumn: TableColumnDefinition = {
    label: '', key: 'actions', type: 'actions', actions: [
      {
        class: 'mat-warn',
        icon: 'mdi-close-circle',
        method: (item, index, ev) => {
          return this.removeLine(item, index);
        },
      }
    ]
  };

  linesColumns: BehaviorSubject<TableColumnDefinition []>;

  linesSource$: Observable<any[]> = of([]);


  extendedColHandler = (index, item) => item.is_comment === true || item['details'] && item.details.length > 0;

  constructor(private dialog: MatDialog) {
  }

  ngOnInit() {
    this.encasement_col = {
      'key': 'encasement_amount',
      'label': 'payment.encasement_amount',
      'type': 'number',
      'editable': this.hideActions,
      'numberConfig': 'float',
      'dbClick': (row) => {
        return this.encasementDbClick(row);
      },
      'min': 0
      // 'max': (row) => {
      //   return this.setMax(row);
      // },
    };
    this.handleColumns(paymentLinesHeaders);
  }

  handleColumns(paymentLinesHeaders) {
    this.linesColumns = new BehaviorSubject<TableColumnDefinition []>(_.concat(_.cloneDeep(paymentLinesHeaders), this.encasement_col, !this.hideActions ? this.actionColumn : []));
  }

  onLinesChange(data) {
    this.lines = data;
    this.linesSource$ = of(this.lines);
  }


  removeLine(row, idx) {
    this.lines.splice(idx, 1);
    this.linesSource$ = of(this.lines);
    this.dissociateLineHandler.emit(row);
  }

  getTotal() {
    let total = _.reduce(this.lines, sumTotal, 0);
    return _.isNil(total) ? 0 : total;

    function sumTotal(result, value) {
      return result + value['encasement_amount'];
    }
  }

  paymentRemainingAmount() {
    let model_amount = this.payment.received_amount - this.payment.consumed_amount;
    return model_amount - this.getTotal() > 0 ? model_amount - this.getTotal() : 0;
  }


  handlePaymentLine(row) {
    let paid_idx = _.findIndex(this.payment.lines, function (item) {
      if (item['paid_doc'].number == row.paid_doc.number && item['paid_doc']._module == row.paid_doc._module && item['paid_doc']._model == row.paid_doc._model) return item;
    });

    if (paid_idx == -1) {
      this.payment.lines.push(row);
    }
    else {
      _.update(this.payment.lines, `[${paid_idx}].encasement_amount`, function (encasement_amount) {
        return encasement_amount + row.encasement_amount;
      });
    }


  }

  encasementDbClick(row) {
    this.actionHandler.emit({action: 'encasementDbClick', data: row});
  }

  setMax(row) {
    // let remaining_amount = this.paymentRemainingAmount();
    // return remaining_amount < row.remaining_amount ? remaining_amount : row.remaining_amount;
  }

  handlePatientImatr(row) {
    return this.beneficiary ? _.chain(row).get('paid_doc.patient.medical_insurances').find((e) => {
      return e.insurance.id == this.beneficiary.id
    }).get('imatr_number').value() : null;
  }
}
