import {Component, OnInit} from '@angular/core';
import {DocumentsListComponent} from "../../../shared/components/documents-list/documents-list.component";
import * as _ from "lodash";
import {get, assign} from "lodash";

@Component({
  selector: 'ftb-invoice-list',
  templateUrl: '../../../shared/components/documents-list/documents-list.component.html',
  styleUrls: ['../../../shared/components/documents-list/documents-list.component.scss']
})
export class InvoiceListComponent extends DocumentsListComponent {

  actions = [
    {
      class: 'mat-primary',
      icon: 'mdi-currency-usd',
      tooltip: 'shared.pay',
      method: (item, index, ev) => {
        return this.handlePayment(item);
      },
      disabled: (item) => {
        return _.get(item, 'is_paid') || !_.get(item, 'state.is_valid')
      },
      acl: {resource: 'payment', action: 'create', behavior: 'disabled'}
    }];

  handlePayment(item) {
    let paymentLines = [];
    let beneficiary = null;
    let patient = null
    let organization = null;
    let received_amount = 0;
    let beneficiary_type = null;
    if (!item['is_paid']) {
      this.subscription = this.sharedService.handleRemainingAmount('invoice', get(item, 'id')).subscribe(data => {
        item = assign(item, {'remaining_amount': data});
        received_amount = _.get(item, 'remaining_amount');
        paymentLines.push(this.handlePaymentLine('invoicing.models', 'Invoice', item));
        beneficiary_type = item.organization ? 'organization' : 'patient';
        if (item.patient || item.organization) {
          if (beneficiary_type === 'patient') {
            patient = item.patient
          }
          else {
            organization = item.organization;
          }
        }

        if (!_.isEmpty(paymentLines)) {
          this.routerDataService.routerData = {
            paymentData: {
              paymentLines,
              beneficiary_type,
              patient,
              organization,
              payer_type: beneficiary_type,
              received_amount
            }
          };
          this.router.navigate(['/payment/payments/payment-form/']);
        }
      })
    }
  }

  handlePaymentLine(_pkg, _model, doc) {
    return {
      total_amount: doc.taxed_amount,
      paid_doc: _.assignIn(doc, {_module: _pkg, _model: _model}),
      encasement_amount: 0.0,
      remaining_amount: doc.remaining_amount
    };
  }
}
