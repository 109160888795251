
<div fxFlex class="document-lines-container">
  <!--<ft-body-table [settings]='linesSettings' [ObservableData]='tableDataChange'-->
                 <!--(linesChange)="onLinesChange($event)"  [isExtendedCol]='extendedColHandler'></ft-body-table>-->
  <ft-generic-body-table fxFlex
                         [remote]="false"
                         [source]="linesSource$"
                         [selectable]="false"
                         [hasPagination]="true"
                         [columns]="linesColumns | async"
                         [multiActions]="[]"
                         [extendedHandler]="extendedColHandler"
  >
  </ft-generic-body-table>
</div>

