<mat-form-field fxFlex>
  <input readonly matInput [placeholder]="label|translate" [(ngModel)]="currentModel" [readonly]="true" (change)="handleChange()" [required]="is_required" [disabled]="is_disabled"/>
  <button *ngIf="addDialog" matSuffix mat-icon-button (click)="showDialog(true)" [disabled]="is_disabled" type="button" color="accent">
    <mat-icon fontSet="mdi" fontIcon="mdi-plus"></mat-icon>
  </button>
  <button matSuffix mat-icon-button (click)="showDialog()" [disabled]="is_disabled" type="button" color="primary">
    <mat-icon fontSet="mdi" fontIcon="mdi-magnify"></mat-icon>
  </button>

  <button *ngIf="currentModel" matSuffix mat-icon-button (click)="reset()" [disabled]="is_disabled" type="button"  color="warn">
    <mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
  </button>
</mat-form-field>

