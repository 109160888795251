export const lang = {
  'states_list': 'Liste des États',
  states: 'États',
  daily_activities: 'Activité journalière',
  personalized_states: 'Rapports personnalisés',
  checkout: 'Caisse',
  values: 'Valeurs',
  value: 'valeur',
  paid: 'payé',
  invoiced: 'facturé',
  remaining: 'Impayé',
  patients: 'Patients',
  treasury: 'TRESORERIE',
  'daily_immediate_payments': "Réglements Immédiats",
  nbr: 'Nbr',
  amount: 'Montant',
  anterior_payments: 'Réglements Antérieurs',
  general_activity: 'État générale',
  daily_activity: 'Activité journalière',
  visits: 'Nbr.Visites',
  taxed_amount: 'Montant dû',
  paid_amount: 'Montant encaissé',
  remaining_amount: 'Reste à régler',
  daily_paid_amount: 'Total du jour',
  anterior_paid_amount: 'Total Dos. Ant.',
  full_paid_amount: 'Solde final',
  patient_payments: 'Paiements par patient',
  organization_payments: 'Paiements par Tier payant',
  care_requests_state: 'État de suivi',
  patient_fullname: 'Patient',
  exams: 'Examen',
  exams_date: 'Date d\'examen',
  send_date: 'Date d\'envoi',
  payment_date: 'Date de règlement',
  "aging_balance": "Balance âgée",
  "customer_recovery": "État de relance",
  "b_15": "+15j",
  "b_30": "+30j",
  "b_60": "+60j",
  "b_120": "+120j",
  "m_15": "-15j",
  "m_30": "-30j",
  "m_60": "-60j",
  "m_120": "-120j",
  daily_invoices: "Activité journalière",
  paid_total: "Total payé",
  show_percents: "Pourcentages Centre/Médecin",
  center_percent: "Centre %",
  physician_percent: "Médecin %",
  base_percent: "Base %",
  received_amount: "Montant encaissé",
  anterior_activity: "Activité antérieur",
  doc_number: "N°.Exam",
  periodic_activity: "Activité périodique",
  taxed_amount_sum: "Total dû",
  paid_amount_sum: "Total encaissé",
  exams_count: "Nombre des examens",
  has_invoice: "Facturé",
  total_has_invoice: "Nombre des examens Facturés",
  remaining_amount_sum: "Total des restes",
  daily_state: "État journalier"
}
