export const PAYMENT_CONDITIONS = [
  {key: 'net_10_days', value: 10},
  {key: 'net_30_days', value: 30},
  {key: 'net_120_days', value: 120},
  {key: 'net_60_days', value: 60},
  {key: 'net_90_days', value: 90},
  {key: 'end_of_month_the_10', value: 25},
  {key: 'end_of_month_the_25', value: 40},
  {key: '30_end_of_month', value: 45},
  {key: '45_end_of_month', value: 60},
  {key: '60_end_of_month', value: 75},
  {key: '90_end_of_month', value: 105},
  {key: '30_end_of_month_the_10', value: 55},
  {key: '30_end_of_month_the_15', value: 60},
  {key: '30_end_of_month_the_25', value: 70},
  {key: '60_end_of_month_the_10', value: 85},
  {key: '60_end_of_month_the_25', value: 100},
  {key: '90_end_of_month_the_10', value: 115}
];
