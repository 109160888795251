import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {Subscription} from "rxjs";
import {BillingService} from "../../../shared/services/billing.service";
import * as _ from "lodash";
import {get} from "lodash";
import {ORGANIZATION_VIEW} from "../../../settings/utils/views-settings";

@Component({
  selector: 'ftb-patient-dialog',
  templateUrl: './patient-dialog.component.html',
  styleUrls: ['./patient-dialog.component.scss']
})
export class PatientDialogComponent implements OnInit {
  loader: Subscription;
  patient: any = {};
  organizationInputSettings = _.assignIn(_.cloneDeep(ORGANIZATION_VIEW), {selectable: false});

  constructor(public dialogRef: MatDialogRef<PatientDialogComponent>, private billingService: BillingService,
              @Inject(MAT_DIALOG_DATA) public data: any, protected dialog: MatDialog) {
  }

  ngOnInit() {
    this.patient = this.data ? this.data : {
      contact_info: {phone_numbers: []},
      // external_id: "None",
      national_id: null,
      source: 'FtBilling',
      medical_insurances: [],
      address: {content: null},
      gender: 'M'
    };
    if (!_.has(this.patient, 'contact_info')) {
      this.patient.contact_info = {phone_numbers: []};
    }
    if (!_.has(this.patient, 'address')) {
      this.patient.address = {content: null};
    }

  }

  save() {
    if (_.isEmpty(this.patient.national_id)) {
      this.patient.national_id = null;
    }
    this.loader = this.billingService.saveBillingDoc(this.patient, 'patient').subscribe(data => {

      this.dialogRef.close(data)
    });
  }

  listChange(value) {
    get(value, 'gender') == 'FEMALE' ? this.patient.gender = 'F' : this.patient.gender = 'M';
  }
}
