export const lang = {
  invoices: 'Invoices',
  invoice: 'Invoice',
  quotations: 'Quote',
  invoice_list: 'Invoices List',
  invoice_header_number: 'Invoice n °',
  invoice_number: 'Invoice n°',
  applied_tariff: 'Convention',
  payment_deadline: 'Payment date',
  fees_notes_list: 'Notes of Fees',
  fees_note: 'Honorary notes',
  fees_note_header_number: 'Note N°',
  fees_note_number: 'Note.N°',
  quotation_header_number: 'Quote n°',
  quotation_list: 'Quotation list',
  quotation_number: 'N°. Quote',
  quotation_pattern: 'Pattern',
  valid_duration: 'Validity',
  care_sheet_number:"N°. Mutual",
  care_sheet_header_number:"MUTUAL N°",
  care_sheet_list:"Mutuals list",
  full_list:"List of ",
  other_name:"Calling",
  certificates:"Certificates",
  certificate:"Certificate",
  certificate_number:"Certificate.N°",

};
