<div mat-dialog-content class="ft-content">
  <div fxLayout="row" fxLayoutGap="6px"  >
    <p class="confirm-modal-text">{{message_text|translate}}</p>
  </div>
</div>

<mat-dialog-actions fxLayout="row">
  <span fxFlex></span>
  <button type="button" mat-button color="primary"   [mat-dialog-close]="true">
    <span>{{'shared.ok' | translate}}</span>
  </button>
</mat-dialog-actions>
