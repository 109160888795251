import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {BillingService} from '../../../shared/services/billing.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'ftb-procedure-dialog',
  templateUrl: './procedure-dialog.component.html',
  styleUrls: ['./procedure-dialog.component.scss']
})
export class ProcedureDialogComponent implements OnInit {
  procedure = null;
  public loader: Subscription;

  constructor(private billingService: BillingService, public dialogRef: MatDialogRef<ProcedureDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit() {
    this.procedure = this.data ? this.data : {price: 0, tnr: 0};
  }

  save() {
    this.loader = this.billingService.saveBillingDoc(this.procedure, 'procedure').subscribe(data => this.dialogRef.close(data));
  }
}
