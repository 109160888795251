import {Component, OnInit, ViewChild} from '@angular/core';
import {NavigationEnd, NavigationError, NavigationStart, Router} from '@angular/router';
import * as _ from 'lodash';
import {RouterDataService} from '../../../shared/services/router-data.service';
import {MatTabNav} from "@angular/material/tabs";

@Component({
  selector: 'ftb-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {
  @ViewChild('navBar', {static: true}) _navBar: MatTabNav;
  navLinks: any[] = [];
  activateList = false;

  constructor(private router: Router,   private routerDataService: RouterDataService) {
    this.navLinks = [
      {
        name: 'invoices',
        label: 'invoicing.invoices',
        isDisabled: () => false,
        path: '/invoicing/invoice/',
        fromPath: '/invoicing/invoice/invoice-form/',
        icon: 'mdi-receipt-text',
        params: () => {
        }
      },
      {
        name: 'fees_notes',
        label: 'shared.fees_notes',
        isDisabled: () => false,
        path: '/invoicing/fees-note/',
        fromPath: '/invoicing/fees-note/fees-note-form/',
        icon: 'mdi-note-text',
        params: () => {
        }
      },
      {
        name: 'care_sheets',
        label: 'shared.care_sheets',
        isDisabled: () => false,
        path: '/invoicing/care-sheet/',
        fromPath: '/invoicing/care-sheet/care-sheet-form/',
        icon: 'mdi-file-multiple',
        params: () => {
        }
      },
      {
        name: 'quotations',
        label: 'invoicing.quotations',
        isDisabled: () => false,
        path: '/invoicing/quotation/',
        fromPath: '/invoicing/quotation/quotation-form/',
        icon: 'mdi-file-document',
        params: () => {
        }
      },
      {
        name: 'certificates',
        label: 'invoicing.certificates',
        isDisabled: () => false,
        path: '/invoicing/certificate/',
        fromPath: '/invoicing/certificate/certificate-form/',
        icon: 'mdi-file-certificate',
        params: () => {
        }
      }
    ];

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
            this.activateList =   _.includes(event.urlAfterRedirects, '-form')? true : false;
      }
    });
  }

  ngOnInit() {



  }

  handleNew(ev) {
    this.routerDataService.routerData = null;
    this.router.navigate([_.get( this.getCurrentNavLink(), 'fromPath')], {replaceUrl: false});
    this.activateList = true;
  }

  handleList() {
    this.activateList = false;
    this.router.navigate([_.get( this.getCurrentNavLink(), 'path')], {replaceUrl: true});
  }

  getCurrentNavLink(){
    return this.navLinks[ this._navBar.focusIndex];

  }
}
