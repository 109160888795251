import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import * as _ from "lodash";
import {PATIENTS_VIEW} from "../../../patient/utils/views-settings";
import {Subscription} from "rxjs";
import {CONVENTION_VIEW, ORGANIZATION_VIEW} from "../../../settings/utils/views-settings";
import {CARE_REQUEST_VIEW} from "../../../pec/utils/views-settings";
import {getLineAmount} from "../../../shared/utils/functions";
import {HEIGHT_ANIMATION, LARGE_DIALOG, SCALE_ANIMATION} from "@ft/core";
import {LinesSelectDialogComponent} from "../../../shared/dialogs/lines-select-dialog/lines-select-dialog.component";
import {SelectDocumentsDialogComponent} from "../../../shared/dialogs/select-documents-dialog/select-documents-dialog.component";
import {DailyWorkflowService} from "../../services/daily-workflow.service";
import * as moment from "moment";
import {PatientService} from "../../../patient/services/patient.service";
import {ActivatedRoute} from "@angular/router";
import {BillingService} from "../../../shared/services/billing.service";
import {PecService} from "../../../pec/services/pec.service";

@Component({
  selector: 'ftb-add-request-dialog',
  templateUrl: './add-request-dialog.component.html',
  styleUrls: ['./add-request-dialog.component.scss'],
  animations: [
    HEIGHT_ANIMATION
  ]
})
export class AddRequestDialogComponent implements OnInit {
  patientInputSettings = _.assignIn(_.cloneDeep(PATIENTS_VIEW), {selectable: false});
  organizationInputSettings = _.assignIn(_.cloneDeep(ORGANIZATION_VIEW), {selectable: false});
  conventionInputSettings = _.assignIn(_.cloneDeep(CONVENTION_VIEW), {selectable: false});

  pecInputSettings = _.assignIn(_.cloneDeep(CARE_REQUEST_VIEW), {
    selectable: false,
    query: [{key: 'state.is_valid', logical_operator: 'and', operator: '$eq', value: true},
      {key: 'answers', logical_operator: 'and', operator: '$elemMatch', value: {'type': 'final_agreement_received'}}]

  });

  model: any = {
    doc_source: 'FtBilling',
    financial_detail: {},
    debtor: 'PATIENT',
    procedures: [],
    global_discount_amount: 0,
    global_discount: 0,
    patient: {contact_info: {phone_numbers: []}, national_id: null, source: 'FtBilling', gender: 'M'},
    doc_date: moment(),
    request_date_time: moment()
  };
  loader = Subscription;
  patient: any = {};
  payment_status = 'unpaid';
  pricingList: any = [];
  nonConsumedPecs = [];


  constructor(public dialogRef: MatDialogRef<AddRequestDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private dialog: MatDialog,
              private dailyWorkflowService: DailyWorkflowService,
              private patientService: PatientService,
              private billingService: BillingService,
              private pecService: PecService
  ) {
  }

  ngOnInit() {
    if (_.get(this.data, 'pk')) {
      this.billingService.getBillingDoc('billing-request', _.get(this.data, 'pk')).subscribe(data => {
        this.model = data;
        this.initContactInfo();
      });
    }
    this.billingService.getBillingDocs('pricing').subscribe(data => {
      this.pricingList = data;
    });
    this.payment_status = _.get(this.data, 'payment_status', null);
  }

  initContactInfo() {
    if (!_.get(this.model, 'patient.contact_info')) {
      this.model.patient.contact_info = {phone_numbers: []}
    }
    if (!_.get(this.model, 'financial_detail')) {
      this.model.financial_detail = {};
    }
  }


  save() {
    this.dailyWorkflowService.handleBillingRequest(this.model).subscribe(data => {
      if (!_.get(this.model, 'patient.id') && !_.get(this.model, 'patient.external_id')) {
        this.patientService.handlePatientNotification(_.get(this.model, 'patient.id')).subscribe(data => _.noop);
      }
      if (this.model.doc_id) {
        this.dailyWorkflowService.handleChangeNotification(this.model.id).subscribe(data => {
          _.noop()
        });
      }
      this.model = data;
      if (this.model.doc_id) {
        console.log(this.model)
        this.dialogRef.close(true);
      }
    });
  }

  scheduleRequest() {
    this.dailyWorkflowService.handleBillingRequest(this.model).subscribe(data => {
      this.model = data;
      // if (_.get(this.model, 'patient.external_id')) {
      this.dailyWorkflowService.handleChangeNotification(this.model.id).subscribe(data => {
        if (data) this.dialogRef.close(true);
      });

    });

  }

  getFullAmount() {
    return _.chain(this.model.procedures).sumBy((row) => {
      return getLineAmount(row);
    }).value();
  }

  handleDiscount(is_amount = false) {
    let taxed_amount = this.getFullAmount();
    if (taxed_amount) {
      if (is_amount) {
        this.model.global_discount = _.get(this.model, 'global_discount_amount', 0) * 100 / taxed_amount;
      } else {
        this.model.global_discount_amount = _.get(this.model, 'global_discount', 0) * taxed_amount / 100;
      }
      if (this.model.global_discount) {
        this.handleLinesGlobalDiscount(taxed_amount);
      }
    }
  }

  handleLinesGlobalDiscount(taxed_amount) {
    _.forEach(this.model.procedures, (l) => {
      l.discount_amount = (_.get(l, 'discount', 0) * (l.qte * l.price) / 100);
      let st1 = (l.qte * l.price) - l.discount_amount;
      l.discount_amount_from_global = _.get(this.model, 'global_discount_amount', 0) * (st1 * 100 / taxed_amount) / 100;
      l.discount_from_global = l.discount_amount_from_global * 100 / st1;
    })
  }

  onPatientChange = (data) => {
    this.model.patient = data;
  }

  onDebtorChange(data) {
    if (this.model.debtor == 'PEC') {
      this.pecService.getNonFullyConsumedPecs({
        patient: _.get(this.model, 'patient.id'),
        organization: _.get(this.model, 'organization.id')
      }).subscribe(data => {
          if (data) {
            this.nonConsumedPecs = data;
            this.pecInputSettings = _.assignIn(_.cloneDeep(CARE_REQUEST_VIEW), {
              selectable: false,
              query: [
                {key: '_id', logical_operator: 'and', operator: '$in', value: _.map(data, '_id')}]
            });
          }
        }
      )
    }
  }

  handlePricingChange(value) {
    this.model.financial_detail.pricing = value;
  }

  searchPatient() {
    this.dialog.open(SelectDocumentsDialogComponent, _.assign(LARGE_DIALOG, {
      data: _.assignIn(this.patientInputSettings, {
        query: !_.get(this.model, 'patient.id') ? _.reject([
          {
            value: _.get(this.model, 'patient.first_name', null),
            operator: '$eq',
            logical_operator: 'or',
            key: 'first_name',
            type: 'text'
          },
          {
            value: _.get(this.model, 'patient.last_name', null),
            operator: '$eq',
            logical_operator: 'or',
            key: 'last_name',
            type: 'text'
          },
          {
            value: _.get(this.model, 'patient.national_id', null),
            operator: '$eq',
            logical_operator: 'or',
            key: 'national_id',
            type: 'text'
          },
        ], (e) => {
          return _.isEmpty(e)
        }) : []
      }),
      disableClose: true
    })).afterClosed().subscribe(res => {
      if (res) {
        this.model.patient.national_id = null;
        this.model.patient.first_name = null;
        this.model.patient.last_name = null;
        setTimeout(() => {
          this.model.patient = res;
          this.initContactInfo();
          this.handlePecFilter(true);
        }, 50)

      }
      // this.model = res;
      // this.modelChange.emit(res);
      // this.currentModel = _.get(this._model, this.value);
    });
  }

  comparePricingCondition(o1, o2) {
    return o1 && o2 ? o1.id == o2.id : false;
  }


  handlePecChange(value) {
    let codes = _.chain(this.nonConsumedPecs).find({'_id': _.get(value, 'id')}).get('non_consumed', []).value();
    if (value) {
      if (_.get(this.model, 'financial_detail.pec')) {
        this.model.organization = _.get(this.model, 'financial_detail.pec.organization')
      }
      this.model.procedures = this.model.procedures.concat(_.chain(value.details).filter((d: any) => {
        return _.includes(codes, d.code) && _.get(d, 'confirmed_amount', 0) > 0 && !_.chain(this.model).get('procedures').find({code: d.code}).value()
      }).forEach((d) => {
        let p = _.get(d, 'confirmed_amount', 0) * 100 / getLineAmount(d);
        d.tariff = {tp: p, tm: 100 - p}
        // d.price = d.confirmed_amount
      }).value());
    }
  }

  handlePecFilter(ev) {
    if (_.get(this.model, 'patient.id')) {
      let idx = _.findIndex(this.pecInputSettings.query, {key: 'patient'});
      if (idx === -1) {
        this.pecInputSettings.query.push({
          key: 'patient',
          logical_operator: 'or',
          operator: '$eq',
          value: _.get(this.model, 'patient.id')
        })
      } else {
        this.pecInputSettings.query[idx] = {
          key: 'patient',
          logical_operator: 'or',
          operator: '$eq',
          value: _.get(this.model, 'patient.id')
        };
      }
    }
    if (_.get(this.model, 'organization.id')) {
      let ix = _.findIndex(this.pecInputSettings.query, {key: 'organization'});
      if (ix === -1) {
        this.pecInputSettings.query.push({
          key: 'organization',
          logical_operator: 'and',
          operator: '$eq',
          value: _.get(this.model, 'organization.id')
        })
      } else {
        this.pecInputSettings.query[ix] = {
          key: 'organization',
          logical_operator: 'and',
          operator: '$eq',
          value: _.get(this.model, 'organization.id')
        }
      }
      if (_.get(this.model, 'debtor') == 'THIRD_PARTY_PAYMENT') {
        this.model.financial_detail.convention = _.chain(this.model).get('organization.conventions').find({is_default: true}).get('convention', this.model.financial_detail.convention).value();
      }
      this.model.financial_detail.pricing = !_.isEmpty(_.get(this.model, 'organization.pricing')) ? _.get(this.model, 'organization.pricing') : this.model.financial_detail.pricing;
    }
  }
}
