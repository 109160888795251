import {Component, Input, OnInit} from '@angular/core';
import * as _ from "lodash";
import {SharedService} from "../../services/shared-service";
import {Subscription} from "rxjs";
import {MatSnackBar} from "@angular/material/snack-bar";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'ftb-import-csv-button',
  templateUrl: './import-csv-button.component.html',
  styleUrls: ['./import-csv-button.component.scss']
})
export class ImportCsvButtonComponent implements OnInit {
  subscription: Subscription;
  public files: File[] = [];
  @Input() importCallBack:Function;
  @Input() importTopic :string;


  constructor(private sharedService: SharedService,protected snackBar: MatSnackBar, private translateService: TranslateService) { }

  ngOnInit() {
  }

  importFromCsv(files: File[]) {
    let items = _.filter(files, i => i instanceof File);

    if (items.length > 0) {
      let extension = items[0].name.split('.').pop();
      if (extension == 'csv') {
        this.sharedService.readFile(items[0]).subscribe(fileString => {
          this.subscription = this.sharedService.handleImport(fileString, this.importTopic,_.get(items[0].name.split('.'),'0', 'NoName') ).subscribe(data => {
              this.files = [];
              this.snackBar.open(this.translateService.instant('shared.upload_success'), null, {
                duration: 2000,
              });
            this.importCallBack();
            },
            () => {
              this.snackBar.open(this.translateService.instant('shared.upload_file_error'), null, {
                duration: 2000,
              });
            }
          );
        })
      }
    }
  }

}
