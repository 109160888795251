<div fxFlex fxLayout="column">
  <div class="document-lines-container">
    <ft-generic-body-table fxFlex
                           [remote]="false"
                           [source]="linesSource$"
                           [selectable]="false"
                           [hasPagination]="true"
                           [columns]="linesColumns | async"
                           [multiActions]="linesActions"
                           [extendedHandler]="extendedColHandler"
                           [hasSearch]="hasSearch"
                           [pageSize]="pageSize"
    >
    </ft-generic-body-table>
  </div>
  <span fxFlex></span>
  <div fxLayout="row" *ngIf="showAmounts">
    <div *ngIf="model?.state?.is_valid" class="amount-text" fxLayoutAlign="center center" fxLayoutGap="2px">
      <span>{{('shared.stop_document_at_amount'|translate)  }} <b>{{model?.text_amount}} </b></span>
    </div>
    <span fxFlex></span>
    <div class="mn-amount ft-padding">
      <div class="amounts" fxLayoutGap="8px">
        <div>
          <span  *ngIf="showDetailedAmounts">{{'shared.total'|translate}}</span>
          <span  *ngIf="showDetailedAmounts">{{'shared.global_discount'|translate}}</span>
          <span>{{'shared.amount'|translate}}</span>
          <span *ngIf="showDetailedAmounts">{{'shared.organization_part'|translate}}</span>
          <span *ngIf="showDetailedAmounts">{{'shared.patient_part'|translate}}</span>
        </div>
        <div>
          <span  *ngIf="showDetailedAmounts">:</span>
          <span  *ngIf="showDetailedAmounts">:</span>
          <span>:</span>
          <span *ngIf="showDetailedAmounts">:</span>
          <span *ngIf="showDetailedAmounts">:</span>
        </div>
        <div>
          <span  *ngIf="showDetailedAmounts">{{getAmountNoGlobalDiscount() | ftNumber: "currency"}}</span>
          <span  *ngIf="showDetailedAmounts">{{getGlobalDiscount() | ftNumber: "currency"}}</span>
          <span>{{getAmount() | ftNumber: "currency"}}</span>
          <span *ngIf="showDetailedAmounts">{{getOrganizationAmount() | ftNumber: "currency"}}</span>
          <span *ngIf="showDetailedAmounts">{{getPatientAmount() | ftNumber: "currency"}}</span>
        </div>
      </div>

    </div>
  </div>

</div>
