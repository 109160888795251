export const lang = {
  payment_list: 'payments list',
  payment: 'payment ',
  payment_header_number: 'Payment N°.',
  payment_number: 'Payment N°',
  deadline: 'Due date',
  bank: 'Bank',
  ref: 'Ref',
  received_amount: 'Received amount',
  init_amount: 'Initial amount',
  consumed_amount: 'Consumed amount',
  remaining_amount: 'Remaining amount',
  be_paid_document: 'Documents to be paid',
  payment_lines: 'Payment lines',
  encasement_amount: "Encasement amount",
  total_amount: "Amount",
  paid_doc: "Document N°",
  line_remaining_amount: "Remains to be paid",
  payment_outstanding_amount: "Remaining amount",
  header_invalidate: "Invalidate header",
  calculate: "Calculate",
  dissociate: "Dissociate",
  external_doc_id: "HIS/RIS Document N°",
  payment_receipt: "Payment receipt"
};
