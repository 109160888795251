import {TableColumnDefinition} from '@ft/table/table/models/table-definitions';
import * as _ from "lodash";

export const PROCEDURE_COLUMNS: TableColumnDefinition[] = [
  {
    "label": "shared.name",
    "key": "name",
    "sortBy": "name",
    "type": "text",
    "is_global_searchable": true
  },
  {
    "label": "settings.code",
    "key": "code",
    "sortBy": "code",
    "type": "text",
    "is_global_searchable": true
  },
  {
    "label": "settings.ngap_code",
    "key": "NGAP_code",
    "sortBy": "NGAP_code",
    "type": "text",
    "is_global_searchable": true
  },
  {
    "label": "settings.procedure_price",
    "key": "price",
    "sortBy": "price",
    "type": "number",
    "is_global_searchable": true,
    'numberConfig':'currency'
  },
  {
    "label": "settings.is_refundable",
    "key": "is_refundable",
    "type": "boolean",
    "is_global_searchable": false,
    'icon': 'mdi-checkbox-blank-circle',
    'style': (row) => {
      return _.get(row, 'is_refundable') ? {'color': '$ft-success-color', 'font-size': '20px'} : {
        'color': 'grey',
        'font-size': '20px'
      };
    }
  }
]
