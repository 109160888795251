import {TableColumnDefinition} from '@ft/table/table/models/table-definitions';

export const CONVENTION_COLUMNS: TableColumnDefinition[] = [
  {
    'label': 'settings.name',
    'key': 'label',
    'sortBy': 'label',
    'type': 'text',
    'is_global_searchable': true
  },
  {
    'label': 'settings.tp',
    'key': 'tariff.tp',
    'sortBy': 'tariff.tp',
    'type': 'number',
    'is_global_searchable': false
  },
  {
    'label': 'settings.tm',
    'key': 'tariff.tm',
    'sortBy': 'tariff.tm',
    'type': 'number',
    'is_global_searchable': false
  }
];
