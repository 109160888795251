<div fxFlex fxLayout="column">
  <div class="ft-parent-layout mat-elevation-z1 ft-content-margin" fxFlex fxLayout="column">
    <mat-toolbar color="accent">
      <div class="ft-module-icon">
        <button type="button" mat-icon-button>
          <mat-icon fontSet="mdi" fontIcon="mdi-currency-usd"></mat-icon>
        </button>
      </div>
      <h2>
        <span>{{'payment.payment_header_number'|translate}}: {{payment.number}} </span>
        <span *ngIf="paymentForm.disabled">| {{'payment.init_amount'|translate}}: {{payment.received_amount}} </span>
      </h2>
      <span fxFlex></span>
      <button type="button" mat-button class="with-icon" [routerLink]="[routes['list']]">
        <mat-icon fontSet="mdi" fontIcon="mdi-format-list-bulleted"></mat-icon>
        <span>{{'payment.payment_list'|translate}}</span>
      </button>
    </mat-toolbar>
    <div fxFlex fxLayout="column" class="ft-content no-padding">
      <div [ngBusy]="loading"></div>

      <div *ngIf="payment.state?.is_valid" fxLayoutAlign="center center" class="validate-icon">
        <img mat-card-image src="/assets/lock-outline.svg" alt="validation img">
      </div>
      <form #paymentForm="ngForm" fxFlex fxLayout="column" fxLayoutGap="6px">
        <div class="ft-padding" fxLayout="row" fxLayoutGap="6px">
          <div fxFlex fxLayout="column">
            <div fxLayout="row" fxLayoutGap="6px">
              <mat-form-field>
                <input matInput type="text" required [ftSequenceInput]="seqQuery.cls" [ftSequenceToggle]="toggle"
                       name="sequence"
                       placeholder="{{'payment.payment_number'|translate}}" [(ngModel)]="payment.number"/>
                <ft-sequence-toggle [disabled]="payment.id"  matSuffix #toggle [(objectId)]="loadedObject"
                                    [config]="{cls: seqQuery.cls, field: 'number'}">
                </ft-sequence-toggle>
              </mat-form-field>
              <mat-form-field>
                <input matInput ftDate [matDatepicker]="inv_date" [placeholder]="'shared.date'|translate"
                       [(ngModel)]="payment.doc_date" name="doc_date">
                <mat-datepicker-toggle matSuffix [for]="inv_date">
                  <mat-icon matDatepickerToggleIcon fontSet="mdi" fontIcon="mdi-calendar-today"></mat-icon>
                </mat-datepicker-toggle>
                <mat-datepicker #inv_date></mat-datepicker>
              </mat-form-field>

            </div>
            <div fxLayout="row" fxLayoutGap="6px">
              <mat-form-field floatLabel="always" class="ft-radio-field" fxFlex="nogrow">
                <input matInput placeholder="{{'shared.beneficiary'|translate}}" style="display: none">
                <mat-radio-group [(ngModel)]="payment.beneficiary_type" name="b_type"
                                 (change)="onBeneficiaryChange($event)">
                  <mat-radio-button *ngFor="let b_type of ['patient','organization']" [value]="b_type">
                    {{'shared.'+b_type|translate}}
                  </mat-radio-button>
                </mat-radio-group>
              </mat-form-field>
            </div>
            <div fxLayout="row">
              <ftb-select-input *ngIf="payment.beneficiary_type == 'patient'"  fxFlex="50" [required]="true" [disabled]="paymentForm.disabled"
                                [label]="'shared.patient'"
                                [(model)]="payment.patient"
                                value="full_name"
                                [settings]="patientInputSettings"  [dialog]="patientDialog"
                                (modelChange)="handlePatientChange($event)"></ftb-select-input>
              <ftb-select-input  *ngIf="payment.beneficiary_type == 'organization'"   fxFlex="50"  [required]="true" [disabled]="paymentForm.disabled"
                                [label]="'shared.organization'"
                                [(model)]="payment.organization"
                                value="name"
                                [settings]="organizationInputSettings "
                                (modelChange)="handleOrganizationChange($event)"></ftb-select-input>
            </div>
            <div fxLayout="row" fxLayoutGap="6px">
              <mat-form-field floatLabel="always" class="ft-radio-field" fxFlex="no-shrink">
                <input matInput placeholder="{{'shared.payer'|translate}}" style="display: none">
                <mat-radio-group [(ngModel)]="payment.payer_type" name="p_type"
                                 (change)="onPayerChange($event)">
                  <mat-radio-button *ngFor="let p_type of ['patient','organization','other']" [value]="p_type"
                                    [disabled]="payment.beneficiary_type == 'organization'">
                    {{'shared.'+p_type|translate}}
                  </mat-radio-button>
                </mat-radio-group>
              </mat-form-field>


            </div>
            <div fxLayout="row">
              <ftb-select-input    fxFlex="50" [required]="true"
                                *ngIf="payment.beneficiary_type == 'patient' && payment.payer_type == 'organization' "
                                [label]="'shared.organization'"
                                [(model)]="payment.organization"
                                value="full_name"
                                [settings]="organizationInputSettings"
                                (modelChange)="handlePayerChange($event)"></ftb-select-input>
            </div>
            <div fxLayout="row" *ngIf="payment?.payer_type === 'other'" fxLayoutGap="6px">
              <mat-form-field fxFlex="25">
                <input matInput placeholder="{{'shared.first_name'|translate}}"
                       [(ngModel)]="payment.other_payer.first_name" name="first_name">
              </mat-form-field>
              <mat-form-field fxFlex="25">
                <input matInput placeholder="{{'shared.last_name'|translate}}"
                       [(ngModel)]="payment.other_payer.last_name" name="last_name">
              </mat-form-field>
              <mat-form-field fxFlex="25">
                <input matInput placeholder="{{'shared.cin'|translate}}"
                       [(ngModel)]="payment.other_payer.cin" name="cin">
              </mat-form-field>
            </div>
          </div>
          <mat-divider [vertical]="true"></mat-divider>
          <div fxFlex fxLayout="column">
            <div fxLayout="row" fxLayoutGap="6px">
              <!--<ftb-select-list [required]="true" [disabled]="paymentForm.disabled" fxFlex label="shared.payment_mode"-->
              <!--[model]="payment.payment_mode.type"-->
              <!--[cls]="'PaymentModeType'" (valueChange)="listChange($event,'type')"></ftb-select-list>-->
              <mat-form-field fxFlex>
                <mat-select [placeholder]="'shared.payment_mode' | translate" [compareWith]="compareMode"
                            ftDefaultValues="payment_mode"
                            (selectionChange)="handleModeChange($event)"
                            [(ngModel)]="payment.payment_mode.type" name="mode">
                  <mat-option *ngFor="let type of paymentModeTypes" [value]="type">
                    {{'shared.'+type |translate}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field fxFlex>
                <input matInput placeholder="{{'payment.ref'|translate}}"
                       [(ngModel)]="payment.payment_mode.ref" name="p_ref">
              </mat-form-field>
              <mat-form-field>
                <input matInput ftDate [matDatepicker]="d_date" [placeholder]="'payment.deadline'|translate"
                       [(ngModel)]="payment.deadline" name="deadline">
                <mat-datepicker-toggle matSuffix [for]="d_date">
                  <mat-icon matDatepickerToggleIcon fontSet="mdi" fontIcon="mdi-calendar-today"></mat-icon>
                </mat-datepicker-toggle>
                <mat-datepicker #d_date></mat-datepicker>
              </mat-form-field>
              <ftb-select-list [disabled]="paymentForm.disabled" fxFlex label="payment.bank"
                               [model]="payment.payment_mode.bank" [cls]="'Bank'"
                               (modelChange)="listChange($event,'bank')"></ftb-select-list>
            </div>
            <div fxLayout="row" fxLayoutGap="6px">
              <mat-form-field fxFlex>
                <input matInput placeholder="{{'payment.received_amount'|translate}}" [ftNumber]="'float'" [ftAutoFocus]="true"
                       [(ngModel)]="payment.received_amount" (change)="handleReceivedAmountChange()" [min]="1" required
                       name="p_received">
              </mat-form-field>
              <mat-form-field fxFlex>
                <input matInput placeholder="{{'payment.consumed_amount'|translate}}" [ftNumber]="'float'"
                       [(ngModel)]="payment.consumed_amount" [disabled]="true" name="p_consumed">
              </mat-form-field>
              <mat-form-field fxFlex>
                <input matInput
                       placeholder="{{'payment.remaining_amount'|translate}}" [ftNumber]="'float'"
                       [(ngModel)]="payment.remaining_amount" [disabled]="true" name="p_remaining">
              </mat-form-field>
            </div>
            <div fxLayout="row" fxLayoutGap="6px">
              <mat-form-field fxFlex>
                <input matInput placeholder="{{'shared.comment'|translate}}"
                       [(ngModel)]="payment.comment" name="p_ref">
              </mat-form-field>
            </div>
          </div>
        </div>
        <mat-tab-group fxFlex fxLayout="column">
          <mat-tab fxFlex fxLayout="column" label="{{'payment.be_paid_document'|translate}}">
            <div fxLayout="column" fxFlex="" fxLayoutGap="6px" class="ft-padding" >
                <div fxLayout="row">
                    <span fxFlex=""></span>
                    <strong   [ngClass]="{'warning':getCurrentRemainingAmount()<0}">{{'payment.remaining_amount'|translate}} : {{getCurrentRemainingAmount()| ftNumber:'currency'}}</strong>
                </div>
                <ftb-payment-lines fxFlex [(lines)]="paymentLines" [model]="payment" [hideActions]="true" [beneficiaryType]="payment.beneficiary_type" [beneficiary]="payment.organization"
                                (actionHandler)="handleAction($event)"></ftb-payment-lines>
            </div>
          </mat-tab>
          <mat-tab fxFlex label="{{'payment.payment_lines'|translate}}" [disabled]="payment.lines.length==0">
            <ftb-payment-lines fxFlex [(lines)]="payment.lines" [model]="payment" [hideActions]="true"  [beneficiaryType]="payment.beneficiary_type" [beneficiary]="payment.organization"
                               (dissociateLineHandler)="dissociateOneLine($event)" ></ftb-payment-lines>
          </mat-tab>
        </mat-tab-group>
      </form>
    </div>
    <ftb-payment-actions fxFlex="nogrow" [model]="payment" [cls]="cls" (modelChange)="onModelChange($event)" [loadedObject]="loadedObject"
                         [headerDisabled]="paymentForm.disabled" [preLines]="paymentLines"
                         [formInvalid]="(paymentForm.invalid && paymentForm.untouched )|| (!payment.patient && !payment.organization) || getCurrentRemainingAmount()<0"
                         [routes]="routes"  [(busy)]="loading"></ftb-payment-actions>
  </div>
</div>

