import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'ftb-main',
  template: `<router-outlet></router-outlet>`,
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {
  navLinks: any[] = [];

  constructor() {
  }

  ngOnInit() {
  }

}
