export const lang = {
  schedule: "Planifier",
  debtor: "Modalité d'admission",
  taxed_amount: "Montant dû",
  received_amount: "M.Encaissé",
  fence_payment: "Clôturer le paiement",
  fenced_payment_message_success: "Paiement clôturé avec succès",
  cancel_payment_close: "Annuler la clôture de paiement",
  cancel_payment_close_success: "Clôture de paiement annulée avec succès"
};
