import { Component, OnInit } from '@angular/core';
import {AppConfigsService, DrawerLink, ToolbarConfig} from '@ft/core';
import {BillingService} from '../../../shared/services/billing.service';
import {Router} from '@angular/router';
import {DateAdapter} from "@angular/material/core";

@Component({
  selector: 'ftb-main',
  template: `
        <ft-app-toolbar [config]="toolbarConfig"></ft-app-toolbar>
        <router-outlet></router-outlet>
    `,
  styleUrls: ['./main.component.scss']
})
export class FtbMainComponent   {

  toolbarConfig: ToolbarConfig;

  constructor(adapter: DateAdapter<any>, service: AppConfigsService, private router: Router) {
    adapter.setLocale(service.appLang);

    this.toolbarConfig = {
      logo: '/assets/logo.png',
      links: [
        // {
        //   path: '/core/dashboard',
        //   icon: 'mdi-home'
        // },
        {
          path: '/workflow/billing-requests',
          label: "core.daily_workflow",
          icon:'mdi-calendar-range',
          acl: {resource: 'daily-workflow', action: 'get', class: 'mat-tab-disabled'}
        },
        {
          path: '/patient/patients',
          label: "core.patients",
          icon:'mdi-account-multiple',
          acl: {resource: 'ft-patient', action: 'get', class: 'mat-tab-disabled'}
        },
        {
          path: '/invoicing',
          label: "core.billing",
          icon:'mdi-file-document',
          acl: {resource: 'invoice', action: 'get', class: 'mat-tab-disabled'}
        },
        {
          path: '/payment/payments',
          label: "core.payment",
          icon:'mdi-currency-usd',
          acl: {resource: 'payment', action: 'get', class: 'mat-tab-disabled'}
        },
        {
          path: '/pec/pecs',
          label: "shared.pec",
          icon:'mdi-folder-multiple',
          acl: {resource: 'pec', action: 'get', class: 'mat-tab-disabled'}
        },
        {
          path: '/stock',
          label: "shared.stock_module",
          icon:'mdi-store',
          acl: {resource: 'store-article', action: 'get', behavior:'remove'}
        },
        {
          path: '/states',
          label: "states.states",
          icon:'mdi-chart-areaspline',

          acl: {resource: 'states', action: 'get', class: 'mat-tab-disabled'}
        }


      ],
      actions: [
        {
          class: '',
          icon: 'mdi-cog',
          tooltip: 'core.settings',
          method: () => {this.router.navigate(['/settings'])},
        }
      ],
      phoneNumbers: ['0665 02 71 92', '0663 43 13 00', '0522 29 46 15']
    }

  }

}
