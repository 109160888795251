import {TableColumnDefinition} from '@ft/table';

export const paymentLinesHeaders: TableColumnDefinition[] = [
  {
    'key': 'paid_doc.doc_date',
    'label': 'shared.date',
    'type': 'date'
  }, {
    'key': 'paid_doc.number',
    'label': 'payment.paid_doc',
    'type': 'text'
  },  {
    'key': 'paid_doc.doc_id',
    'label': 'payment.external_doc_id',
    'type': 'text'
  },

  {
    'key': 'total_amount',
    'label': 'payment.total_amount',
    'type': 'number',
    'numberConfig': 'float'
  },
  {
    'key': 'remaining_amount',
    'label': 'payment.line_remaining_amount',
    'type': 'number',
    'numberConfig': 'float'
  }
];
