import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {BaseListFormDialog, FtAuthModule, FtCoreModule, MaterialModule} from '@ft/core';
import {TranslateModule} from '@ngx-translate/core';
import {FtbSettingsRoutingModule} from './settings-routing.module';
import {NgBusyModule} from 'ng-busy';
import {FtTableModule} from '@ft/table';
import {ProcedureDialogComponent} from './dialogs/procedure-dialog/procedure-dialog.component';
import {BillingService} from '../shared/services/billing.service';
import {AlertDialogComponent} from './dialogs/alert-dialog/alert-dialog.component';
import {SequenceListComponent} from './components/sequence-list/sequence-list.component';
import {CatalogueListComponent} from './components/catalogue-list/catalogue-list.component';
import {FtbSharedModule} from '../shared/shared.module';
import {OrganizationFormComponent} from './components/organization-form/organization-form.component';
import {FtbMainComponent} from './components/main/main.component';
import {ConventionDialogComponent} from './dialogs/convention-dialog/convention-dialog.component';
import {BaseSettingsComponent} from './components/base-settings/base-settings.component';
import {HlSevenSetupComponent} from './components/hl-seven-setup/hl-seven-setup.component';
import {HlSevenClientDialogComponent} from './dialogs/hl-seven-client-dialog/hl-seven-client-dialog.component';
import {HlSevenService} from './services/hl-seven.service';
import {PricingComponent} from './components/pricing/pricing.component';
import {BillingStaffDialogComponent} from './dialogs/billing-staff-dialog/billing-staff-dialog.component';
import {MatDialogModule} from "@angular/material/dialog";
import {SharedModule} from "@ft/stock";
import {ngfModule} from "angular-file";

@NgModule({
  imports: [
    CommonModule,
    MatDialogModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    TranslateModule.forChild(),
    NgBusyModule,
    FtTableModule,
    FtCoreModule,
    MaterialModule,
    FtAuthModule,
    FtbSettingsRoutingModule,
    FtbSharedModule,
    SharedModule,
    ngfModule

  ],
  declarations: [
    ProcedureDialogComponent,
    AlertDialogComponent,
    SequenceListComponent,
    CatalogueListComponent,
    OrganizationFormComponent,
    FtbMainComponent,
    ConventionDialogComponent,
    BaseSettingsComponent,
    HlSevenSetupComponent,
    HlSevenClientDialogComponent,
    PricingComponent,
    BillingStaffDialogComponent
  ],
  providers: [BillingService, HlSevenService]

})
export class FtbSettingsModule {
}
