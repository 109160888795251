import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA,  MatDialogRef} from '@angular/material/dialog';
import * as _ from 'lodash';

@Component({
  selector: 'ftb-alert-dialog',
  templateUrl: './alert-dialog.component.html',
  styleUrls: ['./alert-dialog.component.scss']
})
export class AlertDialogComponent implements OnInit {
  message_text = 'message text is missing';

  constructor (public dialogRef: MatDialogRef<AlertDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    this.message_text = _.get(data, 'message_text', this.message_text);
  }

  ngOnInit() {
  }

}
