import {DrawerLink} from '@ft/core';

export const LINKS: DrawerLink[] = [
  {
    label: 'settings.generals',
    items: [
      {
        icon: 'mdi-wrench-outline',
        label: "settings.base_settings",
        path: "base-settings",
      },
      {
        icon: 'mdi-printer-settings',
        label: "settings.printing_setup",
        path: "printing-setup",
      }
    ]
  },
  {
    label: 'settings.users_management',
    items: [
      {
        icon: 'mdi-account-switch',
        label: "settings.profiles",
        path: "profile-setup",
      },
      {
        icon: 'mdi-account-settings',
        label: "auth.staff.setup",
        path: "staff-setup",
      }
    ]
  },
  {
    label: 'settings.app_config',
    items: [
      {
        icon: 'mdi-view-list',
        label: "settings.list_setup",
        path: "list-setup",
      },
      {
        icon: 'mdi-medical-bag',
        path: 'procedure-list',
        label: 'settings.procedures',
      },
      {
        icon: 'mdi-folder-open',
        path: 'procedures-catalogues-list',
        label: 'settings.procedures_catalogues',
      },
      {
        icon: 'mdi-brightness-7',
        path: 'organization-list',
        label: 'settings.organization_list',
      },
      {
        icon: 'mdi-ticket-percent',
        path: 'conventions-list',
        label: 'settings.convention_list',
      },
      {
        icon: 'mdi-currency-usd',
        path: 'pricing-list',
        label: 'settings.pricing_list',
      },
      {
        icon: 'mdi-numeric',
        path: 'sequences',
        label: 'settings.sequence_numbers',
      }
    ]
  },
  {
    label: 'settings.devices_shared_files',
    items: [
      {
        icon: 'mdi-lan-connect',
        label: "settings.hl_seven",
        path: "hl-seven-setup",
      }
    ]
  }
];
