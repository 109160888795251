<div fxFlex class="ft-padding" fxLayout="column">
<form  fxFlex fxLayout="column" fxLayoutGap="6px">
  <!--<div fxLayout="row" fxLayoutGap="6px">
    <ftb-select-input fxFlex="30" [label]="'shared.physician'" [model]="filter.physician" [value]="'full_name'"
                      [settings]="physicianInputSettings"
                      (modelChange)="onPhysicianChange($event)"></ftb-select-input>

  </div>-->
    <div fxLayout="row" fxLayoutGap="6px">
      <mat-form-field fxFlex="nogrow">
        <input matInput ftDate [matDatepicker]="from_date" [placeholder]="'shared.from_date'|translate"
               [(ngModel)]="filter.from_date" name="from_date" (ngModelChange)="handleFilterChange($event)">
        <mat-datepicker-toggle matSuffix [for]="from_date">
          <mat-icon matDatepickerToggleIcon fontSet="mdi" fontIcon="mdi-calendar-today"></mat-icon>
        </mat-datepicker-toggle>
        <mat-datepicker #from_date></mat-datepicker>
      </mat-form-field>

      <mat-form-field fxFlex="nogrow">
        <input matInput ftDate [matDatepicker]="to_date" [placeholder]="'shared.to_date'|translate"
               [(ngModel)]="filter.to_date" name="to_date" (change)="handleFilterChange($event)">
        <mat-datepicker-toggle matSuffix [for]="to_date">
          <mat-icon matDatepickerToggleIcon fontSet="mdi" fontIcon="mdi-calendar-today"></mat-icon>
        </mat-datepicker-toggle>
        <mat-datepicker #to_date></mat-datepicker>
      </mat-form-field>
    </div>
  <div [ngBusy]="busy">
    <ft-generic-body-table  fxFlex
                           [remote]="false"
                           [hasSearch]="false"
                           [hasFooter]="has_res"
                           [source]="linesSource$"
                           [selectable]="false"
                           [hasPagination]="true"
                           [columns]="linesColumns | async"
    >
    </ft-generic-body-table>

  </div>
</form>
  <div fxLayout="row" fxLayoutGap="6px">
    <button fxLayoutAlign="left bottom" type="button"  mat-mini-fab color="primary"
             [disabled]="!has_res"
            [ftPrint]="{group:'client-recoveries-state', view:'/api/billing/invoice/',customView:'print-unique',  model:null, extraQuery:{data:formatFilter()},  unique:true}">
      <mat-icon fontSet="mdi" fontIcon="mdi-printer"></mat-icon>
    </button>
    <button fxLayoutAlign="left bottom" type="button"  mat-mini-fab color="primary"
          [disabled]="!has_res" [columns]="viewColumns"
          [ftbXlsExport]="{filter:formatFilter() ,topic:'invoice.client_recoveries_xls', fileName: 'states.customer_recovery'|translate}"
          >
      <mat-icon fontSet="mdi" fontIcon="mdi-file-excel"></mat-icon>
    </button>
  </div>
</div>
