export const lang = {
  invoices: 'Factures',
  invoice: 'Facture',
  quotations: 'Devis',
  invoice_list: 'Liste des factures',
  invoice_header_number: 'Facture N°',
  invoice_number: 'N°.Facture',
  applied_tariff: 'Convention',
  payment_deadline: 'Date de paiement',
  fees_notes_list: 'Notes d\'honoraires',
  fees_note: 'Note d’honoraire',
  fees_note_header_number: 'Note N°',
  fees_note_number: 'N°. Note',
  quotation_header_number: 'Devis N°',
  quotation_list: 'Liste des devis',
  quotation_number: 'N°. Devis',
  quotation_pattern: 'Motif',
  valid_duration: 'Validité',
  care_sheet_number:"N°. Mutuelle",
  care_sheet_header_number:"Mutuelle N°",
  care_sheet_list:"Liste des mutuelles",
  full_list:"Liste des ",
  other_name:"Appelation",
  certificates:"Attestations",
  certificate:"Attestation",
  certificate_number:"N°. Attestation",

};
