import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import * as _ from 'lodash';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {MatDialog} from "@angular/material/dialog";
import {LARGE_DIALOG, MEDIUM_DIALOG, SMALL_DIALOG} from '@ft/core';

import {TranslateService} from '@ngx-translate/core';
import {RESPONSE_LINES_HEADERS} from '../../utils/response-lines-headers';
import {ResponseDialogComponent} from '../../dialogs/response-dialog/response-dialog.component';
import {getLineAmount} from '../../../shared/utils/functions';
import {TableColumnDefinition} from '@ft/table';

@Component({
  selector: 'ftb-response-lines',
  templateUrl: './response-lines.component.html',
  styleUrls: ['./response-lines.component.scss']
})
export class ResponseLinesComponent implements OnInit {
  lines = [];
  linesActions = [
    {
      class: 'mat-primary',
      icon: 'mdi-plus',
      isMultipleSelection: false,
      method: (item, ev) => {
        return this.addResponseDialog();
      },
      tooltip: this.translateService.instant('shared.add_line')
    }
  ];

  actionColumn: TableColumnDefinition = {
    label: '', key: 'actions', type: 'actions', actions: [
      {
        class: 'mat-primary',
        icon: 'mdi-pencil',
        method: (item, index, ev) => {
          return this.editLine(item, index);
        }
      },
      {
        class: 'mat-warn',
        icon: 'mdi-close-circle',

        method: (item, index, ev) => {
          return this.removeLine(item, index);
        }
      },
    ]
  };

  public linesSource$: Observable<any[]> = of([]);
  public linesColumns = new BehaviorSubject<TableColumnDefinition[]>(RESPONSE_LINES_HEADERS.concat(this.actionColumn));
  hasSearch: boolean = true;

  @Input('lines') set handleLines(value: any[]) {
    this.lines = value;
    this.linesSource$ = of(this.lineDisplayValue(this.lines));
  }

  @Input() cls;
  @Input() model? = {answers: []};
  @Output() linesChange = new EventEmitter();


  @Input('hasSearch') set handleHasSearch(value: boolean) {
    this.hasSearch = value;
  }

  extendedColHandler = (index, item) => item.is_comment === true || item['details'] && item.details.length > 0;

  constructor(private dialog: MatDialog, private translateService: TranslateService) {
  }

  ngOnInit() {
  }

  onLinesChange(data) {
    this.lines = data;
  }

  getAmount() {
    let lines = _.filter(this.lines, (l) => {
      return !l.is_comment;
    });
    return _.chain(this.cls == 'Invoice' ? _.map(lines, 'details') : lines).flatten().sumBy((row) => {
      return getLineAmount(row);
    }).value();
  }

  addResponseDialog() {
    this.dialog.open(ResponseDialogComponent, _.assign(MEDIUM_DIALOG, {
      data: {lines: _.get(this.model,'details')}
    })).afterClosed().subscribe(res => {
      if (_.get(res, 'response')){
        this.lines.push(_.get(res, 'response'));
        this.linesSource$ = of(this.lineDisplayValue(this.lines));
      }

    });
  }

  addComment() {
    this.lines.push({
      code: '',
      description: '',
      is_comment: true
    });
    this.linesSource$ = of(this.lineDisplayValue(this.lines));
  }


  lineDisplayValue(value) {
    if (value) {
      return _.chain(value).map((line) => {
        let lIdx = _.findIndex(value, line);
        let details = _.map(line.details, (e) => {
          return _.assignIn(e, {parentIdx: lIdx, idx: _.findIndex(line.details, e)});
        });
        let _line = _.assignIn(line, {idx: lIdx});

        return _.flatten([_line, details]);
      }).flatten().value();
    }
  }


  editLine(row, idx) {
    this.dialog.open(ResponseDialogComponent, _.assign(MEDIUM_DIALOG, {
      data: {lines: _.get(this.model,'details'), response:row}
    })).afterClosed().subscribe(res => {
      if (_.get(res, 'response')){
        // this.lines.push(res);
        row=_.get(res, 'response');
        this.linesSource$ = of(this.lineDisplayValue(this.lines));
      }

    });
  }
  removeLine(row, idx) {
    this.lines.splice(idx, 1);
    this.linesSource$ = of(this.lineDisplayValue(this.lines));
  }
}
