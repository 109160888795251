<div fxFlex fxLayout="column" class="ft-content ft-padding">
   <div [ngBusy]="loading"></div>
  <div *ngIf="careSheet.state?.is_valid" fxLayoutAlign="center center" class="validate-icon">
    <img mat-card-image src="/assets/lock-outline.svg" alt="validation img">
  </div>
  <form novalidate #careSheetForm="ngForm" [ngBusy]="loading" fxLayout="column" fxFlex fxLayoutGap="6px">
    <div class="ft-padding" fxLayout="row" fxLayoutGap="6px">
      <div fxFlex fxLayout="column">
        <div fxLayout="row" fxLayoutGap="6px">
          <mat-form-field>
            <input matInput type="text" required name="sequence"
                   placeholder="{{'invoicing.care_sheet_number'|translate}}" [(ngModel)]="careSheet.number"/>

          </mat-form-field>
          <mat-form-field>
            <input matInput ftDate [matDatepicker]="inv_date" [placeholder]="'shared.date'|translate"
                   [(ngModel)]="careSheet.doc_date" name="doc_date">
            <mat-datepicker-toggle matSuffix [for]="inv_date">
              <mat-icon matDatepickerToggleIcon fontSet="mdi" fontIcon="mdi-calendar-today"></mat-icon>
            </mat-datepicker-toggle>
            <mat-datepicker #inv_date></mat-datepicker>
          </mat-form-field>
        </div>
        <div fxLayout="row" fxLayoutGap="6px">
          <ftb-select-input fxFlex="50" [label]="'shared.patient'" [(model)]="careSheet.patient" value="full_name"
                            [settings]="patientInputSettings" [dialog]="patientDialog"
                            (modelChange)="onPatientChange($event)"></ftb-select-input>
        </div>
        <div fxLayout="row" fxLayoutGap="6px">
          <ftb-select-input fxFlex="50" [label]="'shared.insurance'" [(model)]="careSheet.insurance" value="name"
                            [settings]="organizationInputSettings"
                            (modelChange)="onOrganizationChange($event)"></ftb-select-input>
        </div>
      </div>
      <mat-divider [vertical]="true"></mat-divider>
      <div fxFlex fxLayout="column">
        <div fxLayout="row" fxLayoutGap="6px">
          <mat-form-field floatLabel="always" class="ft-radio-field">
            <input matInput placeholder="{{'shared.insured_type'|translate}}" style="display: none">
            <mat-radio-group [(ngModel)]="careSheet.insured_details.insured_type" name="b_type" fxLayout="row" >
              <mat-radio-button *ngFor="let b_type of ['PATIENT','PARTNER','CHILD','PARENT']" [value]="b_type">
                {{b_type=='PATIENT' ? ('pec.same_patient'|translate) : ('pec.'+b_type|translate)}}
              </mat-radio-button>
            </mat-radio-group>

          </mat-form-field>
        </div>
        <div fxLayout="row" fxLayoutGap="6px">
          <ftb-select-list fxFlex fxFlex label="shared.title"
                           [model]="careSheet.insured_details.title"
                           [cls]="'Title'" (modelChange)="listChange($event,'Title')"></ftb-select-list>
          <mat-form-field fxFlex>
            <input matInput type="text" name="first_name" placeholder="{{'pec.first_name'|translate}}"
                   [(ngModel)]="careSheet.insured_details.first_name"/>
          </mat-form-field>

          <mat-form-field fxFlex>
            <input matInput type="text" name="last_name" placeholder="{{'pec.last_name'|translate}}"
                   [(ngModel)]="careSheet.insured_details.last_name"/>
          </mat-form-field>


        </div>
        <div fxLayout="row" fxLayoutGap="6px">
          <!--<mat-form-field fxFlex>-->
            <!--<input matInput type="text" required name="affiliate_number"-->
                   <!--placeholder="{{'pec.affiliate_number'|translate}}"-->
                   <!--[(ngModel)]="careSheet.insured_details.affiliate_number"/>-->
          <!--</mat-form-field>-->

          <mat-form-field fxFlex>
            <input matInput ftDate [matDatepicker]="b_date" [placeholder]="'shared.birth_date'|translate"
                   [(ngModel)]="careSheet.insured_details.birth_date" name="b_date">
            <mat-datepicker-toggle matSuffix [for]="b_date">
              <mat-icon matDatepickerToggleIcon fontSet="mdi" fontIcon="mdi-calendar-today"></mat-icon>
            </mat-datepicker-toggle>
            <mat-datepicker #b_date></mat-datepicker>
          </mat-form-field>
          <mat-form-field fxFlex>
            <input matInput type="text" required name="national_id" placeholder="{{'pec.national_id'|translate}}"
                   [(ngModel)]="careSheet.insured_details.national_id"/>
          </mat-form-field>
          <mat-form-field fxFlex>
            <input matInput type="text" required name="imatr_number"
                   placeholder="{{'pec.imatr_number'|translate}} / {{'pec.affiliate_number'|translate}}"
                   [(ngModel)]="careSheet.insured_details.imatr_number"/>
          </mat-form-field>

        </div>
        <div fxLayout="row">
          <mat-form-field fxFlex>
          <textarea matInput [(ngModel)]="careSheet.insured_details.address" name="address"
                    [placeholder]="'shared.address'|translate"></textarea>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div fxFlex>
      <ftb-document-lines fxFlex [(lines)]="careSheet.lines" [cls]="'careSheet'" [model]="careSheet"
                          [showDetailedAmounts]="false"></ftb-document-lines>
    </div>
  </form>
</div>
<ftb-document-actions fxFlex="nogrow" [model]="careSheet" [cls]="cls" (modelChange)="onModelChange($event)"
                      [formInvalid]="careSheetForm.invalid || (!careSheet.patient && !careSheet.insurance)"
                      [routes]="routes" [(busy)]="loading"></ftb-document-actions>
