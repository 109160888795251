import {Component, OnInit} from '@angular/core';
import {HEIGHT_ANIMATION, SCALE_ANIMATION} from '@ft/core';
import {BehaviorSubject, Observable, of, Subscription} from 'rxjs';
import * as _ from 'lodash';
import {StateService} from '../../services/state-service.service';
import {TableColumnDefinition} from '@ft/table';
import {LINES_COLUMNS} from '../../../shared/utils/lines-headers';
import {PHYSICIAN_VIEW} from '../../../shared/utils/views-settings';
import * as moment from 'moment';
import {TableColumnType} from '@ft/table/table/models/table-definitions';
import {saveAs} from 'file-saver';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'ftb-anterior-view',
  templateUrl: './anterior-view.component.html',
  animations: [
    SCALE_ANIMATION,
    HEIGHT_ANIMATION
  ],
  styleUrls: ['./anterior-view.component.scss']
})
export class AnteriorViewComponent implements OnInit {
  items: any = [];
  busy: Subscription;


  linesSource$: Observable<any[]> = of([]);

  filter = {'from_date': moment()};
  has_res = false;
  // billing_request: {_id: 889, doc_date: "20/10/2020", patient: 850, doc_id: "EX0201020102659"}
  // doc_date: "20/10/2020"
  // doc_id: "EX0201020102659"
  // patient: 850
  // _id: 889
  // encasement_amount: 100
  // number: "1324"
  // patient: {_id: 850, first_name: "Abderrahim", last_name: "EL KIMAOUI"}
  // first_name: "Abderrahim"
  // last_name: "EL KIMAOUI"
  // _id: 850
  // total_amount: 200
  // _id: 1330
  footerData = {};

  viewColumns: TableColumnDefinition[] = [
    {
      label: "payment.payment_number",
      type: "text",
      key: "number"
    },
    // {
    //   label: "shared.date",
    //   type: "text",
    //   key: "doc_date"
    // },
    {
      label: "shared.patient_first_name",
      type: "text",
      key: "patient.first_name"
    },
    {
      label: "shared.patient_last_name",
      type: "text",
      key: "patient.last_name",
    },
    {
      label: "states.exams_date",
      type: "text",
      key: "billing_request.doc_date"
    },
    {
      label: "states.doc_number",
      type: "text",
      key: "billing_request.doc_id"
    },
    {
      label: "states.taxed_amount",
      type: "number",
      key: "total_amount",
      numberConfig: 'float',
      footer: () => {
        return this.footerData['total_amount']
      }
    },
    {
      label: "payment.encasement_amount",
      type: "number",
      key: "encasement_amount",
      numberConfig: 'float',
      footer: () => {
        return this.footerData['encasement_amount']
      }
    }
  ]
  linesColumns = new BehaviorSubject<TableColumnDefinition[]>(this.viewColumns);

  constructor(private stateService: StateService, private translateService: TranslateService) {
  }

  ngOnInit() {
    this.handleFilterChange(null);
  }

  trackByFn(index, item) {
    return _.get(item, 'label', index);
  }

  reportClick(link) {

  }


  handleFilterChange(ev) {
    this.busy = this.stateService.getAnteriorPayments(this.formatFilter()).subscribe(data => {
      let totals = _.get(data, 'totals', {});
      if (!_.isNil(data)) {
        this.has_res = !_.isEmpty(data);
        this.footerData = {
          total_amount: _.sumBy(data, 'total_amount'),
          encasement_amount: _.sumBy(data, 'encasement_amount')
        }
        this.linesSource$ = of(data);
      } else {
        this.has_res = false;
      }
    });

  }

  formatFilter() {
    return {_from: _.get(this.filter, 'from_date').format('DD/MM/YYYY')}
  }


}
