import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {MainComponent} from './components/main/main.component';
import {PaymentFormComponent} from './components/payment-form/payment-form.component';
import {AuthGuard} from '@ft/core';
import {PaymentListComponent} from "./components/payment-list/payment-list.component";

const routes: Routes = [
  {
    path: '',
    component: MainComponent,
    children: [
      {path: '', pathMatch: 'full', redirectTo: 'payments'},
      {
        path: 'payments', component: MainComponent, children: [
          {path: '', pathMatch: 'full', redirectTo: 'payments'},
          {path: 'payments', component: PaymentListComponent},
          {path: 'payment-form', component: PaymentFormComponent},
          {path: 'payment-form/:id', component: PaymentFormComponent},
        ]
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class FtbPaymentRoutingModule {
}


