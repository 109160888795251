import {DocumentListSettings} from '../../shared/models/document-list-definition';
import {CONVENTION_COLUMNS} from '../../shared/utils/table-configs/convention';
import {ORGANIZATION_COLUMNS} from '../../shared/utils/table-configs/organisation';
import {PROCEDURE_COLUMNS} from '../../shared/utils/table-configs/procedure';
import {ProcedureDialogComponent} from '../dialogs/procedure-dialog/procedure-dialog.component';
import {ConventionDialogComponent} from '../dialogs/convention-dialog/convention-dialog.component';

export const CONVENTION_VIEW: DocumentListSettings = {
  trackByKey: 'id',
  hasSearch: true,
  selectable: true,
  hasPagination: true,
  viewNamespace: 'CONVENTION_List',
  query: [],
  viewSettings: {

    'model': 'shared.convention.Convention',
    'viewColumns': CONVENTION_COLUMNS,
    'systemColumns': [
      {
        'key': 'created_at', 'label': 'created_at', 'type': 'date'
      },
      {
        'key': 'updated_at', 'label': 'updated_at', 'type': 'date'
      },
      {
        'key': 'is_deleted', 'label': 'is_deleted', 'type': 'boolean'
      },
      {
        'key': 'deleted_at', 'label': 'deleted_at', 'type': 'date'
      },
    ],
    'defaultName': 'Liste des conventions'
  },
  actions: [],
  resource:'convention',
  saveLabel: 'settings.convention_save_success',
  headerLabel: 'settings.convention_list',
  deleteTopic: 'convention.check_and_delete',
  headerIcon: 'mdi-ticket-percent',
  formRoute: null,
  formLabel: 'shared.convention',
  formDialog: ConventionDialogComponent
};

export const ORGANIZATION_VIEW: DocumentListSettings = {
  trackByKey: 'id',
  hasSearch: true,
  selectable: true,
  hasPagination: true,
  viewNamespace: 'ORGANIZATION_List',
  query: [],
  viewSettings: {
    'model': 'shared.insurance.Organization',
    'viewColumns': ORGANIZATION_COLUMNS,
    'systemColumns': [
      {
        'key': 'created_at', 'label': 'created_at', 'type': 'date'
      },
      {
        'key': 'updated_at', 'label': 'updated_at', 'type': 'date'
      },
      {
        'key': 'is_deleted', 'label': 'is_deleted', 'type': 'boolean'
      },
      {
        'key': 'deleted_at', 'label': 'deleted_at', 'type': 'date'
      },
    ],
    'defaultName': 'Toutes les assurances'
  },
  resource:'organization',
  actions: [],
  saveLabel: 'settings.organisation_save_success',
  headerLabel: 'settings.organization_list',
  deleteTopic: 'organization.check_and_delete',
  headerIcon: 'mdi-brightness-7',
  formRoute: '/settings/organization-form/',
  formLabel: 'shared.organization'
};

