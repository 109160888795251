<div fxFlex fxLayout="column" class="ft-content ft-padding">
   <div [ngBusy]="loading"></div>
  <div *ngIf="invoice.state?.is_valid" fxLayoutAlign="center center" class="validate-icon">
    <img mat-card-image src="/assets/lock-outline.svg" alt="validation img">
  </div>
  <form novalidate #invoiceForm="ngForm" [ngBusy]="loading" fxLayout="column" fxFlex fxLayoutGap="6px">
    <div fxLayout="row" fxLayoutGap="6px">
      <div fxFlex fxLayout="column">
        <div fxLayout="row" fxLayoutGap="6px">
          <mat-form-field fxFlex="25">
            <input matInput type="text" required [ftSequenceInput]="seqQuery.cls" [ftSequenceToggle]="toggle"
                   name="sequence"
                   placeholder="{{'invoicing.invoice_number'|translate}}" [(ngModel)]="invoice.number" />
            <ft-sequence-toggle [disabled]="invoice.id"  matSuffix #toggle [(objectId)]="loadedObject"
                                [config]="{cls: seqQuery.cls, field: 'number'}">
            </ft-sequence-toggle>
          </mat-form-field>
          <mat-form-field fxFlex="25" >
            <input matInput ftDate [matDatepicker]="inv_date" [placeholder]="'shared.date'|translate"
                   [(ngModel)]="invoice.doc_date" name="doc_date">
            <mat-datepicker-toggle matSuffix [for]="inv_date" cdkFocusInitial>
              <mat-icon matDatepickerToggleIcon fontSet="mdi" fontIcon="mdi-calendar-today"></mat-icon>
            </mat-datepicker-toggle>
            <mat-datepicker #inv_date></mat-datepicker>
          </mat-form-field>
        </div>
        <div fxLayout="row" fxLayoutGap="6px">
          <ftb-select-input fxFlex="50" [label]="'shared.patient'" [(model)]="invoice.patient" value="full_name"
                            [settings]="patientInputSettings" [dialog]="patientDialog"
                            (modelChange)="onPatientChange($event)"></ftb-select-input>
        </div>
        <div fxLayout="row" fxLayoutGap="6px">
          <ftb-select-input fxFlex="50" [label]="'shared.physician'" [(model)]="invoice.physician" value="full_name"
                            [settings]="physicianInputSettings"
                            (modelChange)="onPhysicianChange($event)"></ftb-select-input>
        </div>
      </div>
      <mat-divider [vertical]="true"></mat-divider>
      <div fxFlex fxLayout="column">
        <div fxLayout="row" fxLayoutGap="6px">
          <div fxLayout="row" fxLayoutGap="6px" fxFlex>
            <mat-form-field fxFlex>
              <input matInput ftDate [matDatepicker]="inv_deadline"
                     [placeholder]="'invoicing.payment_deadline'|translate"
                     [(ngModel)]="invoice.deadline" name="deadline">
              <mat-datepicker-toggle matSuffix [for]="inv_deadline">
                <mat-icon matDatepickerToggleIcon fontSet="mdi" fontIcon="mdi-calendar-today"></mat-icon>
              </mat-datepicker-toggle>
              <mat-datepicker #inv_deadline></mat-datepicker>
            </mat-form-field>
            <mat-form-field fxFlex>
              <mat-select [placeholder]="'shared.payment_condition' | translate" [compareWith]="compareCondition" ftDefaultValues="default_payment_condition"
                          [(ngModel)]="invoice.payment_condition" name="payment_condition" (selectionChange)="handlePaymentCondition($event)">
                <mat-option *ngFor="let condition of paymentConditions" [value]="condition">
                  {{'shared.'+condition.key|translate}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div fxLayout="row" fxLayoutGap="6px">
          <ftb-select-input fxFlex [label]="'shared.organization'" [(model)]="invoice.organization" value="name"
                            [settings]="organizationInputSettings"
                            (modelChange)="onOrganizationChange($event)"></ftb-select-input>

          <ftb-select-input fxFlex [label]="'invoicing.applied_tariff'" [(model)]="invoice.convention" value="label"
                            [settings]="conventionInputSettings"
                            (modelChange)="handleConventionChange($event)"></ftb-select-input>
        </div>
           <div fxLayout="row" fxLayoutGap="6px" fxFlex>
              <mat-form-field fxFlex="50">
                <mat-select [placeholder]="'settings.pricing_list' | translate" [compareWith]="comparePricingCondition"
                            [(ngModel)]="invoice.pricing" name="mode"   [ngModelOptions]="{standalone:true}" (selectionChange)="handlePricingChange(invoice.pricing)" >
                  <mat-option *ngFor="let p of pricingList" [value]="p">
                    {{p.name}}
                  </mat-option>
                </mat-select>
                  <button *ngIf="invoice.pricing" matSuffix mat-icon-button type="button" color="warn" matTooltip="clean"
                          (click)="$event.stopImmediatePropagation(); invoice.pricing=null;">
                    <mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
                  </button>
              </mat-form-field>
            </div>
        </div>
    </div>
    <div fxFlex>
      <ftb-document-lines fxFlex
                          [(lines)]="invoice.lines"
                          [cls]="'Invoice'"
                          [model]="invoice"
                          [(convention)]="invoice.convention"
                          [(pricing)]="invoice.pricing"
                          [linesSelectQuery]="handleLinesQuery()">
      </ftb-document-lines>
    </div>
  </form>
</div>

<ftb-document-actions fxFlex="nogrow" [model]="invoice" [cls]="cls" (modelChange)="onModelChange($event)"
                      [formInvalid]="(invoiceForm.invalid && invoiceForm.touched) || (!invoice.patient && !invoice.organization)"
                      [routes]="routes" [loadedObject]="loadedObject"   [(busy)]="loading" [saveEmpty]="true"></ftb-document-actions>
