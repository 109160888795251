<mat-toolbar color="primary">
  <div class="ft-module-icon">
    <mat-icon fontSet="mdi" fontIcon="{{settings['headerIcon']}}"></mat-icon>
  </div>

  <h2 mat-dialog-title>{{ settings['headerLabel'] | translate }}</h2>
  <span fxFlex></span>

  <button type="button" mat-icon-button mat-dialog-close tabindex="-1">
    <mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
  </button>
</mat-toolbar>
<div mat-dialog-content class="ft-content no-padding"  >
  <ft-generic-view-table  fxFlex [namespace]="tableSettings?.viewNamespace"
                         [viewSettings]="tableSettings?.viewSettings"
                         [hasPagination]="tableSettings?.hasPagination"
                         [selectable]="tableSettings?.selectable"
                         [query]="tableSettings?.query"
                         (selectionChange)="handleSelectionChange($event)"
                         (lineClick)="tableSettings?.lineClick($event)"
                          [(selected)]="selectedItems"

  ></ft-generic-view-table>
</div>
<div *ngIf="settings?.selectable" mat-dialog-actions>
  <span fxFlex></span>
  <button type="button" color="primary" mat-raised-button tabindex="2" (click)="add()" >
    {{'shared.submit_add'|translate}}
  </button>
  <button type="button" [mat-dialog-close]="null"  color="warn" mat-raised-button tabindex="-1">{{'shared.reset'|translate}}</button>
</div>
