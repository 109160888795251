<mat-toolbar color="primary">
  <div class="ft-module-icon">
    <button type="button" mat-icon-button  tabindex="-2">
      <mat-icon fontSet="mdi" fontIcon="mdi-currency-usd"></mat-icon>
    </button>
  </div>
  <h2>{{ 'shared.financial_details' | translate }} - {{full_name}}</h2>
  <span fxFlex></span>
  <button type="button" mat-icon-button mat-dialog-close  tabindex="-1">
    <mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
  </button>
</mat-toolbar>
<form novalidate #form="ngForm" >
  <div [ngBusy]="loader"></div>
  <div mat-dialog-content fxLayout="column" class="ft-busy-container" style="min-height: 230px;">
    <div fxLayout="row" fxLayoutGap="6px" >
        <mat-form-field fxFlex="15">
          <input matInput  name="global_discount_amount" [ftNumber]="'float'" placeholder="{{'shared.discount_amount'|translate}}"
                 [(ngModel)]="model.global_discount_amount" (change)="handleDiscount(true)"
                 [ftAutoFocus]="true"/>
        </mat-form-field>
        <mat-form-field  fxFlex="15">
                    <input matInput name="global_discount" [ftNumber]="'float'" placeholder="{{'shared.discount_percent'|translate}}"
                           [(ngModel)]="model.global_discount" (change)="handleDiscount()"/>
        </mat-form-field>
    </div>
    <hr class="ft-sep">
    <div fxFlex>
      <ftb-document-lines fxFlex
                          [(lines)]="model.procedures"
                          [model]="model"
                          [cls]="'billingRequest'"
                          [hasSearch]= "false"
                          [showDetailedAmounts]="false"
                          >
      </ftb-document-lines>
    </div>
  </div>
  <ng-template  [ftAcl]="{resource: 'billing-request', action: 'create', behavior: 'remove'}">
    <div mat-dialog-actions *ngIf="payment_status  == 'unpaid'">
      <span fxFlex></span>
      <button type="button" color="primary" mat-raised-button tabindex="2"   (click)="save()" [disabled]="form.invalid">
        {{'shared.save'|translate}}
      </button>
      <button type="button" [mat-dialog-close]="null"  type="button"  color="warn" mat-raised-button tabindex="-1">{{'shared.reset'|translate}}</button>
    </div>
  </ng-template>
</form>

