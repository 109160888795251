import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FtbMainComponent} from './components/main/main.component';
import {FtbDashboardComponent} from './components/dashboard/dashboard.component';
import {FtAuthModule, FtCoreModule, MaterialModule} from '@ft/core';
import {TranslateModule} from '@ngx-translate/core';
import {FtbCoreComponent} from './core.component';
import {NgBusyModule} from 'ng-busy';
import {FormsModule} from '@angular/forms';
import {FtbCoreRoutingModule} from './core-routing.module';
import {HttpClientModule} from '@angular/common/http';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    TranslateModule.forChild(),
    HttpClientModule,
    FormsModule,
    NgBusyModule,
    FtCoreModule,
    FtbCoreRoutingModule,
    FtAuthModule
  ],
  declarations: [
    FtbMainComponent,
    FtbDashboardComponent,
    FtbCoreComponent
  ],
  exports: [ FtbMainComponent],
})
export class FtbCoreModule {
}
