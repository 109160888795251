import {TableViewDefinition} from '@ft/table/table/models/table-definitions';
import {DocumentListSettings} from '../../shared/models/document-list-definition';
import {CARE_REQUEST_COLUMNS} from '../../shared/utils/table-configs/care-request';

export const CARE_REQUEST_VIEW: DocumentListSettings = {
  trackByKey: 'id',
  hasSearch: true,
  selectable: true,
  hasPagination: true,
  viewNamespace: 'CARE_REQUEST_List',
  query: [],
  viewSettings: {
    'model': 'pec.CareRequestTable',
    'viewColumns': CARE_REQUEST_COLUMNS,
    'systemColumns': [
      {
        'key': 'created_at', 'label': 'created_at', 'type': 'date'
      },
      {
        'key': 'updated_at', 'label': 'updated_at', 'type': 'date'
      },
      {
        'key': 'is_deleted', 'label': 'is_deleted', 'type': 'boolean'
      },
      {
        'key': 'deleted_at', 'label': 'deleted_at', 'type': 'date'
      },
    ],
    'defaultName': 'Liste des prise en charges'
  },
  actions: [],
  resource: 'pec',
  formRoute: '/pec/pecs/pec-form/',
  formLabel: 'pec.pec_folder',
  headerLabel: 'pec.pec_list',
  headerIcon: 'mdi-folder-multiple-outline',
  removeLinearDelete:true
};
